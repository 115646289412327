import React from "react";

import CheckoutSteps from "../../components/checkout/CheckoutSteps";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header";
import Payment from "../../components/payment/Payment";

const PaymentPage = () => {
  return (
    <div style={{ marginTop: "110px" }}>
      <Header />
      <CheckoutSteps active={2} />
      <Payment />
      <Footer />
    </div>
  );
};

export default PaymentPage;
