import React from "react";

import "./checkout.css";

const CheckoutSteps = ({ active }) => {
  return (
    <div className="checkout_steps">
      <div className="checkout_steps_container">
        <div>
          <span
            className={`${
              active === 1 ? "checkout_steps_active" : "checkout_steps_unactive"
            }`}
          >
            1. Shipping
          </span>
        </div>

        <div>
          <span
            className={`${
              active === 2 ? "checkout_steps_active" : "checkout_steps_unactive"
            }`}
          >
            2. Payment
          </span>
        </div>

        <div>
          <span
            className={`${
              active === 3 ? "checkout_steps_active" : "checkout_steps_unactive"
            }`}
          >
            3. Success
          </span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSteps;
