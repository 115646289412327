// actions/locationActions.js
import Cookies from "js-cookie";

export const setLocation = (latitude, longitude) => {
  // Set location in cookies
  Cookies.set("userLocation", JSON.stringify({ latitude, longitude }));

  // Return the action
  return {
    type: "SET_LOCATION",
    payload: { latitude, longitude },
  };
};
