import { useRef } from "react";
import Detail from "./Detail";
import useOnCLickOutside from "../../helpers/clickOutside";
export default function EditDetails({
  details,
  handleChange,
  updateDetails,
  infos,
  setVisible,
}) {
  const modal = useRef(null);
  useOnCLickOutside(modal, () => setVisible(false));
  return (
    <div className="blur">
      <div className="postBox infosBox" ref={modal}>
        <div className="box_header">
          <div className="small_circle" onClick={() => setVisible(false)}>
            <i className="exit_icon"></i>
          </div>
          <span>Edit About Details</span>
        </div>
        <div className="details_wrapper scrollbar">
          <div className="details_col">
            <span>Edit Your About</span>
            <span>Everyone can see</span>
          </div>
          <div className="details_header">Title</div>
          <Detail
            value={details?.otherName}
            img="studies"
            placeholder="Add a title"
            name="otherName"
            text="your Title"
            handleChange={handleChange}
            updateDetails={updateDetails}
            infos={infos}
          />
          <div className="details_header">Job</div>
          <Detail
            value={details?.job}
            img="job"
            placeholder="Where do you work"
            name="job"
            text="your job"
            handleChange={handleChange}
            updateDetails={updateDetails}
            infos={infos}
          />
          <div className="details_header">City</div>
          <Detail
            value={details?.currentCity}
            img="home"
            placeholder="Add your city"
            name="currentCity"
            text="your city"
            handleChange={handleChange}
            updateDetails={updateDetails}
            infos={infos}
          />
        </div>
      </div>
    </div>
  );
}
