import React from "react";
import Header from "../../components/header";
import RideHero from "../../components/ride/RideHero";

import "./style.css";

const RidePage = () => {
  return (
    <>
      <Header />
      <div className="ride_page">
        <RideHero />
      </div>
    </>
  );
};

export default RidePage;
