import React from "react";
import ReactDOM from "react-dom";
// import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import reduxStore from "./store/reduxStore";
import { Provider } from "react-redux";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import App from "./App";
import rootReducer from "./store/store";
import "./styles/icons/icons.css";
import "./styles/dark.css";
import "./index.css";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const store = createStore(
  rootReducer,
  applyMiddleware(thunk),
  composeWithDevTools()
);

// const root = ReactDOM.createRoot(document.getElementById("root"));

ReactDOM.render(
  <Provider store={store} reduxStore={reduxStore}>
    <Router>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </Router>
  </Provider>,
  document.getElementById("root")
);
