import React from "react";
import { Link } from "react-router-dom";
import { FcAssistant, FcHome, FcShop } from "react-icons/fc";

import Header from "../../components/header";

import "./seller-type.css";

const SellerTypePage = () => {
  return (
    <div>
      <Header />
      <div className="seller_type">
        <div className="seller_type_container">
          <h1 style={{ marginBottom: "20px" }}>Choose type of Seller</h1>
          <span>Create a Eatery, Shop, or Service</span>
          <Link to="/eatery-create">
            <div
              className="blue_btn seller_type_buttons"
              style={{ fontSize: "18px" }}
            >
              <div className="seller_type_icon">
                <FcHome size={22} />
              </div>
              Create a Eatery
            </div>
          </Link>
          <Link to="/shop-create">
            <div
              className="blue_btn seller_type_buttons"
              style={{ fontSize: "18px" }}
            >
              <div className="seller_type_icon">
                <FcShop size={22} />
              </div>
              Create a Shop
            </div>
          </Link>

          <Link to="/service-create">
            <div
              className="blue_btn seller_type_buttons"
              style={{ fontSize: "18px" }}
            >
              <div className="seller_type_icon">
                <FcAssistant size={22} />
              </div>
              Create a Service
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SellerTypePage;
