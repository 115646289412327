import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill } from "react-icons/ci";
import { BiMessageSquareDetail } from "react-icons/bi";

import "./dashboard-layout.css";

const DashboardSidebar = ({ active }) => {
  const tempClick = () => {
    console.log("clicked");
  };

  return (
    <>
      <div className="dashboard_left">
        {/* single item */}
        <div className="db_item hover0">
          <Link to="/shop-dashboard">
            <div className="small_circle">
              <RxDashboard size={24} color={active === 1 ? "#5e97b7" : ""} />
            </div>
            <span className={active === 1 ? "#5e97b7" : ""}>Dashboard</span>
            <div className="dbArrow">
              <i className="right_icon"></i>
            </div>
          </Link>
        </div>

        <div className={`db_item hover0`}>
          <Link to="/dashboard-orders">
            <div className="small_circle">
              <FiShoppingBag size={24} color={active === 2 ? "#5e97b7" : ""} />
            </div>
            <span>All Orders</span>
            <div className="dbArrow">
              <i className="right_icon"></i>
            </div>
          </Link>
        </div>

        <div className={`db_item hover0`}>
          <Link to="/dashboard-products">
            <div className="small_circle">
              <FiPackage size={24} color={active === 3 ? "#5e97b7" : ""} />
            </div>
            <span>All Products</span>
            <div className="dbArrow">
              <i className="right_icon"></i>
            </div>
          </Link>
        </div>

        <div className={`db_item hover0`}>
          <Link to="/dashboard-create-product">
            <div className="small_circle">
              <AiOutlineFolderAdd
                size={24}
                color={active === 4 ? "#5e97b7" : ""}
              />
            </div>
            <span>Create Product</span>
            <div className="dbArrow">
              <i className="right_icon"></i>
            </div>
          </Link>
        </div>

        <div className={`db_item hover0`}>
          <Link to="/dashboard-withdraw-money">
            <div className="small_circle">
              <CiMoneyBill size={24} color={active === 8 ? "#5e97b7" : ""} />
            </div>
            <span>Withdraw Money</span>
            <div className="dbArrow">
              <i className="right_icon"></i>
            </div>
          </Link>
        </div>

        <div className={`db_item hover0`}>
          <Link to="/dashboard-messages">
            <div className="small_circle">
              <BiMessageSquareDetail
                size={24}
                color={active === 9 ? "#5e97b7" : ""}
              />
            </div>
            <span>Shop Inbox</span>
            <div className="dbArrow">
              <i className="right_icon"></i>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default DashboardSidebar;
