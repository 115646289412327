import axios from "axios";
import { axiosInstance } from "./axiosInstance";

// /shopsWithin/:distance/center/:latlng/unit/:unit
export const servicersWithin = async (lat, lng) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/servicer/servicersWithin/200/center/${lat},${lng}/unit/mi`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateServicerProfilePicture = async (url, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/servicer/updateServicerProfilePicture`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateServicerCover = async (url, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/servicer/updateServicerCover`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};
