import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const serviceReducer = createReducer(initialState, {
  serviceCreateRequest: (state) => {
    state.isLoading = true;
  },
  serviceCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.service = action.payload;
    state.success = true;
  },
  serviceCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Servicer all services
  getAllServicesServicerRequest: (state) => {
    state.isLoading = true;
  },
  getAllServicesServicerSuccess: (state, action) => {
    state.isLoading = false;
    state.services = action.payload;
  },
  getAllServicesServicerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.services = false;
  },

  // Get all services
  getAllServicesRequest: (state) => {
    state.isLoading = true;
  },
  getAllServicesSuccess: (state, action) => {
    state.isLoading = false;
    state.allServices = action.payload;
  },
  getAllServicesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    // state.products = false;
  },

  // get service of service
  getServicesOfServiceRequest: (state) => {
    state.isLoading = true;
  },
  getServicesOfServiceSuccess: (state, action) => {
    state.isLoading = false;
    state.service = action.payload;
  },
  getServicesOfServiceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
