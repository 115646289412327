import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaCar } from "react-icons/fa";

import { addToCart, removeFromCart } from "../../store/actions/cartActions";

import "./cart.css";

const Cart = ({ setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const quantityChangeHandler = (data) => {
    dispatch(addToCart(data));
  };

  const productItems = cart
    .map((item) => item.discountPrice * item.qty)
    .filter(Number);

  const serviceItems = cart
    .map((item) => item.service?.feePerHour * item.qty)
    .filter(Number);

  const addFeeIfWordExists = (arr, word, feeAmount) => {
    if (arr.includes(word)) {
      return true + feeAmount;
    } else {
      return false;
    }
  };

  const arr = cart.map((item) => item.deliveryOptions);
  const searchWord = "1-hour Delivery + $9.99";
  const fee = 8.99;

  const wordExistsWithFee = addFeeIfWordExists(arr, searchWord, fee);

  const totalItems = [...productItems, ...serviceItems, wordExistsWithFee];
  const totalPrice = totalItems.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  // const totalPrice = totalItems.reduce(
  //   (acc, item) =>
  //     acc + item.qty * item.discountPrice || acc + item.qty * item.feePerHour,
  //   0
  // );

  return (
    <>
      <div className="cart_container">
        {cart && cart.length === 0 ? (
          <div className="cart_header">
            <div className="cart_close_icon">
              <RxCross1
                size={25}
                onClick={() => setOpenCart(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <h5>Cart Items is empty!</h5>
          </div>
        ) : (
          <>
            <div className="cart_header">
              <div className="cart_close_icon">
                <RxCross1
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenCart(false)}
                />
              </div>

              {/* Item length */}
              <div className="">
                <IoBagHandleOutline size={25} />
                <h5 className="">{cart && cart.length} items</h5>
              </div>
            </div>

            {/* cart Single Items */}
            <br />
            <div className="cart_single_container">
              {cart &&
                cart.map((i, index) => (
                  <CartSingle
                    key={index}
                    data={i}
                    quantityChangeHandler={quantityChangeHandler}
                    removeFromCartHandler={removeFromCartHandler}
                  />
                ))}
            </div>

            {wordExistsWithFee && (
              <div className="cart_delivery">
                <FaCar />
                <span>1-hour Delivery: $9.99</span>
              </div>
            )}

            <div className="">
              {/* checkout buttons */}
              <Link to="/checkout">
                <div className="cart_button">
                  <h1 className="cart_button_text">
                    <div> Checkout Now (USD${totalPrice.toFixed(2)})</div>
                  </h1>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);

  const totalPrice =
    data.discountPrice * value || data?.service?.feePerHour * value;

  const increment = (data) => {
    if (data.stock < value) {
      toast.error(`Sorry limited amount in stock`);
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  console.log(data);

  return (
    <div className="cart_single">
      <div className="cart_single_wrap">
        <div className="cart_single_left">
          <div className="cart_actions">
            <div className="cart_plus" onClick={() => increment(data)}>
              <HiPlus size={18} color="#fff" />
            </div>
            <span style={{ paddingLeft: "10px" }}>{data.qty}</span>
            <div className="cart_minus" onClick={() => decrement(data)}>
              <HiOutlineMinus size={16} color="#7d879c" />
            </div>
          </div>
          {data.images ? (
            <img src={`${data?.images[0].url}`} alt="" className="cart_image" />
          ) : (
            <img
              src={`${data?.service.images[0].url}`}
              alt=""
              className="cart_image"
            />
          )}
        </div>
        <div className="cart_single_right">
          <div className="cart_text">
            {data.name ? (
              <h3 className="cart_name">
                {" "}
                {data?.name?.length > 40
                  ? data?.name.slice(0, 40) + "..."
                  : data?.name}
              </h3>
            ) : (
              <h3 className="cart_name">{data.service.name}</h3>
            )}
            <h4 className="cart_price">
              {data.discountPrice ? (
                <div>
                  ${data.discountPrice} * {value}
                </div>
              ) : (
                <div>
                  ${data?.service?.feePerHour} * {value}
                </div>
              )}
            </h4>
            <h4 className="cart_total">US${totalPrice}</h4>
          </div>
        </div>
        <div className="cart_delete">
          <RxCross1
            className="cursor-pointer"
            onClick={() => removeFromCartHandler(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
