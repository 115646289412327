import React, { useEffect, useRef, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { toast } from "react-toastify";

import { getAllOrdersOfUser } from "../../store/actions/orderActions";
import useClickOutside from "../../helpers/clickOutside";
import { axiosInstance } from "../../functions/axiosInstance";

import "./orders.css";

const UserOrderDetails = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { orders } = useSelector((state) => state.order);
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const dispatch = useDispatch();
  const { id } = useParams();

  const popup = useRef(null);

  useClickOutside(popup, () => {
    setOpen(false);
  });

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user.id));
  }, [dispatch, user.id]);

  // Check for products in profile
  const data = orders && orders.find((item) => item._id === id);

  const reviewHandler = async (e) => {
    await axiosInstance
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/product/createNewReview`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user.id));
        setComment("");
        setRating(null);
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const refundHandler = async () => {
    await axiosInstance
      .put(`${process.env.REACT_APP_BACKEND_URL}/order/order-refund/${id}`, {
        status: "Processing refund",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="orders">
      <div className="orders_container">
        <div className="order_details">
          <div className="order_details_info">
            <BsFillBagFill size={30} color="crimson" />
            <h2 className="">Order Details</h2>
          </div>
        </div>

        <div className="order_details_info">
          <h5 style={{ color: "#00000084" }}>
            Order ID: <span>#{data?._id?.slice(0, 8)}</span>
          </h5>
          {/* </br> */}
          <h5 style={{ color: "#00000084" }}>
            Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
          </h5>
        </div>

        {/* order items */}
        <br />
        <br />
        {data &&
          data?.cart.map((item, index) => {
            return (
              <div className="order_item_container">
                {item.images[0].url ? (
                  <img
                    src={`${item.images[0].url}`}
                    alt=""
                    className="order_item_image"
                  />
                ) : null}
                <div className="order_item_wrap">
                  <h5 className="order_item_info">{item.name}</h5>
                  <p className="order_item_description">{item.description}</p>
                  <h5 className="order_item_info" style={{ color: "#5e97b7" }}>
                    US${item.discountPrice} x {item.qty}
                  </h5>
                </div>
                {!item.isReviewed && data?.status === "Delivered" ? (
                  <div
                    className="blue_btn order_item_button"
                    onClick={() => setOpen(true) || setSelectedItem(item)}
                  >
                    Write a review
                  </div>
                ) : null}
              </div>
            );
          })}

        {/* Bottom Info */}
        <div className="order_details_bottom_container">
          <h5 className="pt-3 text-[18px]">
            Total Price: <strong>US${data?.totalPrice}</strong>
          </h5>
        </div>
        <br />
        <br />
        <div className="w-full 800px:flex items-center">
          <div className="w-full 800px:w-[60%]">
            <h4 className="pt-3 text-[20px] font-[600]">Shipping Address:</h4>
            <h4 className="pt-3 text-[20px]">
              {data?.shippingAddress.address1 +
                " " +
                data?.shippingAddress.address2}
            </h4>
            <h4 className=" text-[20px]">{data?.shippingAddress.country}</h4>
            <h4 className=" text-[20px]">{data?.shippingAddress.city}</h4>
            <h4 className=" text-[20px]">{data?.user?.phoneNumber}</h4>
          </div>
          <div className="w-full 800px:w-[40%]">
            <h4 className="pt-3 text-[20px]">Payment Info:</h4>
            <h4>
              Status:{" "}
              {data?.paymentInfo?.status
                ? data?.paymentInfo?.status
                : "Not Paid"}
            </h4>
            <br />
            {data?.status === "Delivered" && (
              <div
                className="button_black"
                // className={`${styles.button} text-white`}
                onClick={refundHandler}
              >
                Get a Refund
              </div>
            )}
          </div>
        </div>
        <br />
        <Link to="/">
          <div
          // className={`${styles.button} text-white`}
          >
            Send Message
          </div>
        </Link>
        <br />
        <br />
      </div>

      {/* review popup */}
      {/* {open && ( */}
      {open && (
        <div className="order_details_modal" useRef={popup}>
          <div className="odm_container">
            <div className="odm_close_wrap">
              <RxCross1
                size={30}
                onClick={() => setOpen(false)}
                className="odm_close"
              />
            </div>
            <h2 className="odm_title">Give a Review</h2>
            <br />
            <div className="w-full flex">
              <img
                src={`${selectedItem?.images[0]?.url}`}
                alt=""
                style={{ width: "80px", height: "80px" }}
              />
              <div>
                <div className="pl-3 text-[20px]">{selectedItem?.name}</div>
                <h4 className="pl-3 text-[20px]">
                  US${selectedItem?.discountPrice} x {selectedItem?.qty}
                </h4>
              </div>
            </div>

            <br />
            <br />

            {/* ratings */}
            <h5 className="pl-3 text-[20px] font-[500]">
              Give a Rating <span className="text-red-500">*</span>
            </h5>
            <div className="flex w-full ml-2 pt-1">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            <div className="odm_comment_container">
              <label className="odm_comment">
                Write a comment
                <span className="ml-1 font-[400] text-[16px] text-[#00000052]">
                  (optional)
                </span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? Write your thoughts on it!"
                className="mt-2 w-[95%] border p-2 outline-none"
              ></textarea>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="black_button"
              onClick={rating > 1 ? reviewHandler : null}
            >
              Submit
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOrderDetails;
