import React, { useEffect, useState } from "react";
import { serviceData } from "../../data/staticData";
import { useNavigate } from "react-router-dom";

import "./service.css";

const Service = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const allServicesData = serviceData ? [...serviceData] : [];
    const sortedData = allServicesData?.filter((a) => a.featured === true);
    const firstSix = sortedData && sortedData.slice(0, 6);
    setData(firstSix);
  }, [serviceData]);

  return (
    <section>
      <div className="service">
        <h1 className="section_header">Appointment Services</h1>
        <div className="service_container">
          {data &&
            data.map((i, index) => {
              const handleSubmit = (i) => {
                navigate(`/services?category=${i.title}`);
              };
              return (
                <div
                  className="service_wrap"
                  key={index.id}
                  onClick={() => handleSubmit(i)}
                >
                  <img
                    src={i.image}
                    alt=""
                    className="service_wrap_image"
                    loading="lazy"
                  />
                  <div className="service_wrap_bottom">
                    <div>
                      <div className="service_wrap_name">{i.title}</div>
                      <div className="service_wrap_info">{i.description}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Service;
