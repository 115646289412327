import React from "react";
import { useSelector } from "react-redux";

import DashboardSidebar from "../../components/shop/layout/DashboardSidebar";
import WithdrawalMoney from "../../components/shop/WithdrawalMoney";
import Header from "../../components/header";

const ShopWithdrawalMoneyPage = () => {
  const { seller } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Header />
      <DashboardSidebar active={7} />
      <WithdrawalMoney seller={seller} />
    </>
  );
};

export default ShopWithdrawalMoneyPage;
