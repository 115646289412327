import { Form, Formik } from "formik";
import { useState } from "react";
import RegisterInput from "../inputs/registerInput";
import * as Yup from "yup";
import DotLoader from "react-spinners/DotLoader";
import axios from "axios";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function EateryRegisterForm({ setVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eateryInfos = {
    name: "",
    address: "",
    phoneNumber: "",
    zipCode: "",
    email: "",
    password: "",
    description: "",
  };

  const [eatery, setEatery] = useState(eateryInfos);

  const { name, address, phoneNumber, description, zipCode, email, password } =
    eatery;

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setEatery({ ...eatery, [name]: value });
  };

  const registerValidation = Yup.object({
    name: Yup.string()
      .required("What's your eateries name ?")
      .min(2, "Shop name must be between 2 and 16 characters.")
      .max(16, "Shop name must be between 2 and 16 characters.")
      .matches(/^[aA-zZ]+$/, "Numbers and special characters are not allowed."),
    email: Yup.string().required(
      "You'll need this when you log in and if you ever need to reset your password."
    ),
    address: Yup.string().required(
      "You'll need this to ship and receive products."
    ),
    zipCode: Yup.string().required(
      "You'll need this to ship and receive products."
    ),
    phoneNumber: Yup.number().required("So we can contact you."),
    password: Yup.string()
      .required(
        "Enter a combination of at least six numbers,letters and punctuation marks(such as ! and &)."
      )
      .min(6, "Password must be atleast 6 characters.")
      .max(36, "Password can't be more than 36 characters"),
  });
  const [dateError, setDateError] = useState("");
  const [genderError, setGenderError] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const registerSubmit = async () => {
    //   try {
    //     const { data } = await axios.post(
    //       `${process.env.REACT_APP_BACKEND_URL}/register`,
    //       {
    //         first_name,
    //         last_name,
    //         email,
    //         password,
    //         bYear,
    //         bMonth,
    //         bDay,
    //         gender,
    //       }
    //     );
    //     setError("");
    //     setSuccess(data.message);
    //   } catch (error) {
    //     setLoading(false);
    //     setSuccess("");
    //     setError(error.response.data.message);
    //   }
    // };
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/registerEatery`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          email,
          password,
          description,
        }
      );
      setError("");
      setSuccess(data.message);
      const { message, ...rest } = data;
      setTimeout(() => {
        dispatch({ type: "SELLER_LOGIN", payload: rest });
        Cookies.set("eatery", JSON.stringify(rest));
        navigate("/eatery-dashboard");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setSuccess("");
      setError(error.response.data.message);
    }
  };

  return (
    <div className="blur">
      <div className="register">
        <div className="register_header">
          <i className="exit_icon" onClick={() => setVisible(false)}></i>
          <span>Create a Eatery</span>
          <span>Just takes a second.</span>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            name,
            address,
            phoneNumber,
            zipCode,
            email,
            password,
            description,
          }}
          validationSchema={registerValidation}
          onSubmit={() => {
            registerSubmit();
          }}
        >
          {(formik) => (
            <Form className="register_form">
              <div className="reg_line">
                <RegisterInput
                  type="text"
                  placeholder="Eatery name"
                  name="name"
                  onChange={handleRegisterChange}
                />
              </div>
              <div className="reg_line">
                <RegisterInput
                  type="text"
                  placeholder="Eatery description"
                  name="description"
                  onChange={handleRegisterChange}
                />
              </div>
              {/* radio buttons */}
              {/* <div style={{ width: "80%", border: "1px solid gray" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <label style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="restaurant"
                      value="option1"
                      style={{ marginLeft: "10px" }}
                    />
                    <span style={{ marginLeft: "10px" }}>Restaurant</span>
                  </label>
                </div>
                <label>
                  <input type="radio" name="myRadio" value="option2" />
                  Food Truck
                </label>
                <label>
                  <input type="radio" name="myRadio" value="option3" />
                  Kitchen
                </label>
              </div> */}

              <RegisterInput
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleRegisterChange}
              />
              <RegisterInput
                type="text"
                placeholder="Zip code"
                name="zipCode"
                onChange={handleRegisterChange}
              />

              <RegisterInput
                type="number"
                placeholder="Phone number"
                name="phoneNumber"
                onChange={handleRegisterChange}
              />
              <div className="reg_line">
                <RegisterInput
                  type="text"
                  placeholder="Email address"
                  name="email"
                  onChange={handleRegisterChange}
                />
              </div>
              <div className="reg_line">
                <RegisterInput
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleRegisterChange}
                />
              </div>

              <div className="reg_infos">
                By clicking Sign Up, you are agreeing to our{" "}
                <span>Terms & Conditions, Cookie &nbsp;</span>
                and <span>Data Policy.</span>
              </div>
              <div className="reg_btn_wrapper">
                <button className="blue_btn open_signup">Eatery Sign Up</button>
              </div>
              <DotLoader color="#5e97b7" loading={loading} size={30} />
              {error && <div className="error_text">{error}</div>}
              {success && <div className="success_text">{success}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
