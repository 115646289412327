import React, { useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";

import "./canvawww.css";
import { useParams } from "react-router-dom";

const CanvawwwHero = () => {
  const [userEmail, setUserEmail] = useState("");

  const shopId = useParams();

  const handleEmailSubmit = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/getVendorEmail/${shopId.id}`,
        {
          userEmail,
        }
      );
      toast.success("Email sent successfully");
      setUserEmail("");
    } catch (error) {
      toast.error("Email already registered", error.message);
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="canvawww_hero">
      <div className="canvawww_hero_container">
        <div className="canvawww_hero_left">
          <h2 className="canvawww_hero_title">
            <span>CanvAwww:</span> <br />
            Your Personalized Paint and Sip Experience!
          </h2>
          <div className="canvawww_hero_text">
            Welcome to CanvAwww, where creativity meets fun in the comfort of
            your own home! Our paint and sip experiences are designed to bring
            the joy of art to you and your friends, creating unforgettable
            memories thru paint and cheer.
          </div>
        </div>
        <div className="canvawww_hero_right">
          <div className="canvawww_email_container">
            <label htmlFor="email" className="canvawww_email_text">
              Sign up for the wait list
            </label>
            <div>
              <input
                type="email"
                placeholder="Enter an email address"
                className="eatery_page_input"
                onChange={(e) => setUserEmail(e.target.value)}
                value={userEmail}
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  background: "transparent",
                }}
              />
              <button onClick={handleEmailSubmit} className="black_button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hero_grad"></div>
      <video
        src="https://res.cloudinary.com/dcinefb4x/video/upload/v1706582655/assets/paintBg_3_l8dxne.mp4"
        autoPlay
        muted
        loop
        type="video/mp4"
      />
    </div>
  );
};

export default CanvawwwHero;
