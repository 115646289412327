import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ShopProfileData from "../../components/shop/ShopProfileData";
import Header from "../../components/header";
import Spinner from "../../components/shared/spinner/Spinner";
import TrulyRememberedPage from "../trulyremembered/TrulyRememberedPage";
import ShopCover from "./ShopCover";
import ShopProfilePictureInfos from "./ShopProfilePictureInfos";
import AmLabsPage from "../amlabs/AmLabsPage";
import CanvawwwPage from "../canvawww/CanvawwwPage";
import { axiosInstance } from "../../functions/axiosInstance";

import "./shop-profile.css";

const ShopProfilePage = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { seller } = useSelector((state) => ({ ...state }));

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/shop/getShopInfo/${id}`)
      .then((res) => {
        setData(res.data?.shop);
        isLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   const userData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/userShop`,
  //         // { userId: data.userId }
  //         { userId: data.userId }
  //       );

  //       const profilePictureUrl = response.data;

  //       setImageUrl(profilePictureUrl);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   userData();
  // }, [data.userId]);

  const profileTop = useRef(null);

  var visitor = data?._id === seller?.id ? false : true;

  const trulyremembered = data?._id === "65b2b61406fb1c1c4f7d21e2";
  const amlabs = data?._id === "65c7fe69a52186793458c9cd";
  const canvawww = data?._id === "65c7adf5ceafddb818291935";

  return (
    <>
      <Header />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {trulyremembered ? (
            <TrulyRememberedPage data={data} />
          ) : (
            <>
              {amlabs ? (
                <>
                  <AmLabsPage data={data} />
                </>
              ) : (
                <>
                  {canvawww ? (
                    <CanvawwwPage data={data} />
                  ) : (
                    <div className="shop_profile">
                      <div className="shop_profile_top" ref={profileTop}>
                        <ShopCover
                          data={data}
                          cover={data?.coverPicture}
                          visitor={visitor}
                        />
                        <ShopProfilePictureInfos
                          profile={data}
                          visitor={visitor}
                          photos={photos.resources}
                          imageUrl={imageUrl}
                          loading={isLoading}
                          data={data}
                        />
                      </div>
                      <div className="shop_profile_container">
                        <ShopProfileData isOwner={false} data={data} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ShopProfilePage;
