import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header";

const OrderSuccessPage = () => {
  return (
    <div style={{ marginTop: "4rem" }}>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  return (
    <div
      style={{
        marginTop: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "60vh",
        textAlign: "center",
      }}
    >
      <h3>Thank you, your order is successful.</h3>
      <br />
      <span>You with receive an email with purchase details shortly.</span>
      <br />
      <br />
    </div>
  );
};

export default OrderSuccessPage;
