import React from "react";
import { Link } from "react-router-dom";

import "./banner.css";

const HomeServiceBanner = () => {
  return (
    <div className="home_page_product_banner">
      <div className="hppb_info">
        <span className="hppb_title">AM Labs</span>
        <span className="hppb_text">
          Professional, Websites, Apps, Graphics, for your business.
        </span>
      </div>

      <div className="hppb_button_wrap">
        <Link to="/shop/preview/65c7fe69a52186793458c9cd">
          {/* <Link to={`/shop/preview/656e19f75341fb414e5bde8b`}> */}
          <button className="black_button">Order now</button>
        </Link>
      </div>
      <div className="hppb_main_image_wrap">
        <img
          src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151320/assets/AMLabs_service_banner_fndvd1.png"
          alt=""
          className="hppb_main_image"
          loading="lazy"
        />
        <span className="hppb_creator_text">Creators of Nxxtstore</span>
      </div>
    </div>
  );
};

export default HomeServiceBanner;
