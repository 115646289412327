import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ProductFeaturedItem = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const allProductsData = data ? [...data] : [];
    const sortedData = allProductsData?.filter((a) => a.featured === true);
    const firstFour = sortedData && sortedData.slice(0, 4);
    setFilteredData(firstFour);
  }, [data]);

  return (
    <>
      {filteredData.map((product, i) => (
        <>
          <Link
            to={`/product/${product._id}`}
            style={{
              width: "40%",
              height: "90px",
            }}
          >
            <img
              src={product.images[0].url}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              key={i}
            />
          </Link>
        </>
      ))}
    </>
  );
};

export default ProductFeaturedItem;
