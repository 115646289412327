import Cookies from "js-cookie";

export function sellerReducer(
  state = Cookies.get("seller") ? JSON.parse(Cookies.get("seller")) : null,

  action
) {
  switch (action.type) {
    case "SELLER_LOGIN":
      return action.payload;
    case "SELLER_LOGOUT":
      return null;
    case "SELLER_UPDATEPICTURE":
      return { ...state, picture: action.payload };
    case "SELLER_VERIFY":
      return { ...state, verified: action.payload };

    default:
      return state;
  }
}
