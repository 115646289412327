import { Link } from "react-router-dom";

import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-lists">
        <ul className="footer-list">
          <li className="footer-list_item">
            <div className="footer_sell_btn">
              <Link to="/seller-type">Sell</Link>
            </div>
          </li>
        </ul>

        <ul className="footer-list">
          <li className="footer-list_item">
            <h2>Buy</h2>
          </li>
          <li className="footer-list_item">
            <Link to="/products">Products</Link>
          </li>
          <li className="footer-list_item">
            <Link to="/services">Services</Link>
          </li>
          <li className="footer-list_item">
            <Link to="/gigs">Gigs</Link>
          </li>
          <li className="footer-list_item">
            <Link to="/eateries">Eateries</Link>
          </li>
        </ul>

        <ul className="footer-list">
          <li className="footer-list_item">
            <h2>About</h2>
          </li>
          <li className="footer-list_item">Press center</li>
          <li className="footer-list_item">Careers</li>
          <li className="footer-list_item">Investor relations</li>
          <li className="footer-list_item">Advertise with us </li>
        </ul>
      </div>
      <div className="footer-text">Copyright © 2024, Nxxtstore Inc.</div>
    </div>
  );
};

export default Footer;
