import { axiosInstance } from "./axiosInstance";

export const updateprofilePicture = async (url, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/updateProfilePicture`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateCover = async (url, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/updateCover`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const addFriend = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/addFriend/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const cancelRequest = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/cancelRequest/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const follow = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/follow/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    console.log(error.response.data.message);
    return error.response.data.message;
  }
};

export const unfollow = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/unfollow/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const acceptRequest = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/acceptRequest/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const unfriend = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/unfriend/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteRequest = async (id, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/deleteRequest/${id}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

// export const search = async (searchTerm, token) => {
//   try {
//     const { data } = await axiosInstance.post(
//       `${process.env.REACT_APP_BACKEND_URL}/user/search/${searchTerm}`,
//       {},

//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// test
export const searchUser = async (searchTerm, token) => {
  try {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/searchUser/${searchTerm}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addToSearchHistory = async (searchUser, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/addToSearchHistory`,
      { searchUser },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSearchHistory = async (token) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/getSearchHistory`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const removeFromSearch = async (searchUser, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/removeFromSearch`,
      { searchUser },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getFriendsPageInfos = async (token) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/getFriendsPageInfos`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
