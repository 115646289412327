import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShopLoginForm from "../../components/shop-create/ShopLoginForm";
import Footer from "../../components/footer/Footer";
import ShopRegisterForm from "../../components/shop-create/ShopRegisterForm";

import "./shop-login.css";

export default function ShopLoginPage() {
  const [visible, setVisible] = useState(false);

  return (
    <div className="shop_login">
      <div className="shop_login_wrapper">
        <ShopLoginForm setVisible={setVisible} />
        {visible && <ShopRegisterForm setVisible={setVisible} />}
        <Footer />
      </div>
    </div>
  );
}
