import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { categoriesData } from "../../data/categoriesData";
import "./categories.css";

const Categories = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);

  useEffect(() => {
    const allCategoriesData = categoriesData ? [...categoriesData] : [];
    const sortedData = allCategoriesData?.filter((a) => a.featured === true);
    const firstSix = sortedData && sortedData.slice(0, 6);
    setData(firstSix);
  }, []);

  return (
    <>
      <div className="categories" id="categories">
        <div className="categories_container">
          {data &&
            data.map((i) => {
              const handleSubmit = (i) => {
                navigate(`/products?category=${i.title}`);
                //temp reload
                window.location.reload();
              };
              return (
                <div
                  className="categories_item"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  <div className="categories_image_wrap">
                    <img
                      src={i.image_Url}
                      className="categories_image"
                      alt=""
                    />
                  </div>
                  <span className="categories_text">{i.title}</span>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Categories;
