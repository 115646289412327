import React from "react";
import { HashLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import popularPost from "../../data/popularPost.json";

import "./popular_post.css";

const PopularPost = ({ posts, loading }) => {
  return (
    <>
      {loading ? (
        <div className="sekelton_loader">
          <HashLoader color="#5e97b7" />
        </div>
      ) : (
        <section style={{ marginTop: "60px" }}>
          <div className="popular_post">
            <h1 className="section_header">Popular Posts</h1>
            <div className="popular_post_container">
              {/* posts 1 */}
              <div className="popular_post_card">
                <Link to={`/profile/${popularPost[0]?.profile}`}>
                  <div className="popular_post_header">
                    <div className="popular_post_header_info">
                      <img
                        src={popularPost[0].profilePicture}
                        alt=""
                        className="popular_post_picture"
                        loading="lazy"
                      />
                      <div className="popular_post_username">
                        {popularPost[0].username}
                      </div>
                    </div>
                  </div>
                  <div className="popular_post_text">
                    {popularPost[0]?.text}
                  </div>
                  <img
                    src={popularPost[0].image}
                    alt=""
                    className="popular_post_main_image"
                    loading="lazy"
                  />
                </Link>
              </div>

              {/* posts 2 */}
              <div className="popular_post_card">
                <Link to={`/profile/${popularPost[1]?.profile}`}>
                  <div className="popular_post_header">
                    <div className="popular_post_header_info">
                      <img
                        src={popularPost[1].profilePicture}
                        alt=""
                        className="popular_post_picture"
                        loading="lazy"
                      />
                      <div className="popular_post_username">
                        {popularPost[1].username}
                      </div>
                    </div>
                  </div>
                  <div className="popular_post_text">
                    {popularPost[1]?.text}
                  </div>
                  <img
                    src={popularPost[1].image}
                    alt=""
                    className="popular_post_main_image"
                    loading="lazy"
                  />
                </Link>
              </div>

              {/* posts 3 */}
              <div className="popular_post_card">
                <Link to={`/profile/${popularPost[2]?.profile}`}>
                  <div className="popular_post_header">
                    <div className="popular_post_header_info">
                      <img
                        src={popularPost[2].profilePicture}
                        alt=""
                        className="popular_post_picture"
                        loading="lazy"
                      />
                      <div className="popular_post_username">
                        {popularPost[2].username}
                      </div>
                    </div>
                  </div>
                  <div className="popular_post_text">
                    {popularPost[2]?.text}
                  </div>
                  <img
                    src={popularPost[2].image}
                    alt=""
                    className="popular_post_main_image"
                    loading="lazy"
                  />
                </Link>
              </div>

              {/* posts 4 */}
              <div className="popular_post_card">
                <Link to={`/profile/${popularPost[3]?.profile}`}>
                  <div className="popular_post_header">
                    <div className="popular_post_header_info">
                      <div className="popular_post_header_left">
                        <img
                          src={popularPost[2].profilePicture}
                          alt=""
                          className="popular_post_picture"
                          loading="lazy"
                        />

                        <div className="popular_post_username">
                          {popularPost[3].username}
                        </div>
                      </div>
                      <div className="popular_post_sponsored">
                        <span>Sponsored</span>
                      </div>
                    </div>
                  </div>
                  <div className="popular_post_text">
                    {popularPost[3]?.text}
                  </div>
                  <img
                    src={popularPost[3].image}
                    alt=""
                    className="popular_post_main_image"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PopularPost;
