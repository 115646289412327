import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowRight, AiOutlineSend } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
import socketIO from "socket.io-client";
import { format } from "timeago.js";

import "./style.css";
import { axiosInstance } from "../../functions/axiosInstance";

const ENDPOINT = "https://nxxtstore-cd377c16eb45.herokuapp.com";
const socketId = socketIO(ENDPOINT, { transports: ["websocket"] });

const DashboardMessages = () => {
  const { seller, isLoading } = useSelector((state) => ({ ...state }));

  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [images, setImages] = useState();
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    socketId.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversation = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/conversation/getAllConversationSeller/${seller?.id}`,
          {
            headers: {
              Authorization: `Bearer ${seller.token}`,
            },
          }
        );
        console.log(response.data);
        setConversations(response.data.conversations);
        // setConversations(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getConversation();
  }, [seller, messages]);

  useEffect(() => {
    if (seller) {
      const sellerId = seller?.id;
      socketId.emit("addUser", sellerId);
      socketId.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [seller]);

  // online check
  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== seller?.id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);
    // setActiveStatus(online ? true : false);

    return online ? true : false;
  };

  // get messages
  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/message/getAllMessages/${currentChat?._id}`
        );
        // setMessages(response.data.messages);
        setMessages(response.data.messages);
        console.log(response.data.messages);
      } catch (error) {
        console.log(error);
      }
    };
    getMessage();
  }, [currentChat]);

  // create new message
  const sendMessageHandler = async (e) => {
    e.preventDefault();
    const message = {
      // sender: seller._id,
      sender: seller.id,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      // (member) => member.id !== seller._id
      (member) => member.id !== seller.id
    );
    socketId.emit("sendMessage", {
      // senderId: seller._id,
      senderId: seller.id,
      receiverId,
      text: newMessage,
    });
    try {
      if (newMessage !== "") {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/message/createNewMessage`,
            message
          )
          .then((res) => {
            setMessages([...messages, res.data.message]);
            updateLastMessage();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateLastMessage = async () => {
    socketId.emit("updateLastMessage", {
      lastMessage: newMessage,
      lastMessageId: seller.id,
    });

    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/conversation/updateLastMessage/${currentChat._id}`,
        {
          lastMessage: newMessage,
          lastMessageId: seller.id,
        }
      )
      .then((res) => {
        console.log(res.data.conversation);
        setNewMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleImageUpload = async (e) => {
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       setImages(reader.result);
  //       imageSendingHandler(reader.result);
  //     }
  //   };

  //   reader.readAsDataURL(e.target.files[0]);
  // };

  // const imageSendingHandler = async (e) => {
  //   const receiverId = currentChat.members.find(
  //     (member) => member !== seller._id
  //   );

  //   socketId.emit("sendMessage", {
  //     senderId: seller._id,
  //     receiverId,
  //     images: e,
  //   });

  //   try {
  //     await axios
  //       .post(`${process.env.REACT_APP_BACKEND_URL}/createNewMessage`, {
  //         images: e,
  //         sender: seller._id,
  //         text: newMessage,
  //         conversationId: currentChat._id,
  //       })
  //       .then((res) => {
  //         setImages();
  //         setMessages([...messages, res.data.message]);
  //         updateLastMessageForImage();
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const updateLastMessageForImage = async () => {
    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/conversation/updateLastMessage/${currentChat._id}`,
      {
        lastMessage: "Photo",
        lastMessageId: seller.id,
      }
    );
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  console.log(seller);

  return (
    <div className="createProduct">
      {!open && (
        <>
          <div className="createProduct_header">
            All messages, {seller?.name}
            <div className="create_splitter"></div>
          </div>
          {/* All messages list */}
          {conversations &&
            conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={seller.id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
                isLoading={isLoading}
              />
            ))}
        </>
      )}

      {open && (
        <SellerInbox
          setOpen={setOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessageHandler={sendMessageHandler}
          messages={messages}
          sellerId={seller.id}
          userData={userData}
          activeStatus={activeStatus}
          scrollRef={scrollRef}
          setMessages={setMessages}
          // handleImageUpload={handleImageUpload}
        />
      )}
    </div>
  );
};

const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  userData,
  online,
  setActiveStatus,
  isLoading,
}) => {
  const [user, setUser] = useState([]);
  const [active, setActive] = useState(0);

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`?${id}`);
    setOpen(true);
  };

  //checking user
  useEffect(() => {
    setActiveStatus(online);
    const userId = data.members.find((user) => user !== me);

    // console.log(userId);

    const getUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/userInfo/${userId}`
        );
        setUser(res.data.user);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [me, data]);

  console.log(userData);

  return (
    <div
      className={`messages_container ${
        active === index ? "active_message" : "unActive_message"
      } `}
      onClick={(e) =>
        setActive(index) ||
        handleClick(data._id) ||
        setCurrentChat(data) ||
        setUserData(user) ||
        setActiveStatus(online)
      }
    >
      <div style={{ position: "relative" }}>
        <img
          src={`${user?.picture}`}
          alt=""
          className="sender_message_image"
          style={{ cursor: "pointer" }}
        />
        {online ? (
          <div className="messages_active_user" />
        ) : (
          <div className="messages_unActive_user" />
        )}
      </div>
      <div className="pl-3">
        <h1 className="sender_message_name">{user?.username}</h1>
        <p className="text-[16px] text-[#000c]">
          {!isLoading && data?.lastMessageId !== user?.id
            ? "You:"
            : user?.username?.split(" ")[0] + ": "}
          {data?.lastMessage}
        </p>
      </div>
    </div>
  );
};

const SellerInbox = ({
  scrollRef,
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  handleImageUpload,
}) => {
  console.log(userData);
  return (
    <div className="seller_inbox">
      {/* message header */}
      <div className="seller_inbox_header">
        <div className="flex">
          <img
            src={`${userData?.picture}`}
            alt=""
            className="seller_inbox_image"
          />
          <div className="pl-3">
            <h1 className="seller_inbox_name">{userData?.username}</h1>
            <h1>{activeStatus ? "Active Now" : ""}</h1>
          </div>
        </div>
        <AiOutlineArrowRight
          size={20}
          className="cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="create_splitter"></div>

      {/* messages */}
      <div className="messages scrollbar">
        {messages &&
          messages.map((item, index) => {
            return (
              <div
                style={{ display: "flex" }}
                // className={`message_wrap ${
                //   item.sender === sellerId
                //     ? "seller_message_wrap"
                //     : "sender_message_wrap"
                // }`}
                ref={scrollRef}
              >
                {item.images && (
                  <img
                    src={`${item.images?.url}`}
                    className="w-[300px] h-[300px] object-cover rounded-[10px] mr-2"
                    alt=""
                  />
                )}
                {item.text !== "" && (
                  <div>
                    <div
                      className={`w-max p-2 rounded ${
                        item.sender === sellerId
                          ? "seller_message_wrap"
                          : "sender_message_wrap"
                      } text-[#fff] h-min`}
                    >
                      <p>{item.text}</p>
                    </div>

                    <p className="message_timeago">{format(item.createdAt)}</p>
                  </div>
                )}
                {item.sender !== sellerId && (
                  <Link to={`/profile/${item.username}`}>
                    <img
                      src={`${userData?.picture}`}
                      className="message_image"
                      alt=""
                    />
                  </Link>
                )}
              </div>
            );
          })}
      </div>

      {/* send message input */}
      <form
        // area-required={true}
        className="message_input"
        onSubmit={sendMessageHandler}
      >
        <div style={{ width: "30px", cursor: "pointer" }}>
          <input
            type="file"
            name=""
            id="image"
            className="hidden"
            onChange={handleImageUpload}
          />
          <label htmlFor="image">
            <TfiGallery className="cursor-pointer" size={20} />
          </label>
        </div>
        <div className="w-full" style={{ width: "100%" }}>
          <input
            type="text"
            required
            placeholder="Enter your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="input"
          />
          <input type="submit" value="Send" className="hidden" id="send" />
          <label htmlFor="send">
            <AiOutlineSend size={20} className="message_send_icon" />
          </label>
        </div>
      </form>
    </div>
  );
};

export default DashboardMessages;
