import Header from "../../components/header";
import AllServices from "../../components/service/AllServices";
import ServiceDashboardSideBar from "../../components/service/layout/ServiceDashboardSidebar";

const ServiceAllServices = () => {
  return (
    <>
      <Header />
      <ServiceDashboardSideBar active={3} />
      <AllServices />
    </>
  );
};

export default ServiceAllServices;
