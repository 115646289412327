import ShopProfileData from "../../components/shop/ShopProfileData";
import ShopInfo from "../../components/shop/ShopInfo";
import Header from "../../components/header";

const EateryPreviewPage = () => {
  return (
    <div>
      <Header />
      <div>
        <div>
          <ShopInfo isOwner={false} />
        </div>
        <div>
          <ShopProfileData isOwner={false} />
        </div>
      </div>
    </div>
  );
};

export default EateryPreviewPage;
