import React from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscNewFile } from "react-icons/vsc";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";

const DashboardSidebar = ({ active, setActive }) => {
  const navigate = useNavigate();

  const tempClick = () => {
    console.log("clicked");
  };

  return (
    <div className="dashboard_left">
      {/* single item */}
      <div className="db_item hover0">
        <Link to="/service-dashboard">
          <div className="small_circle">
            <RxDashboard size={24} color={active === 1 ? "#5e97b7" : ""} />
          </div>
          <span className={active === 1 ? "#5e97b7" : ""}>Dashboard</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div>

      <div className={`db_item hover0`}>
        <Link to="/service-orders">
          <div className="small_circle">
            <FiShoppingBag size={24} color={active === 2 ? "#5e97b7" : ""} />
          </div>
          <span>All Orders</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div>

      <div className={`db_item hover0`}>
        <Link to="/service-services">
          <div className="small_circle">
            <FiPackage size={24} color={active === 3 ? "#5e97b7" : ""} />
          </div>
          <span>All Services</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div>

      <div className={`db_item hover0`}>
        <Link to="/dashboard-create-service">
          <div className="small_circle">
            <AiOutlineFolderAdd
              size={24}
              color={active === 4 ? "#5e97b7" : ""}
            />
          </div>
          <span>Create Service</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div>

      {/* <div className={`db_item hover0`}>
        <Link to="/dashboard-events">
          <div className="small_circle">
            <MdOutlineLocalOffer
              size={24}
              color={active === 5 ? "#5e97b7" : ""}
            />
          </div>
          <span>All Events</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div> */}

      {/* <div className={`db_item hover0`}>
        <Link to="/dashboard-create-event">
          <div className="small_circle">
            <VscNewFile size={24} color={active === 6 ? "#5e97b7" : ""} />
          </div>
          <span>Create Event</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div> */}

      <div className={`db_item hover0`}>
        <Link to="/service-withdraw-money">
          <div className="small_circle">
            <CiMoneyBill size={24} color={active === 7 ? "#5e97b7" : ""} />
          </div>
          <span>Withdraw Money</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div>

      <div
        className={`db_item hover0`}
        // onClick={() => setActive(4) || navigate("/inbox")}
      >
        <Link to="/service-messages">
          <div className="small_circle">
            <BiMessageSquareDetail
              size={24}
              color={active === 8 ? "#5e97b7" : ""}
            />
          </div>
          <span>Service Inbox</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div>

      {/* <div className={`db_item hover0`}>
        <Link to="/dashboard-coupons">
          <div className="small_circle">
            <AiOutlineGift size={24} color={active === 9 ? "#5e97b7" : ""} />
          </div>
          <span>Discount Codes</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div> */}

      {/* <div className={`db_item hover0`}>
        <Link to="/dashboard-refunds">
          <div className="small_circle">
            <HiOutlineReceiptRefund
              size={24}
              color={active === 10 ? "#5e97b7" : ""}
            />
          </div>
          <span>Refunds</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div> */}

      {/* <div className={`db_item hover0`}>
        <Link to="/dashboard-settings">
          <div className="small_circle">
            <CiSettings size={24} color={active === 11 ? "#5e97b7" : ""} />
          </div>
          <span>Settings</span>
          <div className="dbArrow">
            <i className="right_icon"></i>
          </div>
        </Link>
      </div> */}
    </div>
  );
};

export default DashboardSidebar;
