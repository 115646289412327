import { Dots, NewRoom, Search } from "../../../svg";
import Contact from "./Contact";

import "./style.css";

export default function RightHome({ user }) {
  const color = "#65676b";

  return (
    <div className="right_home">
      <div className="sponsored">
        <div className="heading">Sponsored</div>
        <div className="sponsor_top">
          <span>AM Labs</span>
          <span>Truly Remembered</span>
          {/* <img src="../../../../public/images/postBackgrounds/4.jpg" alt="" /> */}
        </div>
      </div>

      <div className="splitter1"></div>
      <div className="contacts_wrap">
        <div className="contacts_list">
          Following Newest
          <Contact user={user} />
        </div>
      </div>
    </div>
  );
}
