import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Cookies from "js-cookie";

import Navbar from "../categoryMenu/Navbar";
import { serviceSubData, remoteServiceData } from "../../data/serviceData";
import { servicersWithin } from "../../functions/servicer";

import "./service.css";

const ServiceHero = () => {
  const [services, setServices] = useState(null);

  const check = useMediaQuery({
    query: "(min-width:560px)",
  });

  useEffect((position) => {
    const servicesData = async (position) => {
      const userLocation = Cookies.get("userLocation");

      if (userLocation) {
        const { latitude, longitude } = JSON.parse(userLocation);
        const lat = latitude;
        const lng = longitude;

        const result = await servicersWithin(lat, lng);
        const servicesObject = result?.services;
        setServices(servicesObject);
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    servicesData();
  }, []);

  return (
    <div className="service_hero">
      <div className="service_hero_title">
        {check ? (
          <span>
            Get the services you need,
            <br />
            <span>when you need them.</span>
          </span>
        ) : (
          <span>All the services you need.</span>
        )}
      </div>
      <div className="service_hero_subcategory_card">
        <span className="service_hero_subcategory_title">Local services</span>
        <div className="service_hero_subcategory_images">
          {services &&
            services.map((service) => (
              <div className="service_hero_subcategory_wrap">
                <img
                  src={service.picture}
                  alt={service.name}
                  className="service_hero_subcategory_image"
                />
                {service.name}
              </div>
            ))}
        </div>
      </div>
      <div className="service_hero_button_container">
        {serviceSubData.map((service, i) => (
          <button
            className="service_hero_button white_glassmorphism"
            key={i.id}
          >
            {service.title}
          </button>
        ))}{" "}
      </div>
      <Navbar />
    </div>
  );
};

export default ServiceHero;
