import React from "react";
import { Link } from "react-router-dom";

const ProductHeroItem = ({ shop }) => {
  return (
    <>
      <Link to={`/shop/preview/${shop?._id}`}>
        <img src={shop.picture} alt="" className="phc_image" />
        <div key={shop.id}>{shop.name}</div>
      </Link>
    </>
  );
};

export default ProductHeroItem;
