import React from "react";
import { Link } from "react-router-dom";

import { navItems } from "../../data/staticData";

import "./style.css";

const Navbar = ({ active }) => {
  return (
    <div className="category_nav_items">
      <div className="category_navbar">
        <div className="category_navbar_container">
          {navItems &&
            navItems.map((i, index) => (
              <div className="navbar_item" key={index}>
                <Link to={i.url}>
                  <img src={i.imageUrl} alt="" className="navbar_image" />
                </Link>
                <Link
                  to={i.url}
                  className={`${
                    active === index + 1 ? "text-[#17dd1f]" : "text-[#fff]"
                  } font-[500] px-6 cursor-pointer}`}
                >
                  {i.title}
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
