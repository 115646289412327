import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ProductCard from "../product/productCard/ProductCard";

const BestDeals = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allProducts]);

  return (
    <section>
      <h1 className="section_header" style={{ marginTop: "40px" }}>
        Best Deals
      </h1>
      <div
        className="section_container"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
      </div>
    </section>
  );
};
export default BestDeals;
