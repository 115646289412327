import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HashLoader } from "react-spinners";
import CreatePost from "../../components/createPost";
import Header from "../../components/header";
import LeftFeed from "../../components/feed/left";
import RightFeed from "../../components/feed/right";
import SendVerification from "../../components/feed/sendVerification";
import Post from "../../components/post";
import { Outlet } from "react-router-dom";
import FeedContent from "../../components/feed/content/FeedContent";

import "./style.css";

export default function Feed({ setVisible, posts, loading, getAllPosts }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [height, setHeight] = useState();
  const [active, setActive] = useState(1);

  const middle = useRef(null);

  useEffect(() => {
    setHeight(middle.current.clientHeight);
    window.scrollTo(0, 0);
  }, [loading]);

  return (
    <div>
      <div className="home">
        <Header page="home" getAllPosts={getAllPosts} />
        <LeftFeed
          user={user}
          active={active}
          setActive={setActive}
          posts={posts}
        />
        <div className="home_middle" ref={middle}>
          {/* {user.verified === false && <SendVerification user={user} />} */}
          <CreatePost user={user} setVisible={setVisible} />
          {loading ? (
            <div className="sekelton_loader">
              <HashLoader color="#1876f2" />
            </div>
          ) : (
            <>
              <FeedContent
                active={active}
                setActive={setActive}
                posts={posts}
              />
            </>
          )}
        </div>
        <RightFeed user={user} />
      </div>
    </div>
  );
}
