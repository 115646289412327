import { axiosInstance } from "../../functions/axiosInstance";

// get all orders of user
export const getAllOrdersOfUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllOrdersUserRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/order/getAllOrders/${userId}`
    );

    dispatch({
      type: "getAllOrdersUserSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "getAllOrdersUserFailed",
      payload: error.response.data.message,
    });
  }
};

// get all orders of seller
export const getAllOrdersOfShop = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllOrdersShopRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/order/getSellerAllOrders/${shopId}`
    );

    dispatch({
      type: "getAllOrdersShopSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "getAllOrdersShopFailed",
      payload: error.response.data.message,
    });
  }
};

// get all orders of servicer
export const getAllOrdersOfServicer = (servicerId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllOrdersServicerRequest",
    });
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/order/getServicerAllOrders/${servicerId}`
    );
    dispatch({
      type: "getAllOrdersServicerSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "getAllOrdersServicerFailed",
      payload: error.response.data.message,
    });
  }
};
// get all orders of Admin
export const getAllOrdersOfAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "adminAllOrdersRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/order/order/admin-all-orders`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "adminAllOrdersSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "adminAllOrdersFailed",
      payload: error.response.data.message,
    });
  }
};
