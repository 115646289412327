import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginInput from "../inputs/loginInput";
import DotLoader from "react-spinners/DotLoader";
import axios from "axios";
import Cookies from "js-cookie";
import Header from "../header";
import Footer from "../footer/Footer";
// import ImageBg from "../../../public/images/homePage_rev_1.jpg";

const eateryLoginInfos = {
  email: "",
  password: "",
};

export default function EateryLoginForm({ setVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, setLogin] = useState(eateryLoginInfos);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { email, password } = login;

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  const loginValidation = Yup.object({
    email: Yup.string()
      .required("Email address is required.")
      .email("Must be a valid email.")
      .max(100),
    password: Yup.string().required("Password is required"),
  });

  const loginSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/eateryLogin`,
        {
          email,
          password,
        }
      );
      dispatch({ type: "SELLER_LOGIN", payload: data });
      Cookies.set("seller", JSON.stringify(data));
      navigate("/eatery-dashboard");
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <Header />
      <div className="shop_login">
        <div className="shop_login_1">
          <div className="shop_login_2">
            <div className="shop_login_2_wrap">
              <h1>Eatery Login</h1>
              <span>Sell, Create, & Interact.</span>

              <Formik
                enableReinitialize
                initialValues={{
                  email,
                  password,
                }}
                validationSchema={loginValidation}
                onSubmit={() => {
                  loginSubmit();
                }}
              >
                {(formik) => (
                  <Form>
                    <LoginInput
                      type="text"
                      name="email"
                      placeholder="Email address"
                      onChange={handleLoginChange}
                    />

                    <LoginInput
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleLoginChange}
                      bottom
                    />
                    <button type="submit" className="blue_btn">
                      Eatery Login
                    </button>
                  </Form>
                )}
              </Formik>
              <Link to="/reset" className="forgot_password">
                Forgotten password?
              </Link>
              <DotLoader color="#5e97b7" loading={loading} size={30} />
              {error && <div className="error_text">{error}</div>}
              <div className="sign_splitter"></div>
              <button
                className="black_button open_signup"
                onClick={() => setVisible(true)}
              >
                Create Eatery Account
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
