import React from "react";

import ServiceDashboardSideBar from "../../components/service/layout/ServiceDashboardSidebar";
import AllServiceOrders from "../../components/service/AllServiceOrders";
import Header from "../../components/header";

const ServicerAllOrders = () => {
  return (
    <>
      <Header />
      <ServiceDashboardSideBar active={2} />
      <AllServiceOrders />
    </>
  );
};

export default ServicerAllOrders;
