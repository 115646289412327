import React from "react";
import { useSelector } from "react-redux";

import ServiceDashboardSidebar from "../../components/service/layout/ServiceDashboardSidebar";
import ServiceDashboardMessages from "../../components/service/ServiceDashboardMessages";
import Header from "../../components/header";

const ServiceInboxPage = () => {
  const { servicer } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Header />
      <ServiceDashboardSidebar active={8} />
      <ServiceDashboardMessages servicer={servicer} />
    </>
  );
};

export default ServiceInboxPage;
