import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ServiceDetailsCard from "./ServiceDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../store/actions/wishlistActions";
import { addToCart } from "../../../store/actions/cartActions";

import { toast } from "react-toastify";

import "./service-card.css";

const ServiceCard = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);

  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart");
    } else {
      if (data.stock < 1) {
        toast.error(`Sorry limited amount in stock`);
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addToCart(cartData));
        toast.success("Item added to cart successfully");
      }
    }
  };

  return (
    <>
      <div className="service_card" key={data.i}>
        <Link to={`/book-appointment/${data._id}`}>
          <img src={data.images[0]?.url} alt="" className="service_image" />
        </Link>

        <div className="service_details">
          <div className="service_servicer_info">
            <Link to={`/servicer/preview/${data.servicerId}`}>
              <img src={data.servicer.picture} alt="" className="service_pp" />
            </Link>
            <Link to={`/book-appointment/${data._id}`}>
              <h3 className="service_name">{data?.name}</h3>
            </Link>
          </div>
          <h4 className="service_description">
            {data?.description?.length > 40
              ? data?.description.slice(0, 40) + "..."
              : data?.description}
          </h4>
          <span className="service_description">
            {data.servicer.licensed && (
              <span>Lic. #{data.servicer.licenseNumber}</span>
            )}
          </span>

          <div className="service_timings">
            Open {data.timings[0]} am - {data.timings[1]} pm
          </div>
        </div>
        <div className="service_rating">
          <div className="service_star_wrap">
            <AiFillStar className="service_star" size={20} color="#F6BA00" />
            <AiFillStar className="service_star" size={20} color="#F6BA00" />
            <AiFillStar className="service_star" size={20} color="#F6BA00" />
            <AiFillStar className="service_star" size={20} color="#F6BA00" />
            <AiOutlineStar className="service_star" size={20} color="#F6BA00" />
          </div>
          <span className="product_sold">{data?.sold_out} sold</span>
        </div>
        <hr className="" />

        <div className="service_info">
          <div style={{ display: "flex", marginTop: "10px" }}>
            <h5>${data.feePerHour} per hour</h5>
          </div>
        </div>
      </div>

      {/* side options */}
      <div>
        {click ? (
          <AiFillHeart
            size={22}
            className="service_heart_icon"
            onClick={() => removeFromWishlistHandler(data)}
            color={click ? "red" : "#333"}
            title="Remove from wishlist"
          />
        ) : (
          <AiOutlineHeart
            size={22}
            className="service_heart_icon"
            onClick={() => addToWishlistHandler(data)}
            color={click ? "red" : "#333"}
            title="Add to wishlist"
          />
        )}

        {/* open */}
        {/* {open ? <ServiceDetailsCard setOpen={setOpen} data={data} /> : null} */}
      </div>
      {/* </div> */}
    </>
  );
};

export default ServiceCard;
