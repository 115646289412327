import AllProducts from "../../components/shop/AllProducts";
import DashboardSidebar from "../../components/shop/layout/DashboardSidebar";
import Header from "../../components/header";

const ShopAllProducts = () => {
  return (
    <>
      <Header />
      <DashboardSidebar active={3} />
      <AllProducts />
    </>
  );
};

export default ShopAllProducts;
