import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { addToCart } from "../../../store/actions/cartActions";
import { toast } from "react-toastify";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../store/actions/wishlistActions";

import "./product-card.css";

const ProductDetailsCard = ({ setOpen, data, shops }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);

  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);

  const dispatch = useDispatch();

  const handleMessageSubmit = () => {};

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart");
    } else {
      if (data.stock < count) {
        toast.error(`Sorry limited amount in stock`);
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addToCart(cartData));
        toast.success("Item added to cart successfully");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <>
      {data ? (
        <div className="pdc_container">
          <div className="pdc_wrap">
            <RxCross1
              size={30}
              className="pdc_cross hover1"
              onClick={() => setOpen(false)}
            />

            <div className="pdc_wrap_left">
              <img src={data.images[0]?.url} alt="" className="pdc_image" />
              <div className="flex">
                {/* <img
                    src={data?.shop.shop_avatar.url}
                    alt=""
                    className="pdc_shop_avatar"
                  /> */}
                <img
                  src={data.shop.picture}
                  alt=""
                  className="pdc_shop_avatar"
                />
                <div style={{ display: "flex" }}>
                  <h3 className="pdc_shop_name">{data.shop.name}</h3>
                  {/* <p className="pdc_ratings">({data.shop.ratings}) Ratings</p> */}
                </div>
              </div>
              {/* <div>
                <div className="pdc_message" onClick={handleMessageSubmit}>
                  <span>
                    Send Message{" "}
                    <AiOutlineMessage size={20} style={{ marginLeft: "5px" }} />
                  </span>
                </div>
                <h5 className="pdc_soldout">({data.total_sell}) Sold</h5>
              </div> */}
            </div>
            <div className="pdc_wrap_right">
              <h3 className="pdc_name">{data.name}</h3>
              <p className="pdc_description">{data.description}</p>

              <div className="pdc_price_wrap">
                <h4 className="">${data.discount_price}</h4>
                <h3
                  className="pdc_discount_price
                  t"
                >
                  {data.price ? "$" + data.price : null}
                </h3>
              </div>
              <div className="flex items-center mt-12 justify-between pr-3">
                <div>
                  <button
                    className="pdc_decremement_button"
                    onClick={decrementCount}
                  >
                    -
                  </button>
                  <span className="pdc_count">{count}</span>
                  <button
                    className="pdc_increment_button"
                    onClick={incrementCount}
                  >
                    +
                  </button>
                </div>
                <div>
                  {click ? (
                    <AiFillHeart
                      size={30}
                      style={{ cursor: "pointer" }}
                      onClick={() => removeFromWishlist(data)}
                      color={click ? "red" : "#333"}
                      title="Remove from wishlist"
                    />
                  ) : (
                    <AiOutlineHeart
                      size={30}
                      style={{ cursor: "pointer" }}
                      onClick={() => addToWishlistHandler(data)}
                      color={click ? "red" : "#333"}
                      title="Add to wishlist"
                    />
                  )}
                </div>
              </div>
              <div
                className="pdc_cart_button"
                onClick={() => addToCartHandler(data._id)}
              >
                <span>
                  Add to cart <AiOutlineShoppingCart className="ml-1" />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductDetailsCard;
