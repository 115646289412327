import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineTrackChanges, MdRssFeed } from "react-icons/md";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { AiOutlineCreditCard, AiOutlineMessage } from "react-icons/ai";
import { IoBagHandleOutline } from "react-icons/io5";
import { TbAddressBook } from "react-icons/tb";

const OrderSidebar = ({ active, setActive }) => {
  const navigate = useNavigate();
  const { type } = useParams();

  return (
    <div className="friends_left_wrap">
      {/* info */}
      <div
        className={`mmenu_item hover0 ${
          type === "requests" && "active_friends"
        }`}
        onClick={() => setActive(1)}
      >
        <div className="small_circle">
          <IoBagHandleOutline size={24} color={active === 1 ? "#5e97b7" : ""} />
        </div>
        <span className={active === 1 ? "#5e97b7" : ""}>Info</span>
        <div className="rArrow">
          <i className="right_icon"></i>
        </div>
      </div>

      {/* order */}
      <div
        className={`mmenu_item hover0 ${
          type === "requests" && "active_friends"
        }`}
        onClick={() => setActive(2)}
      >
        <div className="small_circle">
          <IoBagHandleOutline size={24} color={active === 2 ? "#5e97b7" : ""} />
        </div>
        <span className={active === 2 ? "#5e97b7" : ""}>Orders</span>
        <div className="rArrow">
          <i className="right_icon"></i>
        </div>
      </div>

      {/* refund */}
      <div
        className={`mmenu_item hover0 ${type === "sent" && "active_friends"}`}
        onClick={() => setActive(3)}
      >
        <div className="small_circle">
          <HiOutlineReceiptRefund
            size={24}
            color={active === 3 ? "#5e97b7" : ""}
          />
        </div>
        <span>Returns</span>
        <div className="rArrow">
          <i className="right_icon"></i>
        </div>
      </div>

      {/* messages */}
      <div
        className="mmenu_item hover0"
        onClick={() => setActive(4) || navigate("/inbox")}
      >
        <div className="small_circle ">
          <AiOutlineMessage size={24} color={active === 4 ? "#5e97b7" : ""} />
        </div>

        <span>Inbox</span>
        <div className="rArrow">
          <i className="right_icon"></i>
        </div>
      </div>

      {/* tracking */}
      <div className="mmenu_item hover0" onClick={() => setActive(5)}>
        <div className="small_circle">
          <MdOutlineTrackChanges
            size={24}
            color={active === 5 ? "#5e97b7" : ""}
          />
        </div>

        <span>Tracking</span>
        <div className="rArrow">
          <i className="right_icon"></i>
        </div>
      </div>

      {/* payment */}
      <div
        className={`mmenu_item hover0 ${type === "all" && "active_friends"}`}
        onClick={() => setActive(6)}
      >
        <div className="small_circle">
          <AiOutlineCreditCard
            size={20}
            color={active === 6 ? "#5e97b7" : ""}
          />
        </div>
        <span>Payment methods</span>
        <div className="rArrow">
          <i className="right_icon"></i>
        </div>
      </div>

      {/* address */}
      <div>
        <div className="mmenu_item hover0" onClick={() => setActive(7)}>
          <div className="small_circle">
            <TbAddressBook size={20} color={active === 7 ? "#5e97b7" : ""} />
          </div>
          <span>Address book</span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSidebar;
