import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer/Footer";
import UserOrderDetails from "../../components/orders/UserOrderDetails";

const OrderDetailsPage = () => {
  return (
    <>
      <Header />
      <UserOrderDetails />
      <Footer />
    </>
  );
};

export default OrderDetailsPage;
