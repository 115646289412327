import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const dishReducer = createReducer(initialState, {
  dishCreateRequest: (state) => {
    state.isLoading = true;
  },
  dishCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.dish = action.payload;
    state.success = true;
  },
  dishCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Shop all dishes
  getAllDishesShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllDishesShopSuccess: (state, action) => {
    state.isLoading = false;
    state.dishes = action.payload;
  },
  getAllDishesShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.dishes = false;
  },

  // User all dishes
  getAllDishesUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllDishesUserSuccess: (state, action) => {
    state.isLoading = false;
    state.dishes = action.payload;
  },
  getAllDishesUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.dishes = false;
  },

  // delete product of a shop
  deleteDishRequest: (state) => {
    state.isLoading = true;
  },
  deleteDishSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteDishFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Get all dishes
  getAllDishesRequest: (state) => {
    state.isLoading = true;
  },
  getAllDishesSuccess: (state, action) => {
    state.isLoading = false;
    state.allDishes = action.payload;
  },
  getAllDishesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    // state.products = false;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
