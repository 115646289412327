import { axiosInstance } from "../../functions/axiosInstance";

export const createService =
  (
    name,
    description,
    category,
    tags,
    userId,
    servicerId,
    images,
    specialization,
    experience,
    feePerHour,
    feePerService,
    timings,
    occupants
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "serviceCreateRequest",
      });

      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/service/createService`,
        name,
        description,
        category,
        tags,
        specialization,
        experience,
        feePerHour,
        feePerService,
        userId,
        servicerId,
        images,
        timings,
        occupants
      );
      dispatch({
        type: "serviceCreateSuccess",
        payload: data.service,
      });
    } catch (error) {
      dispatch({
        type: "serviceCreateFail",
        payload: error.response?.data.message,
      });
    }
  };

// get all services from servicer
// export const getAllServicesServicer = (id) => async (dispatch) => {
export const getAllServicerServices = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllServicesServicerRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/service/getServicerServices/${id}`
    );
    dispatch({
      type: "getAllServicesServicerSuccess",
      payload: data.services,
    });
  } catch (error) {
    dispatch({
      type: "getAllServicesServicerFailed",
      payload: error.response?.data.message,
    });
  }
};

// Get all services
export const getAllServices = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllServicesRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/service/getAllServices`
    );
    dispatch({
      type: "getAllServicesSuccess",
      payload: data.services,
    });
  } catch (error) {
    dispatch({
      type: "getAllServicesFailed",
      payload: error.response?.data.message,
    });
  }
};

// get all orders of user
export const getServicesOfService = (serviceId) => async (dispatch) => {
  try {
    dispatch({
      type: "getServicesOfServiceRequest",
    });

    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/service/getServiceById/${serviceId}`
    );

    dispatch({
      type: "getServicesOfServiceSuccess",
      payload: data.service,
    });
  } catch (error) {
    dispatch({
      type: "getServicesOfServiceFailed",
      payload: error.response.data.message,
    });
  }
};

// delete service of a servicer
export const deleteService = (id) => async (dispatch, token) => {
  try {
    dispatch({
      type: "deleteServiceRequest",
    });

    const { data } = await axiosInstance.delete(
      `${process.env.REACT_APP_BACKEND_URL}/service/deleteServicerService/${id}`
    );

    dispatch({
      type: "deleteServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteServiceFailed",
      payload: error.response.data.message,
    });
  }
};
