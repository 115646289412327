import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers, createStore, applyMiddleware } from "redux";

import friendsReducer from "./reducers/friendsReducer";
import { userReducer } from "./reducers/userReducer";
import { themeReducer } from "./reducers/themeReducer";
import { authReducer } from "./reducers/authReducer";
import { sellerReducer } from "./reducers/sellerReducer";
import { productReducer } from "./reducers/productReducer";
import { eventReducer } from "./reducers/eventReducer";
import { cartReducer } from "./reducers/cartReducer";
import { wishlistReducer } from "./reducers/wishlistReducer";
import { userrReducer } from "./reducers/userrReducer";
import { orderReducer } from "./reducers/orderReducer";
import { postReducer } from "./reducers/postReducer";
import { dishReducer } from "./reducers/dishReducer";
import { servicerReducer } from "./reducers/servicerReducer";
import { serviceReducer } from "./reducers/serviceReducer";
import { appointmentReducer } from "./reducers/appointmentReducer";
import { notificationReducer } from "./reducers/notificationsReducer";
import locationReducer from "./reducers/locationReducer";

import { loadersSlice } from "./reducers/loadersSlice";
import { userrSlice } from "./reducers/userrSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  userr: userrSlice,
  darkTheme: themeReducer,
  friends: friendsReducer,
  seller: sellerReducer,
  products: productReducer,
  events: eventReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  order: orderReducer,
  post: postReducer,
  dishes: dishReducer,
  servicer: servicerReducer,
  services: serviceReducer,
  notification: notificationReducer,
  loaders: loadersSlice.reducer,
  location: locationReducer,
});

export default rootReducer;
