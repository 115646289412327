import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="login_footer">
      <div className="login_footer_wrap">
        <Link to="/login">Sign Up</Link>
        <Link to="/login">Sign in</Link>
        <Link to="/products">Products</Link>
        <Link to="/services">Services</Link>
        <Link to="/eateries">Eateries</Link>
        <Link to="/">About</Link>
        <Link to="/">Advertise</Link>
        <Link to="/">Investors</Link>
        <Link to="/">Developers</Link>
        <Link to="/">Careers</Link>
        <Link to="/">Privacy</Link>
        <Link to="/">Cookies</Link>
        <Link to="/about/terms-of-service">Terms & Conditions</Link>
      </div>
      <div className="login_footer_wrap">
        <Link to="/" style={{ fontSize: "12px", marginTop: "10px" }}>
          NXXTSTORE © 2024
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
