import { useState } from "react";
import Header from "../../components/header";
import OrderContent from "../../components/orders/layout/OrderContent";
import OrderSidebar from "../../components/orders/layout/OrderSidebar";

import "./style.css";

export default function OrderPage() {
  const [active, setActive] = useState(1);

  return (
    <>
      <Header />
      <div className="friends">
        <div className="friends_left" style={{ backgroundColor: "#fff" }}>
          <div className="friends_left_header">
            <h3>Info</h3>
          </div>
          <OrderSidebar active={active} setActive={setActive} />
        </div>
        <div className="friends_right" style={{ backgroundColor: "#e9f3f7" }}>
          <OrderContent active={active} />
        </div>
      </div>
    </>
  );
}
