import React from "react";
import DashboardSidebar from "../../components/shop/layout/DashboardSidebar";
import DashboardHero from "../../components/shop/DashboardHero";
import Header from "../../components/header";

const ShopDashboardPage = () => {
  return (
    <>
      <Header />
      <div className="dashboard">
        <DashboardSidebar active={1} />
        <DashboardHero />
      </div>
    </>
  );
};
export default ShopDashboardPage;
