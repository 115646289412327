import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import ReactGA from "react-ga4";

import { ImLocation } from "react-icons/im";
import Navbar from "../categoryMenu/Navbar";
import Spinner from "../shared/spinner/Spinner";
import { heroSlide } from "../../data/heroSlide";
import HeroCarousel from "../carousel/HeroCarousel";

import "./hero.css";

const Hero = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="hero">
            <div className="beta">beta</div>
            <div className="hero_title">
              DO MORE. <span>NXXTSTORE</span>
            </div>

            {/* <div className="hero_sub_title">
              <div className="hero_sub_title_wrap">
                All the
                <Link to="/products">
                  <div className="hero_sub_title_product_image_wrap">
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1691782990/servicer/suuvoin2xfj9jurkh0e3.jpg"
                      alt=""
                      className="hero_sub_title_product_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1703434983/Jellybeans_olp0dz.jpg"
                      alt=""
                      className="hero_sub_title_product_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1702049437/products/cfdjrhwc2tr18yttrbj5.webp"
                      alt=""
                      className="hero_sub_title_product_image"
                    />
                  </div>
                </Link>
                <span className="hst_span_1">products,</span>
                services
                <Link to="/services">
                  <div className="hero_sub_title_service_image_wrap">
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1704502948/Livery_o9c1jk.png"
                      alt=""
                      className="hero_sub_title_service_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1702752806/albertmontes/profile_pictures/rclclrraxglpy3xdqdro.jpg"
                      alt=""
                      className="hero_sub_title_service_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1692556860/testtest/profile_pictures/sh7xfomokyvnlqi2pfzb.jpg"
                      alt=""
                      className="hero_sub_title_service_image"
                    />
                  </div>
                </Link>
                <span className="hst_span_2">services,</span>
                <br />
                eateries
                <span className="hst_span_3">eateries</span>
                <Link to="/eateries">
                  <div className="hero_sub_title_eateries_image_wrap">
                    <div className="hero_sub_title_eateries_image"> </div>
                  </div>
                </Link>
                <span className="hero_sub_title_more_text">
                  , and more... <br />
                  <span className="hero_sub_title_find_text">
                    Find it on Nxxtstore
                  </span>
                </span>
              </div>
              <br />
            </div> */}
            <div className="hero_sub_title">
              <div className="hero_sub_title_wrap">
                All-In-One Marketplace
                <Link to="/products">
                  <div className="hero_sub_title_product_image_wrap">
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1691782990/servicer/suuvoin2xfj9jurkh0e3.jpg"
                      alt=""
                      className="hero_sub_title_product_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1703434983/Jellybeans_olp0dz.jpg"
                      alt=""
                      className="hero_sub_title_product_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1702049437/products/cfdjrhwc2tr18yttrbj5.webp"
                      alt=""
                      className="hero_sub_title_product_image"
                    />
                  </div>
                </Link>
                <span className="hst_span_1">
                  <br />
                  for
                </span>
                {/* services */}
                <Link to="/services">
                  <div className="hero_sub_title_service_image_wrap">
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1704502948/Livery_o9c1jk.png"
                      alt=""
                      className="hero_sub_title_service_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1702752806/albertmontes/profile_pictures/rclclrraxglpy3xdqdro.jpg"
                      alt=""
                      className="hero_sub_title_service_image"
                    />
                    <img
                      src="https://res.cloudinary.com/dcinefb4x/image/upload/v1692556860/testtest/profile_pictures/sh7xfomokyvnlqi2pfzb.jpg"
                      alt="cleaning"
                      className="hero_sub_title_service_image"
                    />
                  </div>
                </Link>
                <br />
                <span className="hst_span_2">Entrepreneurs</span>
                {/* eateries */}
                <span className="hst_span_3">
                  , and <br />
                  Freelancers.
                </span>
                {/* <Link to="/eateries">
                  <div className="hero_sub_title_eateries_image_wrap">
                    <div className="hero_sub_title_eateries_image"> </div>
                  </div>
                </Link> */}
                {/* <span className="hero_sub_title_more_text">
                  , and more... <br />
                  <span className="hero_sub_title_find_text">
                    Find it on Nxxtstore
                  </span>
                </span> */}
              </div>
              {/* <br /> */}
              <p>
                Nxxtstore is a vibrant marketplace designed for freelancers and
                entrepreneurs, offering a secure platform for booking and
                payments, <br />
                to list both physical and digital goods/services.
              </p>
            </div>

            <div className="hero_button_container">
              <Link to="/products">
                <button className="hero_button">Products</button>
              </Link>
              <Link to="/services">
                <button className="hero_button">Services</button>
              </Link>
              <Link to="/eateries">
                <button className="hero_button">Eateries</button>
              </Link>
              <Link to="/rides">
                <button className="hero_button">Rides</button>
              </Link>
            </div>
            {/* <div className="hero_social_wrap">
      <BsFacebook className="hero_social_icon" />
      <AiFillInstagram className="hero_social_icon" />
      <BsTwitter className="hero_social_icon" />
    </div> */}

            <HeroCarousel images={heroSlide} />
            {/* <img
              src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151327/assets/Hero_main_image_amw1v3.png"
              alt="cell phone image"
              className="hero_image"
            /> */}
            <div className="hero_nav_bar_container">
              <Navbar />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Hero;
