import React from "react";
import { useSelector } from "react-redux";

import DashboardSidebar from "../../components/shop/layout/DashboardSidebar";
import DashboardMessages from "../../components/shop/DashboardMessages";
import Header from "../../components/header";

const ShopInboxPage = () => {
  const { seller } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Header />
      <DashboardSidebar active={8} />
      <DashboardMessages seller={seller} />
    </>
  );
};

export default ShopInboxPage;
