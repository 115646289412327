import React from "react";

import Header from "../../components/header";
import ServiceDashboardSidebar from "../../components/service/layout/ServiceDashboardSidebar.js";
import ServiceDashboardHero from "../../components/service/ServiceDashboardHero.js";

const ServiceDashboardPage = () => {
  return (
    <>
      <Header />
      <div className="dashboard">
        <ServiceDashboardSidebar active={1} />
        <ServiceDashboardHero />
      </div>
    </>
  );
};
export default ServiceDashboardPage;
