import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { serviceData } from "../../data/staticData";

import "./categories.css";

const ServiceCategories = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { allServices } = useSelector((state) => state.services);

  useEffect(() => {
    const allServicesData = serviceData ? [...serviceData] : [];
    const sortedData = allServicesData?.filter((a) => a.featured === true);
    const firstSix = sortedData && sortedData.slice(0, 6);
    setData(firstSix);
  }, [serviceData]);

  return (
    <>
      <div className="categories" id="categories">
        <div className="categories_container">
          {data &&
            data.map((i) => {
              const handleSubmit = (i) => {
                navigate(`/services?category=${i.title}`);
                //temp reload
                window.location.reload();
              };
              return (
                <div
                  className="categories_item"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  <div className="categories_image_wrap">
                    <img src={i.image} className="categories_image" alt="" />
                  </div>
                  <span className={`text-[18px] leading-[1.3]`}>{i.title}</span>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ServiceCategories;
