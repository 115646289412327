import Header from "../../components/header";

const TermsOfServicePage = () => {
  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "49px",
          backgroundColor: "#fff",
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            border: "1px solid lightgrey",
            margin: "100px auto",
            borderRadius: "10px",
            padding: "40px",
          }}
        >
          <div>
            <h1 style={{ paddingTop: "20px" }}>Terms of Use (TOU)</h1>
            <span>
              WELCOME TO NXXTSTORE. We (Nxxtstore, Inc.) hope that you find NS,
              TOU useful. By using and interacting with our servers, services,
              websites, mobile app ("App"), or any associated
              postings/products/services/vendors/merchants/content (together,
              "NS"), you agree to these Terms of Service ("TOU") (last updated
              Feburary 1, 2024). By using and accessing NS, you acknowledge and
              agree NS is a private site owned and operated by Nxxtstore, Inc.
              If you are accessing or using NS on behalf of a business, you
              represent and warrant to NS that you have authority to accept the
              TOU on behalf of that business and that that business agrees to
              the TOU. If you do not agree to the TOU, you are not authorized to
              use NS or download the App. We may modify the TOU at any time in
              our sole discretion. You are responsible for periodically checking
              for changes and are bound by them if you continue to use NS. Our
              privacy policy (nxxtstore.com/about/privacy.policy), prohibited
              list (nxxtstore.com/about/prohibited), and all other policies,
              site rules, and agreements referenced below or on NS, are fully
              incorporated into this TOU, and you agree to them as well.
            </span>
          </div>
          <div>
            <h1 style={{ paddingTop: "20px" }}>License</h1>
            <span>
              Agreeing to the TOU and (1) having sufficient adult age and
              capacity to use NS or (2) using NS on behalf of a business,
              entitles you to a limited, revocable, non-exclusive,
              non-assignable license to use NS in compliance with the TOU; any
              unlicensed use is unauthorized. You commit not to display,
              "frame," create derivative works, distribute, license, or sell
              content from NS, excluding postings you create. You grant us a
              perpetual, irrevocable, unlimited, worldwide, fully
              paid/sublicensable license to use, copy, display, distribute, and
              create derivative works from content you post.{" "}
            </span>
          </div>
          <div>
            <h1 style={{ paddingTop: "20px" }}>Usage</h1>
            <span>
              Unless specifically licensed by us in a separate written or
              electronic agreement, you agree not to use or provide software
              (except our App and general-purpose web browsers and email
              clients) or services that interact with NS, e.g., for downloading,
              uploading, creating/accessing/using an account, posting, flagging,
              emailing, searching, or mobile use. Copying/collecting NS content
              via robots, spiders, scripts, scrapers, crawlers, or any automated
              or manual equivalent (e.g., by hand) is prohibited. Misleading,
              unsolicited, and/or unlawful postings/communications/accounts are
              not allowed, as is buying or selling accounts. Posting content
              prohibited by any of NS's policies or rules ("Prohibited Content")
              is not permitted. Abusing NS's flagging or reporting processes is
              also prohibited. You must not collect NS user information or
              interfere with NS. We reserve the right to moderate NS access/use
              at our discretion, e.g., by blocking, filtering, re-categorizing,
              re-ranking, deleting, delaying, holding, omitting, verifying, or
              terminating your access/license/account. You agree (1) not to
              bypass said moderation, (2) that we are not liable for moderating
              or not moderating, and (3) that nothing we say or do waives our
              right to moderate, or not. Unless licensed by us in a separate
              written or electronic agreement, you agree not to (i) rent, lease,
              sell, publish, distribute, license, sublicense, assign, transfer,
              or otherwise make NS or our application programming interface
              ("API") available, (ii) copy, adapt, create derivative works,
              decompile, reverse engineer, translate, localize, port or modify
              the App, the API, any website code, or any software used to
              provide NS, (iii) combine or integrate NS or the API with any
              unauthorized software, technology, services, or materials, (iv)
              circumvent any functionality controlling access to or protecting
              NS or the API, or (v) remove or alter any copyright, trademark, or
              other proprietary rights notices. Using NS or the API in any way
              or for any purpose that infringes, misappropriates, or otherwise
              violates any intellectual property right or other right of any
              person or violates any applicable law is not allowed.{" "}
            </span>
          </div>
          <div>
            <h1 style={{ paddingTop: "20px" }}>Trademarks</h1>
            <span>
              NXXTSTORE, "NS" and the "NS" logo are registered trademarks with
              the U.S. Patent and Trademark Office and multiple trademark
              offices worldwide.{" "}
            </span>
          </div>
          <div>
            <h1 style={{ paddingTop: "20px" }}>Claims & Indemnity</h1>
            <span>
              Any claim, cause of action, demand, or dispute arising from or
              related to NS ("Claims") will be governed by the internal laws of
              California, without regard to conflict of law provisions, except
              to the extent governed by US federal law. Any Claims will be
              exclusively resolved by courts in San Diego, CA (except we may
              seek preliminary or injunctive relief anywhere). You agree to (1)
              submit to the personal jurisdiction of courts in San Diego, CA;
              (2) indemnify and hold NS Entities harmless from any Claims,
              losses, liability, or expenses (including attorneys' fees) that
              arise from a third party and relate to your use of NS; and (3) be
              liable and responsible for any Claims we may have against your
              officers, directors, employees, agents, affiliates, or any other
              party, directly or indirectly, paid, directed or controlled by
              you, or acting for your benefit.{" "}
            </span>
          </div>
          <div>
            <h1 style={{ paddingTop: "20px" }}>Misc.</h1>
            <span>
              Unless entering a separate written or electronic agreement with us
              expressly referencing the TOU, this constitutes the exclusive and
              entire agreement between us and you, superseding all prior and
              contemporaneous understandings, agreements, representations, and
              warranties, both written and oral. Users complying with prior
              written licenses may access NS until authorization is terminated.
              Our actions or silence towards you or anyone else do not waive,
              modify, or limit the TOU or our ability to enforce it. The USE,
              LIQUIDATED DAMAGES, and CLAIMS & INDEMNITY sections persist after
              TOU termination, and you will remain bound by those sections. If
              any TOU term is unenforceable, it shall be limited to the least
              extent possible and supplemented with a valid provision embodying
              the parties' intent. The English version of the TOU controls over
              any translations. If you believe content infringes your IP rights,
              see nxxtstore.com/about/dmca.{" "}
            </span>
          </div>
          <div>
            <h1 style={{ paddingTop: "20px" }}>Disclaimer & Liability</h1>
            <span>
              To the fullest extent permitted by law, Nxxtstore, Inc., and its
              officers, directors, employees, agents, licensors, affiliates, and
              successors in interest ("NS Entities") (1) make no promises,
              warranties, or representations as to NS, including its
              completeness, accuracy, availability, timeliness, propriety,
              security, or reliability; (2) provide NS on an "AS IS" and "AS
              AVAILABLE" basis, and any risk of using NS is assumed by you; (3)
              disclaim all warranties, express or implied, including as to
              accuracy, merchantability, fitness for a particular purpose, and
              non-infringement, and all warranties arising from course of
              dealing, usage, or trade practice; and (4) disclaim any liability
              or responsibility for acts, omissions, or conduct of you or any
              party in connection with NS. NS Entities are NOT liable for any
              direct, indirect, consequential, incidental, special, punitive, or
              other losses, including lost profits, revenues, data, goodwill,
              etc., arising from or related to NS, and in no event shall such
              liability exceed $100 or the amount you paid us in the year
              preceding such loss. Some jurisdictions restrict or alter these
              disclaimers and limits, so some may not apply to you.{" "}
            </span>
          </div>
          {/* <div>
            <h1 style={{ paddingTop: "20px" }}>Liquidated Damages</h1>
            <span>
              If you violate the TOU, or encourage, support, benefit from, or
              induce others to do so, you are jointly and severally liable for
              liquidated damages as follows for: (A) collecting/harvesting NS
              users' information, including personal or identifying information
              - $1 per violation; (B) publishing/misusing personal or
              identifying information of a third party in connection with your
              use of NS without that party's express written consent - $1,000
              per violation; (C) misrepresenting your identity or affiliation to
              anyone in connection with your use of NS - $1,000 per violation;
              (D) posting or attempting to post Prohibited Content - $4 per
              violation; (E) posting or attempting to post Prohibited Content in
              any paid section of NS - the price per post applicable to that
              section of NS; (F) sending an unauthorized/unsolicited email to an
              email address obtained from NS - $25 per violation; (G) using NS
              user information to make/send an unauthorized/unsolicited text
              message, call, or communication to a NS user - $500 per
              text/call/communication; (H) creating a misleading or unlawful NS
              account or buying/selling a NS account - $4 per violation; (I)
              abusing or attempting to abuse NS's flagging or reporting
              processes - $1 per violation; (J) distributing any software to
              facilitate violations of the USE Section - $1,000 per violation;
              (K) aggregating, displaying, framing, copying, duplicating,
              reproducing, making derivative works from, distributing,
              licensing, selling, or exploiting NS content for any purpose
              without our express written consent - $3,000 for each day you
              engage in such violations; (L) requesting, viewing, or accessing
              more than 1,000 pages of NS in any 24-hour period - $0.25 per page
              during the 24-hour period after the first 1,000 pages; (M)
              bypassing or attempting to bypass our moderation efforts - $4 per
              violation. You agree that these amounts are (1) a reasonable
              estimate of our damages (as actual damages are often difficult to
              calculate), (2) not a penalty, and (3) not otherwise limiting on
              our ability to recover under any legal theory or claim, including
              statutory damages and other equitable relief (e.g., for spam, we
              can elect between the above liquidated damages or statutory
              damages under the anti-spam statute). You further agree that
              repeated violations of the USE section will irreparably harm and
              entitle us to injunctive or equitable relief, in addition to
              monetary damages.{" "}
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TermsOfServicePage;
