import { useEffect, useReducer, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

import Login from "./pages/login";
import Profile from "./pages/profile";
import Home from "./pages/home";
import {
  LoggedInRoutes,
  ServicerLoggedInRoutes,
  ShopLoggedInRoutes,
} from "./routes/LoggedInRoutes";
import NotLoggedInRoutes from "./routes/NotLoggedInRoutes";
import Activate from "./pages/home/activate";
import Reset from "./pages/reset";
import CreatePostPopup from "./components/createPostPopup";
import { postsReducer } from "./functions/reducers";
import { productsReducer } from "./functions/reducers";
import Feed from "./pages/feed";
import ActivationPage from "./pages/activation/ActivationPage";
import { getAllProducts } from "./store/actions/productActions";

// Shop
import ShopLoginPage from "./pages/shop/ShopLoginPage";
import OrderPage from "./pages/order/OrderPage";
import ShopDashboardPage from "./pages/shop/ShopDashboardPage";
import ShopHomePage from "./pages/shop/ShopHomePage";
import ShopCreateProduct from "./pages/shop/ShopCreateProduct";
import ShopAllProducts from "./pages/shop/ShopAllProducts";
import ShopInboxPage from "./pages/shop/ShopInboxPage";
import ShopWithdrawalMoneyPage from "./pages/shop/ShopWithdrawalMoneyPage";
import ShopOrderDetails from "./pages/shop/ShopOrderDetails";
import ShopAllOrders from "./pages/shop/ShopAllOrders";
// import ShopPreviewPage from "./pages/shop/ShopPreviewPage";
import ShopProfilePage from "./pages/shopProfile/ShopProfilePage";

//Product
// import Product from "./pages/product/ProductsPage";
import ProductsPage from "./pages/product/ProductsPage";
import ProductDetailsPage from "./pages/product/ProductDetailsPage";

import SellerTypePage from "./pages/sellerType/SellerTypePage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import PaymentPage from "./pages/payment/PaymentPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderDetailsPage from "./pages/order/OrderDetailsPage";
import OrderSuccessPage from "./pages/order/OrderSuccessPage";

//Services
import ServiceLoginPage from "./pages/service/ServiceLoginPage";
import ServiceDashboardPage from "./pages/service/ServiceDashboardPage";
import ServiceCreateService from "./pages/service/ServiceCreateService";
import ServicePage from "./pages/service/ServicePage";
import BookAppointmentPage from "./pages/appointment/BookAppointmentPage";
import ServiceInboxPage from "./pages/service/ServiceInboxPage";
import ServiceAllServices from "./pages/service/ServiceAllServices";
import ServiceAllOrders from "./pages/service/ServiceAllOrders";
import ServiceWithdrawalMoneyPage from "./pages/service/ServiceWithdrawalMoneyPage";
import ServicerPreviewPage from "./pages/service/ServicerPreviewPage";
import ServicerProfilePage from "./pages/servicerProfile/ServicerProfilePage";

import NotificationsPage from "./pages/notifications/NotificationsPage";
import UserInbox from "./pages/inbox/UserInbox";
import AllOrders from "./components/shop/AllOrders";

//Eatery
import EateryDashboardPage from "./pages/eateries/EateryDashboardPage";
import EateriesPage from "./pages/eateries/EateriesPage";
import EateryLoginPage from "./pages/eateries/EateryLoginPage";
import EateryPreviewPage from "./pages/eateries/EateryPreviewPage";

//Ride
import RidePage from "./pages/ride/RidePage";

//Vendor
import TrulyRememberedPage from "./pages/trulyremembered/TrulyRememberedPage";

//Terms
import TermsOfServicePage from "./pages/about/TermsOfServicePage";
import { axiosInstance } from "./functions/axiosInstance";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

// const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY;
const GA_MEASUREMENT_ID = "G-MXP36J5KPE";
ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
  const [stripeApiKey, setStripeApiKey] = useState("");

  async function getStripeApikey() {
    const { data } = await axiosInstance.get(
      // `${process.env.REACT_APP_BACKEND_URL}/payment/stripeapikey`
      `/payment/stripeapikey`
    );
    setStripeApiKey(data.stripeApiKey);
  }

  useEffect(() => {
    // Store.dispatch(loadUser());
    // Store.dispatch(loadSeller());
    // Store.dispatch(getAllProducts());
    // Store.dispatch(getAllEvents());
    getStripeApikey();
  });

  const [visible, setVisible] = useState(false);
  const { user, darkTheme } = useSelector((state) => ({ ...state }));

  const [{ loading, error, posts }, dispatch] = useReducer(postsReducer, {
    loading: false,
    posts: [],
    error: "",
  });

  const getAllPosts = async () => {
    try {
      dispatch({
        type: "POSTS_REQUEST",
      });
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/post/getAllPosts`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch({
        type: "POSTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "POSTS_ERROR",
        payload: error?.response?.data.message,
      });
    }
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <div className={darkTheme ? "dark" : "light"}>
      {visible && (
        <CreatePostPopup
          user={user}
          setVisible={setVisible}
          posts={posts}
          dispatch={dispatch}
        />
      )}
      {stripeApiKey && (
        <Elements stripe={loadStripe(stripeApiKey)}>
          <Routes>
            <Route path="/payment" element={<PaymentPage />} />
          </Routes>
        </Elements>
      )}
      <Routes>
        <Route path="/order/success" element={<OrderSuccessPage />} />
        <Route
          path="/products"
          element={<ProductsPage setVisible={setVisible} />}
        />
        <Route
          path="/product/:id"
          element={<ProductDetailsPage setVisible={setVisible} />}
        />

        <Route
          path="/services"
          element={<ServicePage setVisible={setVisible} />}
        />

        <Route
          path="/eateries"
          element={<EateriesPage setVisible={setVisible} />}
        />

        <Route path="/rides" element={<RidePage setVisible={setVisible} />} />

        {/* User Logged In Routes */}
        <Route element={<LoggedInRoutes />}>
          <Route
            path="/profile"
            element={
              <Profile
                setVisible={setVisible}
                getAllProducts={getAllProducts}
                getAllPosts={getAllPosts}
              />
            }
            exact
          />
          <Route
            path="/profile/:username"
            element={
              <Profile
                setVisible={setVisible}
                getAllProducts={getAllProducts}
                getAllPosts={getAllPosts}
              >
                {/* <Route path="getUserProducts/:id" element={<ShopPreviewPage />} /> */}
                {/* <Route path="shop/preview/:id" element={<ShopPreviewPage />} /> */}
              </Profile>
            }
            exact
          />
          <Route
            path="/feed"
            element={
              <Feed
                setVisible={setVisible}
                posts={posts}
                loading={loading}
                getAllPosts={getAllPosts}
              />
            }
          />

          <Route path="/inbox" element={<UserInbox />} />
          <Route path="/seller-type" element={<SellerTypePage />} />
          <Route
            path="/orders"
            element={<OrderPage setVisible={setVisible} />}
            exact
          />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route
            path="/user/order/:id"
            element={<OrderDetailsPage setVisible={setVisible} />}
          />
        </Route>
        {/* User Logged In Routes */}

        {/* SERVICE ROUTES */}
        <Route path="/servicer/preview/:id" element={<ServicerProfilePage />} />
        <Route path="/service-create" element={<ServiceLoginPage />} />
        <Route
          path="/book-appointment/:serviceId"
          element={<BookAppointmentPage />}
        />

        <Route element={<ServicerLoggedInRoutes />}>
          <Route path="/service-dashboard" element={<ServiceDashboardPage />} />
          <Route path="/service-messages" element={<ServiceInboxPage />} />
          <Route path="/service-orders/:id" element={<ServiceAllOrders />} />
          <Route path="/service-orders" element={<ServiceAllOrders />} />
          <Route path="/service-services" element={<ServiceAllServices />} />
          <Route
            path="/service-withdraw-money"
            element={<ServiceWithdrawalMoneyPage />}
          />
          <Route
            path="/dashboard-create-service"
            element={<ServiceCreateService />}
          />
        </Route>

        {/* LOGGED IN EATERY ROUTES */}
        <Route path="/eatery-create" element={<EateryLoginPage />} />
        <Route path="/eatery/preview/:id" element={<EateryPreviewPage />} />
        <Route path="/eatery-dashboard" element={<EateryDashboardPage />} />
        {/* EATERY ROUTES */}

        {/* SHOP ROUTES */}
        <Route path="/shop/preview/:id" element={<ShopProfilePage />} />
        <Route path="/shop-create" element={<ShopLoginPage />} />

        {/* LOGGED IN SHOP ROUTES */}
        <Route element={<ShopLoggedInRoutes />}>
          <Route path="/shop/:id" element={<ShopHomePage />} />
          <Route path="/shop-dashboard" element={<ShopDashboardPage />} />
          <Route
            path="/dashboard-create-product"
            element={<ShopCreateProduct />}
          />
          <Route path="/dashboard-orders" element={<ShopAllOrders />} />
          <Route path="/order/:id" element={<ShopOrderDetails />} />

          <Route path="/dashboard-products" element={<ShopAllProducts />} />
          <Route path="/dashboard-messages" element={<ShopInboxPage />} />
          <Route
            path="/dashboard-withdraw-money"
            element={<ShopWithdrawalMoneyPage />}
          />
        </Route>
        {/* LOGGED IN SHOP ROUTES */}

        <Route
          path="/"
          element={
            <Home
              setVisible={setVisible}
              posts={posts}
              loading={loading}
              getAllPosts={getAllPosts}
            />
          }
          exact
        />
        <Route path="/trulyremembered" element={<TrulyRememberedPage />} />
        <Route
          path="/trulyremembered-products"
          element={<TrulyRememberedPage />}
        />
        <Route
          path="/about/terms-of-service"
          element={<TermsOfServicePage />}
        />

        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/activate/:token" element={<Activate />} exact />
        {/* </Route> */}
        <Route element={<NotLoggedInRoutes />}>
          <Route path="/login" element={<Login />} exact />
        </Route>
        <Route path="/reset" element={<Reset />} />
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
