import { Link } from "react-router-dom";
import { Dots } from "../../svg";

export default function ProfileMenu() {
  return (
    <div className="profile_menu_wrap">
      <div className="profile_menu">
        <Link to="/" className="hover1">
          Home
        </Link>
        <Link to="/products" className="hover1">
          Products
        </Link>
        <Link to="/services" className="hover1">
          Services
        </Link>
        <Link to="/places" className="hover1">
          Eateries
        </Link>
        {/* <div className="p10_dots">
          <Dots />
        </div> */}
      </div>
    </div>
  );
}
