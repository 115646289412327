import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { categoriesData } from "../../data/categoriesData";
import { createProduct } from "../../store/actions/productActions";
import ProductInput from "../inputs/productInputs/ProductInput";

//testing images
import { uploadImages } from "../../functions/uploadImages";
import dataURItoBlob from "../../helpers/dataURItoBlob";
import ProductImages from "./ProductImages";

//animated components for react-select
const animatedComponents = makeAnimated();

const CreateProduct = () => {
  const { seller } = useSelector((state) => ({ ...state }));
  const { user } = useSelector((state) => ({ ...state }));
  const { success, error } = useSelector((state) => state.products);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [used, setUsed] = useState(false);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [loading, setLoading] = useState(false);

  const [file = null, setFile] = useState(null);

  //Sizes
  const clothingSizes = ["XS", "S", "M", "L", "XL", "XXL"];
  const [sizeOption, setSizeOption] = useState([]);
  const handleSizeChange = (clothingSizes) => {
    setSizeOption(clothingSizes);
  };

  //converted sizes
  const sizeOptionsConverted = clothingSizes?.map((size) => {
    return {
      value: size,
      label: size,
    };
  });

  //delivery options
  const deliveryOptions = [
    "Standard 3-5 Business Days",
    "1-hour Delivery + $9.99",
  ];
  const [deliveryOption, setDeliveryOption] = useState([]);
  const handleDeliveryChange = (deliveryOptions) => {
    setDeliveryOption(deliveryOptions);
  };

  //converted delivery options
  const deliveryOptionsConverted = deliveryOptions?.map((delivery) => {
    return { value: delivery, label: delivery };
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/shop-dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", String(seller.id));
    newForm.append("userId", String(user?.id));
    newForm.append("seller", seller);
    newForm.append("user", user?.id);
    newForm.append("used", used);

    clothingSizes.forEach((size) => {
      newForm.append("clothingSizes", size);
    });
    deliveryOptions.forEach((delivery) => {
      newForm.append("deliveryOptions", delivery);
    });

    // sending to product action/reducer
    // dispatch(createProduct(newForm));
    dispatch(
      createProduct({
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId: seller.id,
        userId: user.id,
        images,
        used,
        clothingSizes: sizeOption?.map((size) => size?.label),
        deliveryOptions: deliveryOption?.map((delivery) => delivery?.label),
      })
    );
  };

  return (
    <div className="create_product">
      <div className="createProduct">
        <div className="createProduct_body">
          <div className="createProduct_header">
            Create a product, {seller?.name}
            <div className="create_splitter"></div>
          </div>

          {/* create product form */}
          <form onSubmit={handleSubmit}>
            <br />
            <div className="create_product_input_wrap">
              <label>
                Name{" "}
                <span style={{ color: "red" }} className="text-red-500">
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                className="create_product_input"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your product name..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Description <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                value={description}
                style={{ height: "100px" }}
                className="create_product_input"
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter your product description..."
              ></textarea>
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Category <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="create_product_input"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Choose a category">Choose a category</option>
                {categoriesData &&
                  categoriesData.map((i) => (
                    <option value={i.title} key={i.title}>
                      {i.title}
                    </option>
                  ))}
              </select>
            </div>
            <br />
            {category === "Men" ||
            category === "Women" ||
            category === "Children" ||
            category === "Pets" ? (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Select Size
                </label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name="sizes"
                  options={sizeOptionsConverted}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable={true}
                  isLoading={false}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={(item) => handleSizeChange(item)}
                />
              </div>
            ) : null}{" "}
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px", paddingTop: "8px" }}>
                Tags
              </label>
              <input
                type="text"
                name="tags"
                value={tags}
                className="create_product_input"
                onChange={(e) => setTags(e.target.value)}
                placeholder="Enter your product tags..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Original Price</label>
              <input
                type="number"
                name="price"
                value={originalPrice}
                className="create_product_input"
                onChange={(e) => setOriginalPrice(e.target.value)}
                placeholder="Enter your product price..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Price (With Discount) <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                name="price"
                value={discountPrice}
                className="create_product_input"
                onChange={(e) => setDiscountPrice(e.target.value)}
                placeholder="Enter your product price with discount..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Product Stock <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                name="price"
                value={stock}
                className="create_product_input"
                onChange={(e) => setStock(e.target.value)}
                placeholder="Enter your product stock..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Delivery Options <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                components={animatedComponents}
                isMulti
                name="deliveryOptions"
                options={deliveryOptionsConverted}
                className="basic-multi-select"
                classNamePrefix="select"
                isClearable={true}
                isLoading={false}
                isSearchable={true}
                closeMenuOnSelect={false}
                onChange={(item) => handleDeliveryChange(item)}
              />
            </div>
            <br />
            {/* images */}
            {/* <div>
              <label style={{ paddingBottom: "8px" }}>
                Upload Images (50kb max) <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name=""
                id="upload"
                className="hidden"
                multiple
                onChange={handleImageChange}
              />
              <div className="create_product_add_image">
                <label htmlFor="upload">
                  <AiOutlinePlusCircle
                    size={30}
                    style={{ marginTop: "10px" }}
                    color="#5e97b7"
                  />
                </label>
                {images &&
                  images.map((i) => (
                    <img
                      src={i}
                      key={i}
                      alt=""
                      className="create_product_image"
                      style={{ width: "120px", height: "120px" }}
                    />
                  ))}
              </div>
              <br />{" "}
            </div> */}
            {/* test */}
            <ProductImages
              seller={seller.id}
              images={images}
              setImages={setImages}
              setError={error}
            />
            <div>
              {/* {loading ? <PulseLoader color="#fff" size={5} /> : "Save"} */}

              <input type="submit" value="Create" className="blue_btn" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
