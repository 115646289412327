import React from "react";
import { useSelector } from "react-redux";

import DashboardSidebar from "../../components/shop/layout/DashboardSidebar";
import CreateProduct from "../../components/shop/CreateProduct";
import Header from "../../components/header";

const ShopCreateProduct = () => {
  const { seller } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Header />
      <DashboardSidebar active={4} />
      <CreateProduct seller={seller} />
    </>
  );
};

export default ShopCreateProduct;
