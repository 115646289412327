import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Header from "../../components/header";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SetLoader } from "../../store/reducers/loadersSlice";
import { toast } from "react-toastify";
import { axiosInstance } from "../../functions/axiosInstance";

import "./notifications.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NotificationsPage() {
  const { user } = useSelector((state) => ({ ...state }));
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { servicer } = useSelector((state) => ({ ...state }));

  const [value, setValue] = useState(0);
  const [userNotifications, setUserNotifications] = useState([]);
  const [unseenUserNotifications, setUnseenUserNotifications] = useState([]);
  const [data, setData] = useState([]);
  const [service, setService] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { serviceId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllUnseen = async () => {
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/getAllUnseenNotifications`,
        // { userId: user.id },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(SetLoader(false));
      if (response.data.success) {
        dispatch(setUnseenUserNotifications(response.data));
        // console.log(response.data);
        toast.success(response.data.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      dispatch(SetLoader(false));
      toast.error("Something may have went wrong");
    }
  };

  const markAllAsSeen = async () => {
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/markAllNotificationsAsSeen`,
        { userId: user.id },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(SetLoader(false));
      if (response.data.success) {
        toast.success(response.data.message);
        window.location.reload();
        dispatch(setUserNotifications(response.data));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      dispatch(SetLoader(false));
      toast.error("Something went wrong");
    }
  };

  const deleteAll = async () => {
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/deleteAllNotifications`,
        { userId: user.id },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(SetLoader(false));
      if (response.data.success) {
        toast.success(response.message);
        dispatch(setUserNotifications(response.data));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      dispatch(SetLoader(false));
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUnseen();
    // getServiceData();
  }, []);

  return (
    <>
      <Header />
      <div className="notifications">
        <Box sx={{ width: "90%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Unread" {...a11yProps(0)} />
              <Tab label="Read" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div style={{ backgroundColor: "#fff" }}>
              {unseenUserNotifications?.unseenNotifications?.map(
                (notification) => (
                  <div
                    key={notification.id}
                    onClick={() => navigate(notification.onClickPath)}
                  >
                    {notification.message}
                  </div>
                )
              )}

              <div
                className="d-flex justify-content-end"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <h1
                  className="anchor blue_btn"
                  onClick={() => {
                    markAllAsSeen();
                    // setUnseenUserNotifications([]);
                  }}
                >
                  Mark all as seen
                </h1>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div style={{ backgroundColor: "#fff" }}>
              {user?.seenNotifications?.map((notification, i) => (
                <div onClick={() => navigate(notification.onClickPath)} key={i}>
                  {notification.message}
                </div>
              ))}

              {/* <h1 className="anchor" onClick={() => deleteAll()}>
                Delete all
              </h1> */}
            </div>
          </CustomTabPanel>
        </Box>
      </div>

      <Footer />
    </>
  );
}
