import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControl, FormControlLabel, FormLabel } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

import {
  getAllProducts,
  getAllProductsShop,
} from "../../store/actions/productActions";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../store/actions/wishlistActions";
import { addToCart } from "../../store/actions/cartActions";
import { shopsDistances, shopsWithin } from "../../functions/shop";

import "./product.css";
import { axiosInstance } from "../../functions/axiosInstance";

const ProductDetails = ({ data }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const { products } = useSelector((state) => state.products);
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);

  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [shops, setShops] = useState([]);
  const [shopData, setShopData] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState(
    "Standard 3-5 Business Days"
  );

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart");
    } else {
      if (data.stock < 1) {
        toast.error(`Sorry currently out of stock`);
      } else {
        const cartData = {
          ...data,
          qty: count,
          clothingSizes: selectedSize,
          deliveryOptions: selectedDelivery,
        };
        dispatch(addToCart(cartData));
        toast.success("Item added to cart successfully");
      }
    }
  };

  const handleMessageSubmit = async () => {
    if (user) {
      const groupTitle = data._id + user.id;
      const userId = user.id;
      const sellerId = data.shop._id;

      await axiosInstance
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/conversation/createNewConversation`,
          {
            groupTitle,
            userId,
            sellerId,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          navigate(`/inbox`);
        })
        .catch((error) => {
          toast.error(error.response.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  useEffect(() => {
    const getDeliveryDate = async () => {
      try {
        const response = await axiosInstance.get("/product/deliveryDate");
        setDeliveryDate(response.data.date);
      } catch (error) {
        console.error("Error fetching shipping date:", error);
      }
    };

    getDeliveryDate();
  }, []);

  useEffect((position) => {
    const shopData = async (position) => {
      const userLocation = Cookies.get("userLocation");

      if (userLocation) {
        const { latitude, longitude } = JSON.parse(userLocation);
        const lat = latitude;
        const lng = longitude;

        const result = await shopsWithin(lat, lng);
        const shopsObject = result.shops;
        setShops(shopsObject);
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.props.setLocation(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    shopData();
  }, []);

  useEffect(() => {
    const allShopsData = shops ? [...shops] : [];
    const sortedData = allShopsData?.filter((a) => a._id === data?.shop._id);
    setShopData(sortedData);
  }, [shops]);

  return (
    <div style={{ background: "#fff" }}>
      {data ? (
        <div className="product_details_container">
          <div className="pd_wrap">
            <div className="pd_wrap_left">
              <img
                src={`${data && data.images[select]?.url}`}
                alt=""
                className="pd_image_select"
              />
              <div className="pd_images_container">
                <div className="pd_images_wrap">
                  {data.images &&
                    data.images.map((i, index) => (
                      <img
                        src={i.url}
                        alt=""
                        className="pd_images"
                        onClick={() => setSelect(index)}
                        key={i._id}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="pd_wrap_right">
              <div className="pd_info">
                <h1 className="pd_name">{data.name}</h1>
                <h5 className="pd_description_short">
                  {" "}
                  {data?.description?.length > 40
                    ? data?.description.slice(0, 40) + "..."
                    : data?.description}
                </h5>

                {/* Size picker */}
                {data.clothingSizes.length > 0 ? (
                  <div className="pd_sizes">
                    <FormControl>
                      <FormLabel>Sizes:</FormLabel>
                      <RadioGroup
                        row
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                      >
                        {data.clothingSizes.map((size) => (
                          <FormControlLabel
                            control={<Radio value={size} />}
                            label={size}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                ) : null}

                {/* Delivery Options */}
                <div style={{ marginTop: "20px" }}>
                  {shopData.length > 0 ? (
                    <div>
                      <FormControl>
                        <FormLabel>Delivery: </FormLabel>
                        <RadioGroup
                          value={selectedDelivery}
                          onChange={(e) => setSelectedDelivery(e.target.value)}
                        >
                          {data.deliveryOptions.map((option) => (
                            <div style={{ fontSize: "10px" }}>
                              <FormControlLabel
                                control={<Radio value={option} />}
                                label={option}
                              />
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  ) : (
                    <div className="pd_shipping_wrap">
                      {deliveryDate ? (
                        <p className="pd_delivery_date">
                          Est. Delivery Date: {deliveryDate}
                        </p>
                      ) : null}
                    </div>
                  )}{" "}
                </div>

                <div className="pd_price_wrap">
                  <h4 className="">
                    $
                    {data.originalPrice === 0
                      ? data.originalPrice
                      : data.discountPrice}
                  </h4>
                  <h5 className="product_price">
                    {data.originalPrice ? " $" + data.originalPrice : null}{" "}
                  </h5>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  {data?.stock > 0 ? (
                    <span style={{ color: "green" }}>In Stock</span>
                  ) : (
                    <span style={{ color: "red" }}>Out of Stock</span>
                  )}
                </div>

                <div className="pd_actions_wrap">
                  <div className="pd_actions">
                    <div>
                      <button
                        className="pd_decremement_button"
                        onClick={decrementCount}
                      >
                        -
                      </button>
                      <span className="pd_count">{count}</span>
                      <button
                        className="pd_increment_button"
                        onClick={incrementCount}
                      >
                        +
                      </button>
                    </div>
                    <div>
                      {click ? (
                        <AiFillHeart
                          size={30}
                          style={{ cursor: "pointer" }}
                          onClick={() => removeFromWishlistHandler(data)}
                          color={click ? "red" : "#333"}
                          title="Remove from wishlist"
                        />
                      ) : (
                        <AiOutlineHeart
                          size={30}
                          style={{ cursor: "pointer" }}
                          onClick={() => addToWishlistHandler(data)}
                          color={click ? "red" : "#333"}
                          title="Add to wishlist"
                        />
                      )}
                    </div>
                  </div>
                  <div className="pd_actions_buttons">
                    {data.stock > 0 ? (
                      <div
                        onClick={() => addToCartHandler(data._id)}
                        className="pd_cart_button"
                      >
                        <span>
                          Add to cart <AiOutlineShoppingCart />
                        </span>
                      </div>
                    ) : (
                      <div className="pd_cart_button">Out of Stock</div>
                    )}{" "}
                    <div
                      className="pd_message black_button"
                      style={{
                        height: "40px",
                        marginTop: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleMessageSubmit}
                    >
                      <span>
                        Send Message{" "}
                        <AiOutlineMessage
                          size={20}
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="pd_seller_info_wrap">
                  <Link to={`/shop/preview/${data.shop._id}`}>
                    <img
                      src={data.shop.picture}
                      alt=""
                      className="shop_avatar"
                    />
                  </Link>
                  <div className="pd_seller_info">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className="pd_seller_name">{data?.shop.name}</h3>
                    </Link>
                    <h5>({averageRating}/5) Ratings</h5>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsInfo data={data} products={products} />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({ data, products }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="pdi">
      <div className="pdi_container">
        <div className="pdi_wrap">
          <h5 className="pdi_title" onClick={() => setActive(1)}>
            Product Details
          </h5>
          {active === 1 ? <div style={{ color: "red" }} /> : null}
        </div>
        <div className="pdi_title">
          <h5 className={"pdi_title"} onClick={() => setActive(2)}>
            Product Reviews
          </h5>
          {active === 2 ? <div /> : null}
        </div>
        <div className="pdi_title">
          <h5 className={"pdi_title"} onClick={() => setActive(3)}>
            Seller Information
          </h5>
          {active === 3 ? <div /> : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <p className="pdi_info">{data.description}</p>
        </>
      ) : null}

      {active === 2 ? (
        <div className="pdi_review">
          {data &&
            data.reviews?.map((item, index) => (
              <div style={{ width: "100%" }}>{item.comment}</div>
            ))}
          {data && data.reviews?.length === 0 && (
            <h5>This products has no reviews yet.</h5>
          )}
        </div>
      ) : null}

      {active === 3 && (
        <div className="pdi_seller_info">
          <div>
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className="flex items-center">
                <img src={data.shop.picture} className="shop_avatar" alt="" />
                <div style={{ paddingLeft: "10px" }}>
                  <h3 className="pdi_title">{data.shop.name}</h3>
                  <h5 className="pdi_rating">({data.shop.ratings}) Ratings</h5>
                </div>
              </div>
            </Link>
            <p style={{ paddingTop: "10px" }}>{data.shop.description}</p>
          </div>
          <div>
            <div>
              <h5 style={{ fontWeight: "600", paddingTop: "5px" }}>
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop.createdAt.slice(0, 10)}
                </span>
              </h5>
              <h5 style={{ fontWeight: "600", paddingTop: "5px" }}>
                Total Products:{" "}
                <span style={{ fontWeight: "500" }}>
                  {products && products?.length}
                </span>
              </h5>
              <h5 style={{ fontWeight: "600", paddingTop: "5px" }}>
                Total Reviews: <span style={{ fontWeight: "500" }}>324</span>
              </h5>
              <Link to="/">
                <div>
                  <Link to={`/shop/preview/${data.shop._id}`}>
                    <h4 style={{ color: "blue" }}>Visit Shop</h4>
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
