import React, { useState } from "react";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import "./checkout.css";

const Checkout = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { cart } = useSelector((state) => state.cart);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentSubmit = () => {
    if (address1 === "" || zipCode === null || country === "" || city === "") {
      toast.error("Please choose your delivery address!");
    } else {
      const shippingAddress = {
        address1,
        address2,
        zipCode,
        country,
        city,
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        subTotalPriceWithFee,
        shipping,
        discountPrice,
        shippingAddress,
        user,
      };

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/payment");
    }
  };

  const productItems = cart
    .map((item) => item.discountPrice * item.qty)
    .filter(Number);

  const serviceItems = cart
    .map((item) => item.service?.feePerHour)
    .filter(Number);

  const addFeeIfWordExists = (arr, word, combinedFee) => {
    if (arr.includes(word)) {
      return true + combinedFee;
    } else {
      return false;
    }
  };

  const arr = cart.map((item) => item.deliveryOptions);
  const searchWord = "1-hour Delivery + $9.99";
  const fee = 9.99;

  const fulfillmentFee = 4.99;

  const combinedFee = fee + fulfillmentFee;

  const wordExistsWithFee = addFeeIfWordExists(arr, searchWord, combinedFee);

  const totalItems = [...productItems, ...serviceItems]; //wordExistsWithFee
  const totalItemsWithFee = [
    ...productItems,
    ...serviceItems,
    wordExistsWithFee,
  ]; //combinedFee

  const subTotalPrice = totalItems.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const subTotalPriceWithFee = totalItemsWithFee.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const shipping = subTotalPrice * 0.0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode;

    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/coupon/get-coupon-value/${name}`
      )
      .then((res) => {
        const shopId = res.data.couponCode?.shopId;
        const couponCodeValue = res.data.couponCode?.value;
        if (res.data.couponCode !== null) {
          const isCouponValid =
            cart && cart.filter((item) => item.shopId === shopId);

          if (isCouponValid.length === 0) {
            toast.error("Coupon code is not valid for this shop");
            setCouponCode("");
          } else {
            const eligiblePrice = isCouponValid.reduce(
              (acc, item) => acc + item.qty * item.discountPrice,
              0
            );
            const discountPrice = (eligiblePrice * couponCodeValue) / 100;
            setDiscountPrice(discountPrice);
            setCouponCodeData(res.data.couponCode);
            setCouponCode("");
          }
        }
        if (res.data.couponCode === null) {
          toast.error("Coupon code doesn't exists!");
          setCouponCode("");
        }
      });
  };

  const discountPercentange = couponCodeData ? discountPrice : "";

  const totalPrice = couponCodeData
    ? (subTotalPrice + shipping - discountPercentange).toFixed(2)
    : (subTotalPrice + shipping).toFixed(2);

  return (
    <div className="checkout">
      <div className="checkout_container">
        <div className="checkout_shipping_info_wrap">
          <ShippingInfo
            user={user}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        </div>
        <div className="checkout_cart_data_wrap">
          <CartData
            cart={cart}
            handleSubmit={handleSubmit}
            totalPrice={totalPrice}
            shipping={shipping}
            subTotalPrice={subTotalPrice}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            discountPercentange={discountPercentange}
            fee={fee}
            combinedFee={combinedFee}
            fulfillmentFee={fulfillmentFee}
            wordExistsWithFee={wordExistsWithFee}
            subTotalPriceWithFee={subTotalPriceWithFee}
          />
        </div>
      </div>
      <div className="checkout_payment_submit blue_btn" onClick={paymentSubmit}>
        <h5>Go to Payment</h5>
      </div>
    </div>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
}) => {
  return (
    <div className="checkout_shipping_info">
      <h5 className="checkout_shipping_address">Shipping Address</h5>
      <br />
      <form>
        <div className="checkout_form_field">
          <div className="checkout_full_name">
            <label style={{ display: "block", paddingBottom: "10px" }}>
              Full Name
            </label>
            <input type="text" value={user && user.name} required />
          </div>
          <div>
            <label style={{ display: "block", paddingBottom: "10px" }}>
              Email Address
            </label>
            <input type="email" value={user && user.email} required />
          </div>
        </div>

        <div className="checkout_form_field_2">
          <div>
            <label style={{ display: "block", paddingBottom: "10px" }}>
              Phone Number
            </label>
            <input type="number" required value={user && user.phoneNumber} />
          </div>
          <div>
            <label style={{ display: "block", paddingBottom: "10px" }}>
              Zip Code
            </label>
            <input
              type="number"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="checkout_form_field_2">
          <div>
            <label style={{ display: "block", paddingBottom: "10px" }}>
              Country
            </label>
            <select
              className="checkout_select"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="checkout_option" value="">
                Choose your country
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label style={{ display: "block", paddingBottom: "10px" }}>
              State
            </label>
            <select
              className="checkout_select"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option className="checkout_option" value="">
                Choose your state
              </option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="checkout_form_field">
          <label style={{ display: "block", paddingBottom: "10px" }}>
            Address1
          </label>
          <input
            type="address"
            required
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
          />
        </div>
        <div className="checkout_form_field">
          <label style={{ display: "block", paddingBottom: "10px" }}>
            Address2
          </label>
          <input
            type="address"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            required
          />
        </div>
      </form>
      <h5
        className="checkout_saved_address"
        onClick={() => setUserInfo(!userInfo)}
      >
        Choose From saved address
      </h5>
      {userInfo && (
        <div>
          {user &&
            user.addresses.map((item, index) => (
              <div className="checkout_addresses">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  value={item.addressType}
                  onClick={() =>
                    setAddress1(item.address1) ||
                    setAddress2(item.address2) ||
                    setZipCode(item.zipCode) ||
                    setCountry(item.country) ||
                    setCity(item.city)
                  }
                />
                <h2>{item.addressType}</h2>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const CartData = ({
  cart,
  handleSubmit,
  totalPrice,
  shipping,
  subTotalPrice,
  subTotalPriceWithFee,
  couponCode,
  setCouponCode,
  discountPercentange,
  fee,
  combinedFee,
  fulfillmentFee,
  wordExistsWithFee,
}) => {
  return (
    <div className="checkout_cart_data">
      {cart ? (
        <>
          {cart &&
            cart.map((cartItem) => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                    marginBottom: "20px",
                  }}
                >
                  {cartItem.images ? (
                    <img
                      src={cartItem?.images[0]?.url}
                      alt=""
                      className="cart_image"
                      style={{ width: "20%" }}
                    />
                  ) : null}
                  <h3 className="cart_name">
                    {" "}
                    {cartItem?.name?.length > 40
                      ? cartItem?.name.slice(0, 40) + "..."
                      : cartItem?.name}
                  </h3>
                </div>

                <div>{cartItem.discountPrice}</div>
              </div>
            ))}
        </>
      ) : (
        <>
          {cart.service &&
            cart?.service?.map((cartItem) => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                    marginBottom: "20px",
                  }}
                >
                  <p>{cartItem.description}</p>
                  {/* <img
                  src={cartItem?.images[0]?.url}
                  alt=""
                  className="cart_image"
                  style={{ width: "20%" }}
                /> */}
                  {/* <h3 className="cart_name">
                  {" "}
                  {cartItem?.service.name?.length > 40
                    ? cartItem?.service.name.slice(0, 40) + "..."
                    : cartItem?.service.name}
                </h3> */}
                </div>

                <div>{cartItem.discountPrice}</div>
              </div>
            ))}
        </>
      )}{" "}
      {wordExistsWithFee ? (
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: "14px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Delivery Fee</span>
            <span>${fee}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Fulfillment Fee</span>
            <span>${fulfillmentFee}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>subtotal:</h3>
            <h4>${subTotalPriceWithFee.toFixed(2)}</h4>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>subtotal:</h3>
          <h4>${subTotalPrice.toFixed(2)}</h4>
        </div>
      )}
    </div>
  );
};

export default Checkout;
