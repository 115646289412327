import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { axiosInstance } from "../../functions/axiosInstance";

import "./payment.css";

const Payment = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);

  // Paypal
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "USD",
              value: orderData?.totalPrice,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
    subTotalPriceWithFee: orderData?.subTotalPriceWithFee,
  };

  // Paypal
  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler(paymentInfo);
      }
    });
  };

  // Paypal
  const paypalPaymentHandler = async (paymentInfo) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    order.paymentInfo = {
      id: paymentInfo.payer_id,
      status: "succeeded",
      type: "Paypal",
    };

    await axiosInstance
      .post(`/order/createOrder`, order, config)
      .then((res) => {
        setOpen(false);
        navigate("/order/success");
        toast.success("Order successful!");
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        window.location.reload();
      });
  };

  const paymentData = {
    amount: Math.round(orderData?.subTotalPriceWithFee * 100),
  };

  // stripe
  const paymentHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axiosInstance.post(
        `/payment/process`,
        paymentData,
        config
      );

      const client_secret = data.client_secret;

      if (!stripe || !elements) return;
      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });

      if (result.error) {
        toast.error(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          order.paymentInfo = {
            id: result.paymentIntent.id,
            status: result.paymentIntent.status,
            type: "Credit Card",
          };

          await axiosInstance
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/order/createOrder`,
              order,
              config
            )
            .then((res) => {
              setOpen(false);
              navigate("/order/success");
              toast.success("Order successful!");
              localStorage.setItem("cartItems", JSON.stringify([]));
              localStorage.setItem("latestOrder", JSON.stringify([]));
              window.location.reload();
            });
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // const cashOnDeliveryHandler = async (e) => {
  //   e.preventDefault();

  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   order.paymentInfo = {
  //     type: "Cash On Delivery",
  //   };

  //   await axios
  //     .post(`${process.env.REACT_APP_BACKEND_API}/order/create-order`, order, config)
  //     .then((res) => {
  //       setOpen(false);
  //       navigate("/order/success");
  //       toast.success("Order successful!");
  //       localStorage.setItem("cartItems", JSON.stringify([]));
  //       localStorage.setItem("latestOrder", JSON.stringify([]));
  //       window.location.reload();
  //     });
  // };

  const addFeeIfWordExists = (arr, word, feeAmount, fulfillmentFee) => {
    if (arr?.includes(word)) {
      return true + feeAmount + fulfillmentFee;
    } else {
      return false;
    }
  };

  const arr = orderData?.cart?.map((item) => item.deliveryOptions);
  const searchWord = "1-hour Delivery + $9.99";
  const fee = 8.99;

  const fulfillmentFee = 4.99;

  const wordExistsWithFee = addFeeIfWordExists(
    arr,
    searchWord,
    fee,
    fulfillmentFee
  );

  return (
    <div className="payment">
      <div className="payment_container">
        <div className="payment_info_wrap">
          <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
            onApprove={onApprove}
            createOrder={createOrder}
            paymentHandler={paymentHandler}
            // cashOnDeliveryHandler={cashOnDeliveryHandler}
          />
        </div>
        <div className="card_data_wrap">
          <CartData
            orderData={orderData}
            wordExistsWithFee={wordExistsWithFee}
          />{" "}
        </div>
      </div>
    </div>
  );
};

const PaymentInfo = ({
  user,
  open,
  setOpen,
  onApprove,
  createOrder,
  paymentHandler,
  cashOnDeliveryHandler,
}) => {
  const [select, setSelect] = useState(1);

  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
      {/* select buttons */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div className="payment_select" onClick={() => setSelect(1)}>
            {select === 1 ? <div className="payment_selected" /> : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with Debit/credit card
          </h4>
        </div>

        {/* pay with card */}
        {select === 1 ? (
          <div className="w-full flex border-b">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="w-full flex pb-3">
                <div className="w-[50%]">
                  <label className="block pb-2">Name On Card</label>
                  <input
                    required
                    placeholder={user && user.name}
                    value={user && user.name}
                  />
                </div>
              </div>

              <div className="card_wrap">
                <label className="block pb-2">Card Number</label>
                <div className="card_input">
                  <CardNumberElement
                    options={{
                      style: {
                        base: {
                          fontSize: "14px",
                          lineHeight: 1.5,
                          color: "#444",
                        },
                        empty: {
                          color: "#3a120a",
                          backgroundColor: "transparent",
                          "::placeholder": {
                            color: "#444",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="card_date_cvv_wrap">
                <div style={{ width: "50%" }}>
                  <label className="block pb-2">Exp Date</label>
                  <CardExpiryElement
                    className="card_input"
                    options={{
                      style: {
                        base: {
                          fontSize: "12px",
                          lineHeight: 1.5,
                          color: "#444",
                        },
                        empty: {
                          color: "#3a120a",
                          backgroundColor: "transparent",
                          "::placeholder": {
                            color: "#444",
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <label className="block pb-2">CVV</label>
                  <CardCvcElement
                    className="card_input"
                    options={{
                      style: {
                        base: {
                          fontSize: "12px",
                          lineHeight: 1.5,
                          color: "#444",
                        },
                        empty: {
                          color: "#3a120a",
                          backgroundColor: "transparent",
                          "::placeholder": {
                            color: "#444",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <input type="submit" value="Submit" className="blue_btn" />
            </form>
          </div>
        ) : null}
      </div>

      <br />
      {/* paypal payment */}
      <div>
        <span className="paypal_pay_with">
          ------------- Pay with paypal -------------
        </span>
        <div className="paypal">
          <div className="payment_select" onClick={() => setSelect(2)}>
            {select === 2 ? <div className="payment_selected" /> : null}
          </div>
          <h4 style={{ marginLeft: "10px" }}>Pay with Paypal</h4>
        </div>
        {select === 2 ? (
          <div
            className="w-full flex border-b"
            style={{ width: "100%", display: "flex" }}
          >
            <div className="blue_btn" onClick={() => setOpen(true)}>
              Pay Now
            </div>
            {open && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                  <div className="w-full flex justify-end p-3">
                    <RxCross1
                      size={30}
                      className="cursor-pointer absolute top-3 right-3"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "Aczac4Ry9_QA1t4c7TKH9UusH3RTe6onyICPoCToHG10kjlNdI-qwobbW9JAHzaRQwFMn2-k660853jn",
                    }}
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      onApprove={onApprove}
                      createOrder={createOrder}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>

      <br />
      {/* cash on delivery */}
      {/* <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(3)}
          >
            {select === 3 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Cash on Delivery
          </h4>
        </div>

        --------------cash on delivery------------
        {select === 3 ? (
          <div className="w-full flex">
            <form className="w-full" onSubmit={cashOnDeliveryHandler}>
              <input
                type="submit"
                value="Confirm"
                className="{`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}"
              />
            </form>
          </div>
        ) : null}
      </div> */}
    </div>
  );
};

const CartData = ({ orderData, wordExistsWithFee }) => {
  const shipping = orderData?.shipping?.toFixed(2);
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
        {wordExistsWithFee ? (
          <h5 className="text-[18px] font-[600]">
            ${orderData?.subTotalPriceWithFee}
          </h5>
        ) : (
          <h5 className="text-[18px] font-[600]">
            ${orderData?.subTotalPrice}
          </h5>
        )}
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">shipping:</h3>
        <h5 className="text-[18px] font-[600]">${shipping}</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Total:</h3>
        <h5 className="text-[18px] font-[600]">
          {orderData?.discountPrice ? "$" + orderData.discountPrice : "-"}
        </h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">
        {wordExistsWithFee ? (
          <h5 className="text-[18px] font-[600]">
            ${orderData?.subTotalPriceWithFee}
          </h5>
        ) : (
          <h5 className="text-[18px] font-[600]">
            ${orderData?.subTotalPrice}
          </h5>
        )}{" "}
      </h5>
      <br />
    </div>
  );
};

export default Payment;
