import { axiosInstance } from "./axiosInstance";

export const createProduct = async (images, seller, token) => {
  try {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/product/createProduct`,
      {
        images,
        seller,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllUsersProducts = async (
  type,
  background,
  text,
  images,
  user,
  token
) => {
  try {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/product/getAllUsersProducts`,
      {
        type,
        background,
        text,
        images,
        user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const reactProduct = async (postId, react, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/product/reactProduct`,
      {
        postId,
        react,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const getReacts = async (postId, token) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/product/getReacts/${postId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const comment = async (postId, comment, image, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/product/comment`,
      {
        postId,
        comment,
        image,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const saveProduct = async (productId, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/product/saveProduct/${productId}`,
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deletePost = async (postId, token) => {
  try {
    const { data } = await axiosInstance.delete(
      `${process.env.REACT_APP_BACKEND_URL}/product/deletePost/${postId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchProduct = async (searchTerm, token) => {
  try {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/product/searchProduct/${searchTerm}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
