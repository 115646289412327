import React from "react";
import DashboardSidebar from "../../components/shop/layout/DashboardSidebar";
// import DashboardHero from "../../components/shop/DashboardHero";
import Header from "../../components/header";

const ShopDashboardPage = () => {
  return (
    <>
      <Header />
      <div className="">
        {/* <div>
          <DashboardSidebar active={1} />
        </div> */}
        <div style={{ margin: "100px auto" }}>
          Your Eatery has been created. We are currently populating our eateries
          list. We have your email on file and we send you an email notification
          once we are functional. Thank you for choosing our services and feel
          free to start promoting, we are moving fast.
        </div>
        {/* <DashboardHero /> */}
      </div>
    </>
  );
};
export default ShopDashboardPage;
