import { React, useState } from "react";

import ServiceLoginForm from "../../components/service-create/ServiceLoginForm";
import Footer from "../../components/footer/Footer";
import ServiceRegisterForm from "../../components/service-create/ServiceRegisterForm";

import "./style.css";

export default function ServiceLoginPage() {
  const [visible, setVisible] = useState(false);

  return (
    <div className="service_login">
      <div className="service_login_wrapper">
        <ServiceLoginForm setVisible={setVisible} />
        {visible && <ServiceRegisterForm setVisible={setVisible} />}
        <Footer />
      </div>
    </div>
  );
}
