import ProfileMenu from "../../pages/profile/ProfileMenu";
import { SlPicture } from "react-icons/sl";

import "./style.css";

export default function CreatePost({ user, setVisible, setShowPreview }) {
  return (
    <div className="createPost">
      <div className="createPost_header">
        <img src={user?.picture} alt="" />
        <div
          className="open_post hover2"
          onClick={() => {
            setVisible(true);
          }}
        >
          Say it, {user?.first_name}
        </div>
      </div>
      <div className="create_splitter"></div>
      <div className="createPost_body">
        {/* <ProfileMenu /> */}
        {/* setShowPreview */}
        <div
          className="createPost_icon hover1"
          onClick={() => {
            // setShowPreview();
            setVisible(true);
          }}
        >
          <SlPicture color="#5e97b7" size={22} />
          Photo
        </div>
      </div>
    </div>
  );
}
