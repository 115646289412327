import React from "react";
import { useSelector } from "react-redux";

import ServiceDashboardSidebar from "../../components/service/layout/ServiceDashboardSidebar";
import ServiceWithdrawalMoney from "../../components/service/ServiceWithdrawalMoney";
import Header from "../../components/header";

const ServiceWithdrawalMoneyPage = () => {
  const { servicer } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Header />
      <ServiceDashboardSidebar active={7} />
      <ServiceWithdrawalMoney servicer={servicer} />
    </>
  );
};

export default ServiceWithdrawalMoneyPage;
