import { configureStore } from "@reduxjs/toolkit";
import mapReducer from "./reducers/mapSlice";

const reduxStore = configureStore({
  reducer: {
    map: mapReducer,
  },
});

export default reduxStore;
