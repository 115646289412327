import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import Header from "../../components/header";
import EateryHero from "../../components/eatery/EateryHero";

import "./style.css";

const EateriesPage = () => {
  return (
    <>
      <Header />
      <div className="eatery_page">
        {" "}
        <EateryHero />
      </div>
    </>
  );
};

export default EateriesPage;
