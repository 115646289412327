import React from "react";
import { Link } from "react-router-dom";

import "./banner.css";

const ServicePageBanner = ({ page }) => {
  return (
    <div
      className={
        page === "home" ? "home_page_service_banner" : "service_banner"
      }
      page="services"
    >
      <div className="hpsb_info">
        <span className="hpsb_title">CanvAwww</span>
        <span className="hpsb_text">
          Host paint and sip parties in the comfort of your home.
        </span>
      </div>

      <div className="hpsb_button_wrap">
        <Link to={`/shop/preview/65c7adf5ceafddb818291935`}>
          {/* <Link to={`/service/preview/65b3da4356c244bd6277c1f7`}> */}
          <button className="black_button">Book now</button>
        </Link>
      </div>
      <div className="spb_main_image_wrap">
        <img
          src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151325/assets/canvawww_service_page_banner_jlkbzk.png"
          alt=""
          className="spb_main_image"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default ServicePageBanner;
