import { useState, useEffect } from "react";
import "./hero-carousel.css";

const HeroCarousel = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 6000);
  });

  const slideRight = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };

  return (
    <div
      className="hero_carousel"
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <div className="hero_image">
        {images.map((image, index) => {
          return (
            <div
              className={
                index === current
                  ? "hero_carousel_card hero_carousel_card-active"
                  : "hero_carousel_card"
              }
              key={index}
            >
              <img
                className="hero_carousel_card_image"
                // className="hero_image"
                src={image.url}
                alt=""
              />
              {/* <div className="hero_carousel_card_overlay">
                <h2 className="hero_carousel_card_name">{image.name}</h2>
              </div> */}
            </div>
          );
        })}
        {/* <div className="hero_carousel_arrow_left" onClick={slideLeft}>
          &lsaquo;
        </div>
        <div className="hero_carousel_arrow_right" onClick={slideRight}>
          &rsaquo;
        </div> */}
        <div className="hero_carousel_pagination">
          {images.map((_, index) => {
            return (
              <div
                key={index}
                className={
                  index === current
                    ? "pagination_dot pagination_dot-active"
                    : "pagination_dot"
                }
                onClick={() => setCurrent(index)}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HeroCarousel;
