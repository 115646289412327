import { React, useState } from "react";

import EateryLoginForm from "../../components/eatery-create/EateryLoginForm";
import Footer from "../../components/footer/Footer";
import EateryRegisterForm from "../../components/eatery-create/EateryRegisterForm";

export default function EateryLoginPage() {
  const [visible, setVisible] = useState(false);

  return (
    <div className="shop_login">
      <div className="shop_login_wrapper">
        <EateryLoginForm setVisible={setVisible} />
        {visible && <EateryRegisterForm setVisible={setVisible} />}
        <Footer />
      </div>
    </div>
  );
}
