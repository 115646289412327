import React from "react";
import OrderDetails from "../../components/shop/OrderDetails";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header";

const ShopOrderDetails = () => {
  return (
    <>
      <Header />
      <OrderDetails />
      <Footer />
    </>
  );
};

export default ShopOrderDetails;
