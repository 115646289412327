import React, { useState } from "react";
import ShopInfo from "../../components/shop/ShopInfo";
import ShopProfileData from "../../components/shop/ShopProfileData";
import Header from "../../components/header";

const ShopHomePage = () => {
  return (
    <>
      <Header />
      <div className="friends_left" style={{ backgroundColor: "#fff" }}>
        <ShopInfo isOwner={true} />
      </div>
      <div className="">
        <div className="friends_right" style={{ background: "transparent" }}>
          <ShopProfileData isOwner={true} />
        </div>
      </div>
    </>
  );
};

export default ShopHomePage;
