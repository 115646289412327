import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  getAllProductsShop,
  deleteProduct,
} from "../../store/actions/productActions";
import Spinner from "../shared/spinner/Spinner";

const AllServices = () => {
  let { services, isLoading } = useSelector((state) => state.services);
  const { servicer } = useSelector((state) => ({ ...state }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(servicer?.id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
    window.location.reload();
  };

  const columns = [
    { field: "id", headerName: "Service Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "feePerHour",
      headerName: "Hourly Fee",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/service/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  // Object.keys(products).map((item) => {
  //   row.push({
  //     id: item?.id,
  //     name: item?.name,
  //     price: "US$ " + item?.discountPrice,
  //     Stock: item?.stock,
  //     sold: 10,
  //   });
  // });

  services &&
    services?.map((item) => {
      row.push({
        id: item?._id,
        name: item?.name,
        feePerHour: "US$ " + item?.feePerHour,
        sold: 1,
      });
    });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        // <Loader />
        <div className="all_products_container">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllServices;
