import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { IoIosArrowForward, IoIosNotifications } from "react-icons/io";
import { MdRssFeed } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

import { ArrowDown, Notification, Search } from "../../svg";
import SearchMenu from "./SearchMenu";
import useClickOutside from "../../helpers/clickOutside";
import UserMenu from "./userMenu";
import Cart from "../cart/Cart";
import Wishlist from "../wishlist/Wishlist";
import { SetLoader } from "../../store/reducers/loadersSlice";
import { axiosInstance } from "../../functions/axiosInstance";

import "./style.css";

export default function Header({ page, getAllPosts }) {
  const { user } = useSelector((state) => ({ ...state }));
  const { seller } = useSelector((state) => ({ ...state }));
  const { servicer } = useSelector((state) => ({ ...state }));
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { allProducts } = useSelector((state) => state.products);

  const color = "#65676b";
  const [showSearchMenu, setShowSearchMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [userLocation, setUserLocation] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const dispatch = useDispatch();
  const usermenu = useRef(null);

  const { id } = useParams();

  const check = useMediaQuery({
    query: "(max-width:418px)",
  });

  const getAllUnseen = async () => {
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/getAllUnseenNotifications`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(SetLoader(false));
      if (response.data.success) {
        dispatch(setUserNotifications(response.data));
        toast.success(response.data.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      dispatch(SetLoader(false));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/shop/getShopInfo/${id}`)
      .then((res) => {
        setData(res.data?.shop);
        isLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  useClickOutside(usermenu, () => {
    setShowUserMenu(false);
  });

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setSearchData(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  useEffect(() => {
    getAllUnseen();
  }, []);

  const amlabs = data?._id === "65c7fe69a52186793458c9cd";
  const canvawww = data?._id === "65c7adf5ceafddb818291935";

  return (
    <>
      {amlabs ? (
        <header className="am_header">
          <div className="nav">
            <div className="header">
              {/* header left */}
              <div className="header_left">
                <Link to="/">
                  <img
                    src="https://res.cloudinary.com/dcinefb4x/image/upload/v1704303912/Nxxtstore_logo_rev1_cbam9h.png"
                    alt="nxxtstore logo"
                    className="header_logo"
                  />
                </Link>
                {user && (
                  <div
                    className="search search1"
                    onClick={() => {
                      setShowSearchMenu(true);
                    }}
                  >
                    <Search color={color} />
                    <input
                      type="text"
                      placeholder="Search Products, Services..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="hide_input"
                      style={{ height: "16px" }}
                    />
                    {showSearchMenu && (
                      <SearchMenu
                        color={color}
                        setShowSearchMenu={setShowSearchMenu}
                        searchData={searchData}
                        setSearchData={setSearchData}
                        token={user.token}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* header right */}
              {user ? (
                <div className="header_right">
                  <Link
                    to="/profile"
                    className="profile_link hover1"
                    style={{ color: "white" }}
                  >
                    <img src={user?.picture} alt="" />
                    <span>{user?.first_name}</span>
                  </Link>
                  <div className=" circle_icon hover1">
                    <Link to="/feed">
                      <MdRssFeed size={22} color="white" />
                    </Link>
                  </div>

                  <div className=" circle_icon hover1">
                    <Link to="/notifications">
                      <IoIosNotifications size={22} color="white" />
                      {/* <Notification /> */}
                      {userNotifications &&
                      userNotifications.unseenNotifications?.length ===
                        0 ? null : (
                        <span className="right_notification">
                          {userNotifications.unseenNotifications?.length}
                        </span>
                      )}
                    </Link>
                  </div>

                  <div className="">
                    <div
                      className="wishlist shopping_cart circle_icon hover1"
                      onClick={() => setOpenWishlist(true)}
                    >
                      <AiOutlineHeart size={22} color="white" />
                      {wishlist && wishlist.length === 0 ? null : (
                        <span className="right_notification">
                          {wishlist && wishlist.length}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <div
                      className="shopping_cart circle_icon hover1"
                      onClick={() => {
                        setOpenCart(true);
                      }}
                    >
                      <AiOutlineShoppingCart size={22} color="white" />
                      {cart && cart.length === 0 ? null : (
                        <span className="right_notification">
                          {cart && cart.length}
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className={`circle_icon hover1 ${
                      showUserMenu && "active_header"
                    }`}
                    ref={usermenu}
                  >
                    <div
                      onClick={() => {
                        setShowUserMenu((prev) => !prev);
                      }}
                    >
                      <div style={{ transform: "translateY(2px)" }}>
                        <ArrowDown style={{ color: "white" }} color="white" />
                      </div>
                    </div>

                    {showUserMenu && (
                      <UserMenu
                        user={user}
                        seller={seller}
                        servicer={servicer}
                      />
                    )}
                  </div>
                  <div className="header_seller_button">
                    {seller ? (
                      <Link to="/shop-dashboard">
                        <span>
                          Shop Dash{" "}
                          <IoIosArrowForward className="header_seller_arrow" />
                        </span>
                      </Link>
                    ) : (
                      <>
                        {servicer ? (
                          <Link to="/service-dashboard">
                            <span>
                              Service Dash{" "}
                              <IoIosArrowForward className="ml-1" />
                            </span>
                          </Link>
                        ) : (
                          <Link to="/seller-type">
                            <span>
                              Become Vendor{" "}
                              <IoIosArrowForward className="ml-1" />
                            </span>
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="header_sign">
                  <Link to="/login">
                    <div
                      className="header_sign_text"
                      style={{ marginRight: "20px", color: "white" }}
                    >
                      Sign In
                    </div>
                  </Link>
                  <Link to="/login">
                    <div
                      className="header_sign_text"
                      style={{ color: "white" }}
                    >
                      Sign Up
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </header>
      ) : (
        <>
          {canvawww ? (
            <header className="canvawww_header">
              <div className="nav">
                <div className="header">
                  {/* header left */}
                  <div className="header_left">
                    <Link to="/">
                      <img
                        src="https://res.cloudinary.com/dcinefb4x/image/upload/v1704303912/Nxxtstore_logo_rev1_cbam9h.png"
                        alt="nxxtstore logo"
                        className="header_logo"
                      />
                    </Link>
                    {user && (
                      <div
                        className="search search1"
                        onClick={() => {
                          setShowSearchMenu(true);
                        }}
                      >
                        <Search color={color} />
                        <input
                          type="text"
                          placeholder="Search Products, Services..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          className="hide_input"
                          style={{ height: "16px" }}
                        />
                        {showSearchMenu && (
                          <SearchMenu
                            color={color}
                            setShowSearchMenu={setShowSearchMenu}
                            searchData={searchData}
                            setSearchData={setSearchData}
                            token={user.token}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  {/* header right */}
                  {user ? (
                    <div className="header_right">
                      <Link
                        to="/profile"
                        className="profile_link hover1"
                        style={{ color: "white" }}
                      >
                        <img src={user?.picture} alt="" />
                        <span>{user?.first_name}</span>
                      </Link>
                      <div className=" circle_icon hover1">
                        <Link to="/feed">
                          <MdRssFeed size={22} color="white" />
                        </Link>
                      </div>

                      <div className=" circle_icon hover1">
                        <Link to="/notifications">
                          <IoIosNotifications size={22} color="white" />
                          {/* <Notification /> */}
                          {userNotifications &&
                          userNotifications.unseenNotifications?.length ===
                            0 ? null : (
                            <span className="right_notification">
                              {userNotifications.unseenNotifications?.length}
                            </span>
                          )}
                        </Link>
                      </div>

                      <div className="">
                        <div
                          className="wishlist shopping_cart circle_icon hover1"
                          onClick={() => setOpenWishlist(true)}
                        >
                          <AiOutlineHeart size={22} color="white" />
                          {wishlist && wishlist.length === 0 ? null : (
                            <span className="right_notification">
                              {wishlist && wishlist.length}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="">
                        <div
                          className="shopping_cart circle_icon hover1"
                          onClick={() => {
                            setOpenCart(true);
                          }}
                        >
                          <AiOutlineShoppingCart size={22} color="white" />
                          {cart && cart.length === 0 ? null : (
                            <span className="right_notification">
                              {cart && cart.length}
                            </span>
                          )}
                        </div>
                      </div>

                      <div
                        className={`circle_icon hover1 ${
                          showUserMenu && "active_header"
                        }`}
                        ref={usermenu}
                      >
                        <div
                          onClick={() => {
                            setShowUserMenu((prev) => !prev);
                          }}
                        >
                          <div style={{ transform: "translateY(2px)" }}>
                            <ArrowDown
                              style={{ color: "white" }}
                              color="white"
                            />
                          </div>
                        </div>

                        {showUserMenu && (
                          <UserMenu
                            user={user}
                            seller={seller}
                            servicer={servicer}
                          />
                        )}
                      </div>
                      <div className="canvawww_header_seller_button">
                        {seller ? (
                          <Link to="/shop-dashboard">
                            <span>
                              Shop Dash{" "}
                              <IoIosArrowForward className="header_seller_arrow" />
                            </span>
                          </Link>
                        ) : (
                          <>
                            {servicer ? (
                              <Link to="/service-dashboard">
                                <span>
                                  Service Dash{" "}
                                  <IoIosArrowForward className="ml-1" />
                                </span>
                              </Link>
                            ) : (
                              <Link to="/seller-type">
                                <span>
                                  Become Vendor{" "}
                                  <IoIosArrowForward className="ml-1" />
                                </span>
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="header_sign">
                      <Link to="/login">
                        <div
                          className="header_sign_text"
                          style={{ marginRight: "20px", color: "white" }}
                        >
                          Sign In
                        </div>
                      </Link>
                      <Link to="/login">
                        <div
                          className="header_sign_text"
                          style={{ color: "white" }}
                        >
                          Sign Up
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </header>
          ) : (
            <header>
              <div className="nav">
                <div className="header">
                  {/* header left */}
                  <div className="header_left">
                    <Link to="/">
                      <img
                        src="https://res.cloudinary.com/dcinefb4x/image/upload/v1704303912/Nxxtstore_logo_rev1_cbam9h.png"
                        alt="nxxtstore logo"
                        className="header_logo"
                      />
                    </Link>

                    {user && (
                      <div
                        className="search search1"
                        onClick={() => {
                          setShowSearchMenu(true);
                        }}
                      >
                        <Search color={color} />
                        <input
                          type="text"
                          placeholder="Search Products, Services..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          className="hide_input"
                          style={{ height: "16px" }}
                        />
                        {showSearchMenu && (
                          <SearchMenu
                            color={color}
                            setShowSearchMenu={setShowSearchMenu}
                            searchData={searchData}
                            setSearchData={setSearchData}
                            token={user.token}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  {/* header right */}
                  {user ? (
                    <div className="header_right">
                      <Link to="/profile" className="profile_link hover1">
                        <img src={user?.picture} alt="" />
                        <span>{user?.first_name}</span>
                      </Link>
                      <div className=" circle_icon hover1">
                        <Link to="/feed">
                          <MdRssFeed size={22} />
                        </Link>
                      </div>

                      <div className=" circle_icon hover1">
                        <Link to="/notifications">
                          <IoIosNotifications size={22} color="black" />

                          {/* <Notification /> */}
                          {userNotifications &&
                          userNotifications.unseenNotifications?.length ===
                            0 ? null : (
                            <span className="right_notification">
                              {userNotifications.unseenNotifications?.length}
                            </span>
                          )}
                        </Link>
                      </div>

                      <div className="">
                        <div
                          className="wishlist shopping_cart circle_icon hover1"
                          onClick={() => setOpenWishlist(true)}
                        >
                          <AiOutlineHeart size={22} color="black" />
                          {wishlist && wishlist.length === 0 ? null : (
                            <span className="right_notification">
                              {wishlist && wishlist.length}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="">
                        <div
                          className="shopping_cart circle_icon hover1"
                          onClick={() => {
                            setOpenCart(true);
                          }}
                        >
                          <AiOutlineShoppingCart size={22} color="black" />
                          {cart && cart.length === 0 ? null : (
                            <span className="right_notification">
                              {cart && cart.length}
                            </span>
                          )}
                        </div>
                      </div>

                      <div
                        className={`circle_icon hover1 ${
                          showUserMenu && "active_header"
                        }`}
                        ref={usermenu}
                      >
                        <div
                          onClick={() => {
                            setShowUserMenu((prev) => !prev);
                          }}
                        >
                          <div style={{ transform: "translateY(2px)" }}>
                            <ArrowDown />
                          </div>
                        </div>

                        {showUserMenu && (
                          <UserMenu
                            user={user}
                            seller={seller}
                            servicer={servicer}
                          />
                        )}
                      </div>
                      <div className="header_seller_button">
                        {seller ? (
                          <Link to="/shop-dashboard">
                            {!check ? (
                              <span>
                                Shop Dash{" "}
                                <IoIosArrowForward className="header_seller_arrow" />
                              </span>
                            ) : (
                              "dash"
                            )}
                          </Link>
                        ) : (
                          <>
                            {servicer ? (
                              <Link to="/service-dashboard">
                                {!check ? (
                                  <span>
                                    Service Dash{" "}
                                    <IoIosArrowForward className="ml-1" />
                                  </span>
                                ) : (
                                  "dash"
                                )}
                              </Link>
                            ) : (
                              <Link to="/seller-type">
                                {!check ? (
                                  <span>
                                    Become Vendor{" "}
                                    <IoIosArrowForward className="ml-1" />
                                  </span>
                                ) : (
                                  "sell"
                                )}
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="header_sign">
                      <Link to="/login">
                        <div
                          className="header_sign_text"
                          style={{ marginRight: "20px" }}
                        >
                          Sign In
                        </div>
                      </Link>
                      <Link to="/login">
                        <div className="header_sign_text">Sign Up</div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </header>
          )}
        </>
      )}

      {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

      {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
    </>
  );
}
