import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import socketIO from "socket.io-client";
import { format } from "timeago.js";
import { AiOutlineArrowRight, AiOutlineSend } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";

import Header from "../../components/header";

import "./user-inbox.css";
import { axiosInstance } from "../../functions/axiosInstance";

// const ENDPOINT = "http://localhost:8002";
// const ENDPOINT = "https://www.nxxtstore.com";
const ENDPOINT = "https://nxxtstore-cd377c16eb45.herokuapp.com/";
const socketId = socketIO(ENDPOINT, { transports: ["websocket"] });

const UserInbox = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { isLoading } = useSelector((state) => ({ ...state }));
  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [images, setImages] = useState();
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    socketId.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversation = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/conversation/getAllConversationUser/${user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setConversations(response.data.conversations);
      } catch (error) {
        console.log(error);
      }
    };
    getConversation();
  }, [user, messages]);

  useEffect(() => {
    if (user) {
      const userId = user?.id;
      socketId.emit("addUser", userId);
      socketId.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [user, messages]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== user?.id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };

  // get messages
  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/message/getAllMessages/${currentChat?._id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.log(error);
      }
    };
    getMessage();
  }, [currentChat]);

  // create new message
  const sendMessageHandler = async (e) => {
    e.preventDefault();
    const message = {
      sender: user.id,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      // (member) => member.id !== user.id
      (member) => member !== user.id
    );
    socketId.emit("sendMessage", {
      senderId: user.id,
      receiverId,
      text: newMessage,
    });
    try {
      if (newMessage !== "") {
        await axiosInstance
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/message/createNewMessage`,
            message
          )
          .then((res) => {
            setMessages([...messages, res.data.message]);
            updateLastMessage();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateLastMessage = async () => {
    socketId.emit("updateLastMessage", {
      lastMessage: newMessage,
      lastMessageId: user.id,
    });

    await axiosInstance
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/conversation/updateLastMessage/${currentChat._id}`,
        {
          lastMessage: newMessage,
          lastMessageId: user.id,
        }
      )
      .then((res) => {
        console.log(res.data.conversation);
        setNewMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const updateLastMessageForImage = async () => {
  //   await axios.put(
  //     `${process.env.REACT_APP_BACKEND_URL}/updateLastMessage/${currentChat._id}`,
  //     {
  //       lastMessage: "Photo",
  //       lastMessageId: user._id,
  //     }
  //   );
  // };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <Header />
      {/* user inbox */}
      {!open && (
        <>
          <div className="createProduct_header">
            All messages, {user?.username}
            <div className="create_splitter"></div>
          </div>
          {/* All messages list */}
          {conversations &&
            conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={user.id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
                isLoading={isLoading}
              />
            ))}
        </>
      )}
      {/* seller inbox */}
      {/* {open && (
        <SellerInbox
          setOpen={setOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessageHandler={sendMessageHandler}
          messages={messages}
          // sellerId={seller.id}
          sellerId={user.id}
          userData={userData}
          activeStatus={activeStatus}
          scrollRef={scrollRef}
          setMessages={setMessages}
          // handleImageUpload={handleImageUpload}
        />
      )} */}
      {/* seller inbox */}
      {open && (
        <ServicerInbox
          setOpen={setOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessageHandler={sendMessageHandler}
          messages={messages}
          // sellerId={seller.id}
          servicerId={user.id}
          userData={userData}
          activeStatus={activeStatus}
          scrollRef={scrollRef}
          setMessages={setMessages}
          // handleImageUpload={handleImageUpload}
        />
      )}
    </>
  );
};

const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  userData,
  online,
  setActiveStatus,
  isLoading,
}) => {
  const [user, setUser] = useState([]);
  const [servicer, setServicer] = useState([]);
  const [active, setActive] = useState(0);

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/inbox?${id}`);
    setOpen(true);
  };

  // Try setShop
  useEffect(() => {
    setActiveStatus(online);
    const userId = data.members.find((user) => user != me);

    const getUser = async () => {
      try {
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/shop/getShopInfo/${userId}`
        );
        setUser(res.data.shop);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [me, data]);

  // Try setService
  useEffect(() => {
    setActiveStatus(online);
    const userId = data.members.find((user) => user != me);

    const getServicer = async () => {
      try {
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/servicer/getServicerInfo/${userId}`
        );
        // setUser(res.data.user);
        // setUser(res.data.shop);
        setServicer(res.data.servicer);
      } catch (error) {
        console.log(error);
      }
    };
    getServicer();
  }, [me, data]);

  TODO: console.log(user);
  // check messages for you:
  TODO: console.log(servicer);

  return (
    // user-inbox-page
    <div
      className={`messages_container ${
        active === index ? "active_message" : "unActive_message"
      } `}
      onClick={(e) =>
        setActive(index) ||
        handleClick(data._id) ||
        setCurrentChat(data) ||
        setUserData(user) ||
        setActiveStatus(online)
      }
    >
      <div style={{ position: "relative" }}>
        <img
          // src={`${service?.picture}`}
          src={`${servicer?.picture || user?.picture}`}
          alt=""
          className="sender_message_image"
        />
        {online ? (
          <div className="messages_active_user" />
        ) : (
          <div className="messages_unActive_user" />
        )}
      </div>
      <div style={{ paddingLeft: "6px" }}>
        <h1 className="sender_message_name">{servicer?.name || user?.name}</h1>
        <p style={{ fontSize: "16px", color: "000c" }}>
          {!isLoading && data?.lastMessageId !== user?._id
            ? "You: "
            : servicer?.name?.split(" ")[0] + ": " ||
              user?.name?.split(" ")[0] + ": "}
          {data?.lastMessage}
        </p>
      </div>
    </div>
  );
};

// this is the conversation between user and seller on userside
const SellerInbox = ({
  scrollRef,
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  handleImageUpload,
}) => {
  console.log(userData);
  return (
    // <div className="seller_inbox">
    <div className="seller_inbox">
      {/* message header */}
      <div className="seller_inbox_header">
        <div className="flex">
          <img
            src={`${userData?.picture}`}
            alt=""
            className="seller_inbox_image"
          />
          <div style={{ paddingLeft: "10px" }}>
            <h1 className="seller_inbox_name">{userData?.name}</h1>
            <h1>{activeStatus ? "Active Now" : ""}</h1>
          </div>
        </div>
        <AiOutlineArrowRight
          size={20}
          className="cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="create_splitter"></div>

      {/* messages */}
      <div className="messages scrollbar">
        {messages &&
          messages.map((item, index) => {
            return (
              <div
                className={`message_wrap ${
                  item.sender === sellerId
                    ? "seller_message_wrap"
                    : "sender_message_wrap"
                }`}
                ref={scrollRef}
              >
                {item.sender !== sellerId && (
                  <img
                    src={`${userData?.picture}`}
                    className="message_image"
                    alt=""
                  />
                )}
                {item.images && (
                  <img
                    src={`${item.images?.url}`}
                    // className="w-[300px] h-[300px] object-cover rounded-[10px] mr-2"
                    className="w-[300px] h-[300px] object-cover rounded-[10px] mr-2"
                    alt=""
                  />
                )}
                {item.text !== "" && (
                  <div>
                    <div
                      className={` ${
                        item.sender === sellerId
                          ? "seller_message_wrap"
                          : "sender_message_wrap"
                      }`}
                    >
                      <p>{item.text}</p>
                    </div>

                    <p className="message_timeago">{format(item.createdAt)}</p>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* send message input */}
      <form
        // area-required={true}
        className="message_input"
        onSubmit={sendMessageHandler}
      >
        <div style={{ width: "30px", cursor: "pointer" }}>
          <input
            type="file"
            name=""
            id="image"
            className="hidden"
            onChange={handleImageUpload}
          />
          <label htmlFor="image">
            <TfiGallery style={{ cursor: "pointer" }} size={20} />
          </label>
        </div>
        <div style={{ width: "100%" }}>
          <input
            type="text"
            required
            placeholder="Enter your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="input"
          />
          <input type="submit" value="Send" className="hidden" id="send" />
          <label htmlFor="send">
            <AiOutlineSend size={20} className="message_send_icon" />
          </label>
        </div>
      </form>
    </div>
  );
};

const ServicerInbox = ({
  scrollRef,
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  servicerId,
  userData,
  activeStatus,
  handleImageUpload,
}) => {
  console.log(userData);
  return (
    <div className="user_inbox">
      {/* message header */}
      <div className="seller_inbox_header">
        <div className="flex">
          <img
            src={`${userData?.picture}`}
            alt=""
            className="seller_inbox_image"
          />
          <div className="">
            <h1 className="seller_inbox_name">{userData?.name}</h1>
            <h1>{activeStatus ? "Active Now" : ""}</h1>
          </div>
        </div>
        <AiOutlineArrowRight
          size={20}
          className="cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="create_splitter"></div>

      {/* messages */}
      <div className="messages scrollbar">
        {messages &&
          messages.map((item, index) => {
            return (
              <div
                style={{ display: "flex" }}
                // className={`message_wrap ${
                //   item.sender === servicerId
                //     ? "user_message_wrap"
                //     : "sender_message_wrap"
                // }`}
                ref={scrollRef}
              >
                {item.sender !== servicerId && (
                  <img
                    src={`${userData?.picture}`}
                    className="message_image"
                    alt=""
                  />
                )}
                {item.images && (
                  <img src={`${item.images?.url}`} className="" alt="" />
                )}
                {item.text !== "" && (
                  <div>
                    <div
                      className={`${
                        item.sender === servicerId
                          ? "seller_message_wrap"
                          : "sender_message_wrap"
                      }`}
                    >
                      <p>{item.text}</p>
                    </div>

                    <p className="message_timeago">{format(item.createdAt)}</p>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* send message input */}
      <form
        // area-required={true}
        className="message_input"
        onSubmit={sendMessageHandler}
      >
        <div style={{ width: "30px", cursor: "pointer" }}>
          <input
            type="file"
            name=""
            id="image"
            className="hidden"
            onChange={handleImageUpload}
          />
          <label htmlFor="image">
            <TfiGallery className="cursor-pointer" size={20} />
          </label>
        </div>
        <div className="" style={{ width: "100%" }}>
          <input
            type="text"
            required
            placeholder="Enter your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="input"
          />
          <input type="submit" value="Send" className="hidden" id="send" />
          <label htmlFor="send">
            <AiOutlineSend size={20} className="message_send_icon" />
          </label>
        </div>
      </form>
    </div>
  );
};

export default UserInbox;
