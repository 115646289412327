import React from "react";
import { Link } from "react-router-dom";

import { homeCategoryData } from "../../data/categoriesData";

const HomeCategories = () => {
  return (
    <section>
      <div className="home_categories">
        <h1 className="section_header">Make it a day</h1>

        <div className="home_categories_container">
          {/* Map later */}
          {/* Category 1 */}
          <div className="home_categories_card">
            <h3 className="home_categories_title">
              {homeCategoryData[0].title}
            </h3>
            <div className="home_categories_images">
              {/* wrap 0 */}
              <Link to="/services?category=Childcare">
                <div className="home_category_wrap_0">
                  <img
                    src={homeCategoryData[0].images[0].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[0].text[0]}
                  </span>
                </div>
              </Link>

              {/* wrap 1 */}
              <Link to="/services?category=Beauty">
                <div className="home_category_wrap_1">
                  <img
                    src={homeCategoryData[0].images[1].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[0].text[1]}
                  </span>
                </div>
              </Link>

              {/* wrap 2 */}
              <Link to="/services?category=Styling">
                <div className="home_category_wrap_2">
                  <img
                    src={homeCategoryData[0].images[2].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[0].text[2]}
                  </span>
                </div>
              </Link>

              {/* wrap 3 */}
              <Link to="/rides">
                <div className="home_category_wrap_3">
                  <img
                    src={homeCategoryData[0].images[3].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[0].text[3]}
                  </span>
                </div>
              </Link>
            </div>
            <div className="home_categories_subTitle">
              {homeCategoryData[0].subTitle}
            </div>
          </div>

          {/* Catergory 2 */}
          <div className="home_categories_card">
            <h3 className="home_categories_title">
              {homeCategoryData[1].title}
            </h3>
            <div className="home_categories_images">
              {/* wrap 0 */}
              <Link to="/products?category=Women">
                <div className="home_category_wrap_0">
                  <img
                    src={homeCategoryData[1].images[0].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[1].text[0]}
                  </span>
                </div>
              </Link>

              {/* wrap 1 */}
              <Link to="/products?category=Men">
                <div className="home_category_wrap_1">
                  <img
                    src={homeCategoryData[1].images[1].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[1].text[1]}
                  </span>
                </div>
              </Link>

              {/* wrap 2 */}
              <Link to="/products?category=Children">
                <div className="home_category_wrap_2">
                  <img
                    src={homeCategoryData[1].images[2].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[1].text[2]}
                  </span>
                </div>
              </Link>

              {/* wrap 3 */}
              <Link to="/products?category=Pets">
                <div className="home_category_wrap_3">
                  <img
                    src={homeCategoryData[1].images[3].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[1].text[3]}
                  </span>
                </div>
              </Link>
            </div>
            <div className="home_categories_subTitle">
              {homeCategoryData[1].subTitle}
            </div>
          </div>

          {/* Catergory 3 */}
          <div className="home_categories_card">
            <h3 className="home_categories_title">
              {homeCategoryData[2].title}
            </h3>
            <div className="home_categories_images">
              {/* wrap 0 */}
              <Link to="/eateries">
                <div className="home_category_wrap_0">
                  <img
                    src={homeCategoryData[2].images[0].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[2].text[0]}
                  </span>
                </div>
              </Link>
              {/* wrap 1 */}
              <Link to="/eateries">
                <div className="home_category_wrap_1">
                  <img
                    src={homeCategoryData[2].images[1].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[2].text[1]}
                  </span>
                </div>
              </Link>
              {/* wrap 2 */}
              <Link to="/eateries">
                <div className="home_category_wrap_2">
                  <img
                    src={homeCategoryData[2].images[2].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[2].text[2]}
                  </span>
                </div>
              </Link>
              {/* wrap 3 */}
              <Link to="/eateries">
                <div className="home_category_wrap_3">
                  <img
                    src={homeCategoryData[2].images[3].image_Url}
                    alt=""
                    className="home_categories_image"
                  />
                  <span className="home_categories_text">
                    {homeCategoryData[2].text[3]}
                  </span>
                </div>
              </Link>
            </div>

            <div className="home_categories_subTitle">
              {homeCategoryData[2].subTitle}
            </div>
          </div>

          <div className="home_categories_share_wrap">
            <span className="home_categories_title">Share</span>
            <img
              src="https://res.cloudinary.com/dcinefb4x/image/upload/v1699841066/testtest/post_images/jgmgdfqkxefs4dv1vt9y.jpg"
              alt=""
              className="home_categories_share_image"
            />
            <Link to={`/profile`}>
              <span className="home_categories_share_subTitle">
                Share to profile
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCategories;
