import Cookies from "js-cookie";
import { createReducer } from "@reduxjs/toolkit";

export function userReducer(
  state = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,

  action
) {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "LOGOUT":
      return null;
    case "UPDATEPICTURE":
      return { ...state, picture: action.payload };
    case "UPDATEDETAILS":
      return { ...state, details: action.payload };
    case "VERIFY":
      return { ...state, verified: action.payload };
    case "FETCH_USER_DATA":
      return { ...state, userData: action.payload };

    default:
      return state;
  }
}
