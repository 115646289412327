import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdBorderClear } from "react-icons/md";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import { getAllServicerServices } from "../../store/actions/serviceActions";
import { getAllOrdersOfServicer } from "../../store/actions/orderActions";
import HomePageProductBanner from "../../components/banner/HomePageProductBanner";
import Spinner from "../shared/spinner/Spinner";

const ServiceDashboardHero = () => {
  const { servicer } = useSelector((state) => ({ ...state }));
  const { loading, orders } = useSelector((state) => state.order);
  const { services } = useSelector((state) => state.services);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllServicerServices(servicer?.id));
    dispatch(getAllOrdersOfServicer(servicer?.id));
  }, [dispatch]);

  const availableBalance = servicer?.availableBalance || 0.0;

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      // cellClassName: (params) => {
      //   return params.getValue(params.id, "status") === "Delivered"
      //     ? "greenColor"
      //     : "redColor";
      // },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
        total: "US$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="shop_dashboard_hero">
          <h3 className="sd_hero_title">Overview</h3>
          <div className="sd_hero_container">
            <div className="sd_hero_item">
              <div className="sd_hero_wrap">
                {/* <AiOutlineMoneyCollect
                  size={40}
                  style={{ marginRight: "10px" }}
                  fill="#00000085"
                /> */}
                <h3 className="sd_hero_subtitle">
                  Account Balance <br />
                  <span className="sd_hero_charge">
                    (with 10% service charge)
                  </span>
                </h3>
              </div>
              <h5>${availableBalance.toFixed(2)}</h5>
              <Link to="/dashboard-withdraw-money">
                <h5
                  className="black_button"
                  style={{
                    backgroundColor: "#42b72a",
                    marginTop: "10px",
                  }}
                >
                  Withdraw
                </h5>
              </Link>
            </div>

            <div className="sd_hero_item">
              <div className="sd_hero_wrap">
                {/* <MdBorderClear size={30} className="mr-2" fill="#00000085" /> */}
                <h3 className="sd_hero_subtitle">All Service Orders</h3>
              </div>
              <h5 className="">{orders && orders.length}</h5>
              <Link to="/dashboard-orders">
                <h5
                  className="black_button"
                  style={{
                    backgroundColor: "#5e97b7",
                    marginTop: "10px",
                  }}
                >
                  View Orders
                </h5>
              </Link>
            </div>

            <div className="sd_hero_item">
              <div className="sd_hero_wrap">
                {/* <AiOutlineMoneyCollect
                  size={30}
                  className="mr-2"
                  fill="#00000085"
                /> */}
                <h3 className="sd_hero_subtitle">All Services</h3>
              </div>
              <h5 className="sd_hero_amount">{services && services.length}</h5>
              <Link to="/service-services">
                <h5 className="black_button" style={{ marginTop: "10px" }}>
                  View Services
                </h5>
              </Link>
            </div>
          </div>
          <br />
          <h3 style={{ marginBottom: "20px" }}>Latest Orders</h3>
          <div style={{ backgroundColor: "white", width: "96%" }}>
            {" "}
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
            />
          </div>
          <HomePageProductBanner />
        </div>
      )}
    </>
  );
};

export default ServiceDashboardHero;
