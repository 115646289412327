import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Header from "../../components/header";
import TrHero from "../../components/truly-remembered/TrHero";
import TrCategories from "../../components/categories/TrCategories";

import FeaturedProduct from "../../components/featured/FeaturedProduct";
import TrProductCard from "../../components/truly-remembered/trProductCard/TrProductCard";
import { trProductData } from "../../data/trProductData";
import ShopProfileData from "../../components/shop/ShopProfileData";
import Footer from "../../components/footer/Footer";
import { toast } from "react-toastify";
import axios from "axios";

import { useParams } from "react-router-dom";
import { axiosInstance } from "../../functions/axiosInstance";

import "./style.css";

const TrulyRememberedPage = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState([]);
  const [userEmail, setUserEmail] = useState("");

  const shopId = useParams();

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.filter((a) => a.featured === true);
    const firstFive = sortedData && sortedData.slice(0, 5);
    setProductData(firstFive);
  }, [allProducts]);

  const handleEmailSubmit = async () => {
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/vendor/getVendorEmail/${shopId.id}`,
        {
          userEmail,
        }
      );
      toast.success("Email sent successfully");
      setUserEmail("");
    } catch (error) {
      toast.error("Error sending email", error.message);
    }
  };

  return (
    <>
      <Header />
      <TrHero />
      <div className="truly_remembered_page">
        <div className="tr_email_container">
          <label htmlFor="email">Basket pre-order list</label>
          <div className="tr_email_wrap">
            <input
              type="email"
              placeholder="Enter an email address"
              className="eatery_page_input"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
            <button
              onClick={handleEmailSubmit}
              className="blue_btn"
              style={{ height: "26px", marginLeft: "10px" }}
            >
              Submit
            </button>
          </div>
        </div>
        {/* <TrCategories /> */}
        {/* <div>
          <img
            src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705329087/assets/how-to-section_m6aiyy.png"
            alt=""
            className="tr_how_to"
          />
        </div> */}
        <div className="">
          {/* {productData?.map((product, i) => (
            <TrProductCard data={product} key={i} />
          ))} */}
          <ShopProfileData isOwner={false} data={data} page="trulyremembered" />
        </div>
        {/* <div className="tr_subscribe_container">
          <div className="tr_subscribe_left">
            <div className="tr_subscribe_title_wrap">
              <p className="tr_subscribe_title">Babies first 12</p>
              <p className="tr_subscribe_subtitle">subscription service</p>
            </div>
            <div className="tr_subscribe_text_wrap">
              <p>
                A gift box a month, welcoming the first 12 months of your new
                precious baby.
              </p>
              <br />

              <p className="tr_subscribe_text">
                Every month you will witness the magic as we freeze those
                precious moments. When curiosity sparkles in their eyes and
                smiles light up their faces. Our skilled designers help define
                the innocence of your little one. Our keepsake packages are
                designed to preserve these fleeting moments in a tangible and
                beautiful form
              </p>
              <br />
              <p>
                Contains a baby bundle and surprise customized gift included.
              </p>
            </div>
          </div>
          <div className="tr_subscribe_right">
            <img
              src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705378627/subscribe_mwjsvp.png"
              alt=""
              className="tr_subscribe_main_image"
            />
          </div>
        </div> */}
      </div>
      <div style={{ marginTop: "100px" }}>
        <Footer />
      </div>
    </>
  );
};

export default TrulyRememberedPage;
