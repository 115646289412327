import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HelpSupport from "./HelpSupport";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { IoIosArrowForward } from "react-icons/io";
import { IoInformationCircle, IoLogOutSharp } from "react-icons/io5";
// import { IoMdInformationCircle } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { FaFolder } from "react-icons/fa";

export default function UserMenu({ user, seller, servicer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(0);

  const logout = () => {
    Cookies.set("user", "");
    Cookies.set("seller", "");
    Cookies.set("servicer", "");
    dispatch({
      type: "LOGOUT",
    });
    window.location.reload(true);
    navigate("/login");
  };

  return (
    <div className="mmenu">
      {visible === 0 && (
        <div>
          <Link to="/profile" className="mmenu_header hover3">
            <img src={user?.picture} alt="" />
            <div className="mmenu_col">
              <span>
                {user?.first_name} {user?.last_name}
              </span>
              <span>profile</span>
            </div>
          </Link>
          <div className="mmenu_splitter"></div>
          <div className="mmenu_main hover3">
            <Link to="/orders" style={{ display: "flex" }}>
              <div className="small_circle">
                {/* <i className="report_filled_icon"></i> */}
                <IoInformationCircle size={28} color="black" />
              </div>
              <div className="mmenu_col">
                <div className="mmenu_span1">Order details</div>
                <div className="mmenu_span2">Order/Account Info</div>
              </div>
            </Link>
          </div>
          <div className="mmenu_splitter"></div>

          <div
            className="mmenu_item hover3"
            onClick={() => {
              logout();
            }}
          >
            <div className="small_circle" style={{ marginLeft: "6px" }}>
              <IoLogOutSharp size="28px" color="black" />
            </div>
            <span>Logout</span>
          </div>
          <div className="mmenu_splitter"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <div className="mmenu_seller_button">
              {seller ? (
                <Link to="/shop-dashboard">
                  <span>
                    Shop Dash <IoIosArrowForward color="white" />
                  </span>
                </Link>
              ) : (
                <>
                  {servicer ? (
                    <Link to="/service-dashboard">
                      <span>
                        Service Dash <IoIosArrowForward color="white" />
                      </span>
                    </Link>
                  ) : (
                    <Link to="/seller-type">
                      <span>
                        Become Vendor <IoIosArrowForward />
                      </span>
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {visible === 1 && <HelpSupport setVisible={setVisible} />}
    </div>
  );
}
