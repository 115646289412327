import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductCard from "./productCard/ProductCard";

const SuggestedProduct = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);

  const [productData, setProductData] = useState();

  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category === data.category);
    setProductData(d);
  }, []);

  return (
    <div className="suggested_products">
      <div className="suggested_products_container">
        {data ? (
          <div className="">
            <h2>Related Product</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {productData &&
                productData.map((i, index) => (
                  <ProductCard data={i} key={index} />
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SuggestedProduct;
