import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer/Footer";
import ProductDetails from "../../components/product/ProductDetails";
import { useSelector } from "react-redux";

import SuggestedProduct from "../../components/product/SuggestedProduct";

const ProductDetailsPage = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();

  const { id } = useParams();

  useEffect(() => {
    const loadProducts = async () => {
      const data = allProducts && allProducts?.find((i) => i._id === id);
      setData(data);
    };
    loadProducts();
  }, [data, allProducts]);

  return (
    <div>
      <Header />
      <ProductDetails data={data} />
      {data && <SuggestedProduct data={data} />}
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
