import { useState } from "react";
import "./amlabs.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const AmLabsHero = () => {
  const [userEmail, setUserEmail] = useState("");

  const shopId = useParams();

  const handleEmailSubmit = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/getVendorEmail/${shopId.id}`,
        {
          userEmail,
        }
      );
      toast.success("Email sent successfully");
      setUserEmail("");
    } catch (error) {
      toast.error("Email already registered", error.message);
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="amlabs_hero" style={{ marginTop: "48px" }}>
      <p className="amlabs_hero_creators">Creators of Nxxtstore</p>
      <div className="amlabs_hero_container">
        <h1 className="amlabs_hero_title">
          Turn your ideas into <span className="gradient"></span>.
        </h1>
        <p className="amlabs_hero_text">
          Having a great idea is just the beginning. It takes hard work,
          dedication, and the right resources to bring your vision to life.
          That's where we come in.
        </p>
        <div className="amlabs_email_container">
          <label htmlFor="email" style={{ color: "yellow", fontSize: "28px" }}>
            Email for inquires
          </label>
          <div>
            <input
              type="email"
              placeholder="Enter an email address"
              className="eatery_page_input"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                border: "1px solid yellow",
                background: "transparent",
                color: "yellow",
              }}
            />
            <button
              onClick={handleEmailSubmit}
              className="blue_btn"
              style={{ backgroundColor: "yellow", color: "black" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="flickerA opacity"></div>
      <div className="flickerB opacity"></div>
      <div className="flickerC"></div>
    </div>
  );
};

export default AmLabsHero;
