import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Return, Search } from "../../svg";
import useClickOutside from "../../helpers/clickOutside";
import {
  addToSearchHistory,
  getSearchHistory,
  removeFromSearch,
  search,
  searchUser,
} from "../../functions/user";
import { searchProduct } from "../../functions/product";
import { searchService } from "../../functions/service";

export default function SearchMenu({ color, setShowSearchMenu, token }) {
  const { allProducts } = useSelector((state) => state.products);

  const [iconVisible, setIconVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);

  const menu = useRef(null);
  const input = useRef(null);

  useClickOutside(menu, () => {
    setShowSearchMenu(false);
  });

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    const res = await getSearchHistory(token);
    setSearchHistory(res);
  };

  useEffect(() => {
    input.current.focus();
  }, []);

  const handleUserSearch = async () => {
    if (searchTerm === "") {
      setUsers("");
    } else {
      const res = await searchUser(searchTerm, token);
      setUsers(res);
    }
  };

  const handleProductSearch = async () => {
    if (searchTerm === "") {
      setProducts("");
    } else {
      const res = await searchProduct(searchTerm, token);
      setProducts(res);
    }
  };

  const handleServiceSearch = async () => {
    if (searchTerm === "") {
      setServices("");
    } else {
      const res = await searchService(searchTerm, token);
      setServices(res);
    }
  };

  const addToSearchHistoryHandler = async (searchUser) => {
    const res = await addToSearchHistory(searchUser, token);
    getHistory();
  };

  const handleRemove = async (searchUser) => {
    removeFromSearch(searchUser, token);
    getHistory();
  };

  const handleKeyUp = (e) => {
    handleProductSearch(e);
    handleServiceSearch(e);
    handleUserSearch(e);
  };

  return (
    <div className="header_left search_area scrollbar" ref={menu}>
      <div className="search_wrap">
        <div className="header_logo">
          <div
            className="circle hover1"
            onClick={() => {
              setShowSearchMenu(false);
            }}
          >
            <Return color={color} />
          </div>
        </div>
        <div
          className="search"
          onClick={() => {
            input.current.focus();
          }}
        >
          {iconVisible && (
            <div>
              <Search color={color} />
            </div>
          )}
          <input
            type="text"
            placeholder="Search Nxxtstore..."
            ref={input}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyUp={handleKeyUp}
            onFocus={() => {
              setIconVisible(false);
            }}
            onBlur={() => {
              setIconVisible(true);
            }}
          />
        </div>
      </div>

      <div className="search_results scrollbar">
        {products &&
          products.results?.map((i, index) => (
            <Link
              to={`/product/${i._id}`}
              className="search_user_item hover1"
              onClick={() => addToSearchHistoryHandler(i._id)}
              key={i._id}
            >
              <img src={i.images[0]?.url} alt="" />
              <span>
                {i.name} <span className="search_type_text">product</span>
              </span>
            </Link>
          ))}
        {services &&
          services.results?.map((i, index) => (
            <Link
              to={`/book-appointment/${i._id}`}
              className="search_user_item hover1"
              onClick={() => addToSearchHistoryHandler(i._id)}
              key={i._id}
            >
              <img src={i.images[0]?.url} alt="" />
              <span>
                {i.name} <span className="search_type_text">service</span>
              </span>
            </Link>
          ))}
        {users &&
          users?.results?.map((user) => (
            <Link
              to={`/profile/${user.username}`}
              className="search_user_item hover1"
              onClick={() => addToSearchHistoryHandler(user._id)}
              key={user._id}
            >
              <img src={user.picture} alt="" />
              <span>
                {user.first_name} {user.last_name}
              </span>
            </Link>
          ))}
      </div>
    </div>
  );
}
