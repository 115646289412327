import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";

import LoginInput from "../../components/inputs/loginInput";
import DotLoader from "react-spinners/DotLoader";
import { axiosInstance } from "../../functions/axiosInstance";

const loginInfos = {
  email: "",
  password: "",
};

export default function LoginForm({ setVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, setLogin] = useState(loginInfos);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { email, password } = login;

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  const loginValidation = Yup.object({
    email: Yup.string()
      .required("Email address is required.")
      .email("Must be a valid email.")
      .max(100),
    password: Yup.string().required("Password is required"),
  });

  const loginSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(`/user/login`, {
        email,
        password,
      });
      dispatch({ type: "LOGIN", payload: data });
      Cookies.set("user", JSON.stringify(data));
      navigate("/");
    } catch (error) {
      setLoading(false);
      setError(error.response?.data.message);
    }
  };

  return (
    <div className="login_wrap">
      <div className="login_1">
        <div className="login_2">
          <div className="login_2_wrap">
            <div style={{ height: "60px", width: "100%" }}>
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/dcinefb4x/image/upload/v1704303912/Nxxtstore_logo_rev1_cbam9h.png"
                  alt="nxxtstore logo"
                  style={{ height: "50px", width: "50px" }}
                />
              </Link>
            </div>
            <span>Products, Services, & More.</span>

            <Formik
              enableReinitialize
              initialValues={{
                email,
                password,
              }}
              validationSchema={loginValidation}
              onSubmit={() => {
                loginSubmit();
              }}
            >
              {(formik) => (
                <Form>
                  <LoginInput
                    type="text"
                    name="email"
                    placeholder="Email address"
                    onChange={handleLoginChange}
                  />
                  <LoginInput
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleLoginChange}
                    bottom
                  />
                  <button type="submit" className="blue_btn">
                    Log In
                  </button>
                </Form>
              )}
            </Formik>
            <Link to="/reset" className="forgot_password">
              Forgotten password?
            </Link>
            <DotLoader color="#5e97b7" loading={loading} size={30} />
            {error && <div className="error_text">{error}</div>}
            <div className="sign_splitter"></div>
            <button
              className="black_button open_signup"
              onClick={() => setVisible(true)}
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
