import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const EateryHero = () => {
  const [userEmail, setUserEmail] = useState("");

  const handleEmailSubmit = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getEateryEmail`, {
        userEmail,
      });
      toast.success("Email sent successfully");
      setUserEmail("");
    } catch (error) {
      toast.error("Email already registered", error.message);
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="eatery_hero">
      <div className="eatery_notification">
        <div className="eatery_notification_container">
          <h1 style={{ color: "#ffffff" }}>Almost ready to serve.</h1>
          <span className="eatery_notification_text">
            We're cooking up something we know your gonna love. Please provide
            your email to be notified when the food is ready.
          </span>
          <div className="eatery_notification_wrap">
            <input
              type="email"
              placeholder="Enter an email address"
              className="eatery_page_input"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
            />
            <button
              onClick={handleEmailSubmit}
              className="black_button"
              style={{
                marginLeft: "20px",
                width: "20%",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* <Eatery /> */}
    </div>
  );
};

export default EateryHero;
