export const serviceData = [
  {
    id: 7,
    title: "Plumbing",
    subTitle: "",
    image_Url:
      "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  },
  {
    id: 8,
    title: "Electrical",
    subTitle: "",
    image_Url:
      "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  },
  {
    id: 9,
    title: "Construction",
    subTitle: "",
    image_Url:
      "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  },
  {
    id: 10,
    title: "Mechanic",
    subTitle: "",
    image_Url:
      "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  },
  {
    id: 11,
    title: "Moving",
    subTitle: "",
    image_Url:
      "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  },
  {
    id: 12,
    title: "Cleaning",
    subTitle: "",
    image_Url:
      "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  },
];

export const serviceSubData = [
  {
    id: 1,
    title: "Plumbing",
    url: "/plumbing",
  },
  {
    id: 2,
    title: "Electrical",
    url: "/electrical",
  },
  {
    id: 3,
    title: "Construction",
    url: "/construction",
  },
  {
    id: 4,
    title: "Mechanic",
    url: "/mechanic",
  },
  {
    id: 5,
    title: "Moving",
    url: "/moving",
  },
  {
    id: 6,
    title: "Cleaning",
    url: "/cleaning",
  },
];

export const remoteServiceData = [
  {
    id: 1,
    title: "Website Design",
    img: "",
  },
  {
    id: 2,
    title: "Graphic Design",
    img: "",
  },
  {
    id: 1,
    title: "Tech & Development",
    img: "",
  },
];
