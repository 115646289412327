import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// import { getAllProductsShop } from "../../redux/actions/product";
import Spinner from "../shared/spinner/Spinner";
import { shopLogout } from "../../functions/auth";

import { getAllProductsShop } from "../../store/actions/productActions";
import { DataGrid } from "@mui/x-data-grid";

import "./style.css";

const ShopInfo = ({ isOwner, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  // var shopId = id === undefined ? seller.id : id;

  // const { username } = useParams();
  // var userName = username === undefined ? user.username : username;

  // useEffect(() => {
  //   setIsLoading(true);
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/getShopInfo/${id}`)
  //     .then((res) => {
  //       setData(res.data?.shop);
  //       isLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // }, []);

  const logoutHandler = async () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/logout`);
    window.location.reload();
  };

  console.log(data);
  console.log("path", id);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="shop_info_left">
          {/* <div
           className={
             data._id === "65a6e9a08fea53cf8d387150" ? "" : "shop_info_left"
           }
         > */}
          <div className="shop_info_left_data">
            {/* <img src={data?.picture} alt="" className="shop_info_image" /> */}
            <div className="profile_w_img">
              {/* <div
                className="profile_w_bg"
                style={{
                  backgroundSize: "cover",
                  backgroundImage: `url(${data.picture})`,
                }}
              ></div> */}
              {/* {!visitor && (
                  <div
                    className="profile_circle hover1"
                    onClick={() => setShow(true)}
                  >
                    <i className="camera_filled_icon"></i>
                  </div>
                )} */}
            </div>

            <h3 className="shop_info_name">{data?.name}</h3>

            <div style={{ width: "100%", marginTop: "40px" }}>
              <p className="shop_info_description">{data?.description}</p>

              {/* <div style={{ padding: "10px" }}>
                <h5 className="font-[600]">Address</h5>
                <h4 className="text-[#000000a6]">{data?.address}</h4>
              </div>
              <div style={{ padding: "10px" }}>
                <h5 className="font-[600]">Phone Number</h5>
                <h4 className="text-[#000000a6]">{data?.phoneNumber}</h4>
              </div> */}
              <div style={{ padding: "10px" }}>
                <h5 className="font-[600]">Total Products</h5>
                <h4 className="text-[#000000a6]">10</h4>
              </div>
              <div style={{ padding: "10px" }}>
                <h5 className="font-[600]">Shop Ratings</h5>
                <h4 className="text-[#000000b0]">4/5</h4>
              </div>
              <div style={{ padding: "10px" }}>
                <h5 className="font-[600]">Joined On</h5>
                <h4 className="text-[#000000b0]">
                  {data?.createdAt?.slice(0, 10)}
                </h4>
              </div>
            </div>
          </div>
          {isOwner && (
            <div className="shop_info_buttons">
              <div className="blue_btn" style={{ width: "50%" }}>
                <span className="text-white">Edit Shop</span>
              </div>
              <div
                className="{`${styles.button} !w-full !h-[42px] !rounded-[5px]`}"
                onClick={shopLogout}
              >
                <span className="black_button">Log Out</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShopInfo;
