import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Login from "../pages/login";
import ShopLoginPage from "../pages/shop/ShopLoginPage";
import ServicerLoginPage from "../pages/service/ServiceLoginPage";

// export default LoggedInRoutes
export function LoggedInRoutes() {
  const { user } = useSelector((state) => ({ ...state }));
  return user ? <Outlet /> : <Login />;
}

export function ShopLoggedInRoutes() {
  const { seller } = useSelector((state) => ({ ...state }));
  return seller ? <Outlet /> : <ShopLoginPage />;
}

export function ServicerLoggedInRoutes() {
  const { servicer } = useSelector((state) => ({ ...state }));
  return servicer ? <Outlet /> : <ServicerLoginPage />;
}
