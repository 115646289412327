import { useRef, useState } from "react";

import ServiceProfilePicture from "../../components/serviceProfilePicture";
// import Friendship from "./Friendship";
import { Link } from "react-router-dom";

export default function ServicerProfilePictureInfos({
  profile,
  visitor,
  photos,
  imageUrl,
  data,
}) {
  const [show, setShow] = useState(false);
  const pRef = useRef(null);

  return (
    <div className="shop_profile_img_wrap">
      {show && (
        <ServiceProfilePicture setShow={setShow} pRef={pRef} photos={photos} />
      )}
      <div className="shop_profile_w_left">
        <div className="shop_user_profile_image_wrap">
          <Link to={`/profile/${data?.user?.username}`}>
            <img
              src={data?.user?.picture}
              alt=""
              className="shop_user_profile_image"
            />
          </Link>
        </div>
        <div className="shop_profile_w_img">
          <div
            className="shop_profile_w_bg"
            ref={pRef}
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${profile?.picture})`,
            }}
          ></div>
          {!visitor && (
            <div
              className="shop_profile_circle hover1"
              onClick={() => setShow(true)}
            >
              <i className="camera_filled_icon"></i>
            </div>
          )}
        </div>
        <div className="shop_profile_w_col">
          <div className="shop_profile_name">{profile?.name}</div>
          <div className="othername">{profile?.description}</div>
          <div className="profile_friend_count">
            {profile?.friends && (
              <div className="profile_card_count">
                {profile?.friends.length === 0
                  ? ""
                  : profile?.friends.length === 1
                  ? "1 Friend"
                  : `${profile?.friends.length} Friends`}
              </div>
            )}
          </div>
          {/* <div className="profile_friend_imgs">
            {profile?.friends &&
              profile.friends.slice(0, 6).map((friend, i) => (
                <Link to={`/profile/${friend.username}`} key={i}>
                  <img
                    src={friend.picture}
                    alt=""
                    style={{
                      transform: `translateX(${-i * 7}px)`,
                      zIndex: `${i}`,
                    }}
                  />
                </Link>
              ))}
          </div> */}
        </div>
      </div>
      {visitor ? (
        <></>
      ) : (
        // <Friendship friendshipp={profile?.friendship} profileid={profile._id} />
        <div className="profile_w_right">
          <div className="blue_btn">
            <img src="../../../icons/plus.png" alt="" className="invert" />
            <span>Add to story</span>
          </div>
          <div className="gray_btn">
            <i className="edit_icon"></i>
            <span>Edit profile</span>
          </div>
        </div>
      )}
    </div>
  );
}
