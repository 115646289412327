import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const postReducer = createReducer(initialState, {
  // User all posts
  getAllPostsUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllPostsUserSuccess: (state, action) => {
    state.isLoading = false;
    state.posts = action.payload;
  },
  getAllPostsUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.posts = false;
  },

  // Get all posts
  getEveryPostsRequest: (state) => {
    state.isLoading = true;
  },
  getEveryPostsSuccess: (state, action) => {
    state.isLoading = false;
    state.everyPosts = action.payload;
  },
  getEveryPostsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    // state.products = false;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
