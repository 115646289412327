import React from "react";
import { useSelector } from "react-redux";

import ServiceDashboardSidebar from "../../components/service/layout/ServiceDashboardSidebar";
import CreateService from "../../components/service/CreateService";
import Header from "../../components/header";

const ServiceCreateService = ({ setVisible }) => {
  const { servicer } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Header />
      <ServiceDashboardSidebar active={4} />
      <CreateService servicer={servicer} setVisible={setVisible} />
    </>
  );
};

export default ServiceCreateService;
