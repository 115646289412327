import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import ServiceCard from "../../components/service/serviceCard/ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getAllServices } from "../../store/actions/serviceActions";
import ServiceHero from "../../components/service/ServiceHero";
import ServiceCategories from "../../components/categories/ServiceCategories";
import Spinner from "../../components/shared/spinner/Spinner";
import ServicePageBanner from "../../components/banner/ServicePageBanner";

import "./style.css";

const ServicePage = () => {
  const { allServices } = useSelector((state) => state.services);
  const [searchParams] = useSearchParams();
  const serviceData = searchParams.get("category");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllServices());
  }, []);

  useEffect(() => {
    if (serviceData === null) {
      const d = allServices;
      setData(d);
    } else {
      const d =
        allServices && allServices.filter((i) => i.category === serviceData);
      setData(d);
    }
    // window.scrollTo(0, 0);
  }, [allServices]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Header />
          <ServiceHero />

          <div className="service_page">
            <ServiceCategories />
            <section>
              <div className="service_page_container">
                {data &&
                  data.map((i, index) => <ServiceCard data={i} key={index} />)}
                {data && data?.length === 0 ? (
                  <h1 className="">No services Found!</h1>
                ) : null}
              </div>
            </section>
            <ServicePageBanner />
          </div>
        </>
      )}
    </>
  );
};

export default ServicePage;
