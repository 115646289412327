import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SetLoader } from "../../store/reducers/loadersSlice";
import Spinner from "../../components/shared/spinner/Spinner";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  Button,
  Col,
  // DatePicker,
  Form,
  Input,
  Row,
  message,
  // TimePicker,
} from "antd";
import TimePicker from "react-time-picker";
import { getServicesOfService } from "../../store/actions/serviceActions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { toast } from "react-toastify";
import { AddNotification } from "../../functions/notifications";
import { addToCart } from "../../store/actions/cartActions";
import { AiOutlineMessage } from "react-icons/ai";
import { TbVideoPlus } from "react-icons/tb";
import { axiosInstance } from "../../functions/axiosInstance";

import "./book-appointment.css";

dayjs.extend(customParseFormat);

const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
const todayStartOfTheDay = today.startOf("day");

const BookAppointmentPage = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { cart } = useSelector((state) => state.cart);

  // const { notification } = useSelector((state) => state.notification);

  const [isAvailable, setIsAvailable] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState("07:00");
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);
  const [feePerHour, setFeePerHour] = useState(null);

  const [count, setCount] = useState(1);
  const [data, setData] = useState(null);
  const [select, setSelect] = useState(0);

  // const [value, setValue] = useState(dayjs(""));
  // const [value, setValue] = useState(dayjs(""));
  // const [value, onChange] = useState("10:00");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceId } = useParams();

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const getServiceData = async () => {
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/service/getServiceById/${serviceId}`
      );
      if (response.data.success) {
        dispatch(SetLoader(false));
        setService(response.data);
        setData(response.data);
      }
    } catch (error) {
      dispatch(SetLoader(false));
    }
  };

  // checkBookingAvailability
  const checkAvailability = async () => {
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/checkBookingAvailability`,
        {
          serviceId: serviceId,
          date: date,
          time: time,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setIsAvailable(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(SetLoader(false));
    }
  };

  const bookNow = async () => {
    setIsAvailable(false);
    try {
      dispatch(SetLoader(true));
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/bookAppointment`,
        {
          serviceId: serviceId,
          userId: user.id,
          serviceInfo: service,
          userInfo: user,
          date: date,
          time: time,
          feePerHour: feePerHour,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setData(response.data);
        dispatch(SetLoader(true));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(SetLoader(false));
    }
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart");
    } else {
      if (data.occupants < 1) {
        toast.error(`Sorry limited amount in occupants meet`);
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addToCart(cartData));
        toast.success("Item added to cart successfully");
      }
    }
  };

  const handleMessageSubmit = async () => {
    if (user) {
      const groupTitle = service?.service?.servicerId + user.id;
      const userId = user.id;
      const serviceId = service?.service?.servicerId;
      console.log(serviceId);

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/createNewServicerConversation`,
          {
            groupTitle,
            userId,
            serviceId,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          navigate(`/inbox`);
        })
        .catch((error) => {
          toast.error(error.response.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  useEffect(() => {
    getServiceData();
  }, []);

  return (
    <>
      <Header />
      <div className="book_appointment">
        {loading ? (
          <Spinner />
        ) : (
          <div className="ba_container">
            <div className="ba_wrap">
              <div className="ba_wrap_left">
                <img
                  src={service.service?.images[select].url}
                  alt=""
                  className="ba_image_select"
                />
                <div className="ba_images_wrap">
                  {service.service?.images &&
                    service.service?.images.map((i, index) => (
                      <img
                        src={i.url}
                        alt=""
                        className="ba_images"
                        onClick={() => setSelect(index)}
                        key={i._id}
                      />
                    ))}
                </div>
              </div>
              <div className="ba_wrap_right">
                <div className="ba_info">
                  <h1 className="ba_title">{service?.service?.name}</h1>
                </div>
                <div className="ba_reservation">
                  <div style={{ color: "#000", fontWeight: "600" }}>
                    Hourly: ${service?.service?.feePerHour}
                  </div>
                  <span className="ba_timings">
                    Hours of operation: {service?.service?.timings[0]} am -{" "}
                    {service?.service?.timings[1]} pm
                  </span>

                  <div className="ba_date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Date"
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                            setIsAvailable(false);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <TimePicker
                    disableClock={true}
                    value={time}
                    onChange={(value) => {
                      setTime(value);
                      setIsAvailable(false);
                    }}
                  />
                </div>

                <div>
                  <div
                    className="pdc_message"
                    style={{
                      height: "40px",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    onClick={handleMessageSubmit}
                  >
                    <span>
                      Send Message{" "}
                      <AiOutlineMessage
                        size={20}
                        style={{ marginLeft: "5px" }}
                      />
                    </span>
                  </div>
                  <div
                    className="pdc_message"
                    style={{
                      height: "40px",
                      marginTop: "20px",
                      // cursor: "pointer",
                      backgroundColor: "gray",
                    }}
                  >
                    <span>
                      Video Chat{" "}
                      <TbVideoPlus
                        size={24}
                        style={{ marginLeft: "5px" }}
                        disabled={true}
                      />
                    </span>
                  </div>
                </div>

                <Button
                  className="blue_btn_2"
                  style={{ width: "200px" }}
                  onClick={checkAvailability}
                >
                  Check Availability
                </Button>

                <div style={{ marginTop: "20px" }}>
                  <Link
                    to={`/servicer/preview/${service.service?.servicer?._id}`}
                  >
                    <div className="flex items-center">
                      <img
                        src={service.service?.servicer?.picture}
                        className="shop_avatar"
                        alt=""
                      />
                      <div style={{ paddingLeft: "10px" }}>
                        <h3 className="pdi_title">
                          {service.service?.servicer.name}
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>

                {isAvailable && (
                  <div onClick={() => addToCartHandler(data?.id)}>
                    <Button className="pdc_cart_button" onClick={bookNow}>
                      Book Now
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ServiceDetailsInfo service={service} />
      <Footer />
    </>
  );
};

const ServiceDetailsInfo = ({ service }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="sdi">
      <div className="pdi_container">
        <div className="pdi_wrap">
          <h5 className="pdi_title" onClick={() => setActive(1)}>
            Service Details
          </h5>
          {active === 1 ? <div style={{ color: "red" }} /> : null}
        </div>
        <div className="pdi_title">
          <h5 className={"pdi_title"} onClick={() => setActive(2)}>
            Service Reviews
          </h5>
          {active === 2 ? <div /> : null}
        </div>
        <div className="pdi_title">
          <h5 className={"pdi_title"} onClick={() => setActive(3)}>
            Servicer Information
          </h5>
          {active === 3 ? <div /> : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <div className="sdi_info_details">
            <Link to={`/servicer/preview/${service.service?.servicer?._id}`}>
              <img
                src={service.service?.servicer?.picture}
                alt=""
                className="sdi_info_image"
              />
            </Link>

            <span>{service.service?.name}</span>
            <div className="sdi_experience">
              <h5>Experience</h5>
              <span>{service.service?.experience} experience</span>
            </div>
            <div className="sdi_description">
              <h5 style={{ marginBottom: "10px" }}>Service Description</h5>
              {service?.service?.description}
            </div>
          </div>
        </>
      ) : null}

      {active === 2 ? (
        <div className="pdi_review">
          {service.service &&
            service.service.reviews?.map((item, index) => (
              <div style={{ width: "100%" }}>{item.comment}</div>
            ))}
          {service.service && service.service.reviews?.length === 0 && (
            <h5>This products has no reviews yet.</h5>
          )}
        </div>
      ) : null}

      {active === 3 && (
        <div className="pdi_seller_info">
          <div>
            <Link to={`/servicer/preview/${service.service.servicer?._id}`}>
              <div className="flex items-center">
                <img
                  src={service.service.servicer?.picture}
                  className="shop_avatar"
                  alt=""
                />
                <div style={{ paddingLeft: "10px" }}>
                  <h3 className="pdi_title">{service.service.name}</h3>
                  <h5 className="pdi_rating">
                    ({service.service.ratings}) Ratings
                  </h5>
                </div>
              </div>
            </Link>
            <p style={{ paddingTop: "10px" }}>{service.service.description}</p>
          </div>
          <div>
            <div>
              <h5 style={{ fontWeight: "600", paddingTop: "5px" }}>
                Joined on:{" "}
                <span className="font-[500]">
                  {service.service.createdAt.slice(0, 10)}
                </span>
              </h5>
              <h5 style={{ fontWeight: "600", paddingTop: "5px" }}>
                Total Products:{" "}
                <span style={{ fontWeight: "500" }}>
                  {/* {products && products?.length} */}
                </span>
              </h5>
              <h5 style={{ fontWeight: "600", paddingTop: "5px" }}>
                Total Reviews: <span style={{ fontWeight: "500" }}>324</span>
              </h5>
              <Link to="/">
                <div>
                  <Link to={`/service/preview/${service.servicer?._id}`}>
                    <h4 style={{ color: "blue" }}>Visit Shop</h4>
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookAppointmentPage;
