import React from "react";
import { Link } from "react-router-dom";

import "./banner.css";

const HomeBanner = () => {
  return (
    <div className="home_page_banner">
      <div className="hpb_info">
        <span className="hpb_title">Truly Remembered</span>
        <span className="hpb_text">
          Create the perfect custom gift basket, for any occasion.
        </span>
      </div>

      <div className="hpb_button_wrap">
        {/* <Link to="/trulyremembered"> */}
        <Link to="/shop/preview/65b2b61406fb1c1c4f7d21e2">
          <button className="black_button">Order now</button>
        </Link>
      </div>

      <div className="hpb_image_wrap">
        <div className="hpb_small_image_container">
          <img
            src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151324/assets/baby_pics_tnt59n.png"
            alt=""
            className="hpb_image_1"
            loading="lazy"
          />
          <span className="hpb_image_plus">+</span>
          <img
            src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151330/assets/lrg_slate_oval_baby_touu2k.png"
            alt=""
            className="hpb_image_2"
            loading="lazy"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151321/assets/Baby_basket_homepage_erb8o5.png"
            alt=""
            className="hpb_main_image"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
