// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Women",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1707662432/freestocks-VFrcRtEQKL8-unsplash_haphwj.jpg",
    featured: true,
  },
  {
    id: 2,
    title: "Men",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1707662363/andrew-neel-HqtYwlY9dxs-unsplash_dbfn4t.jpg",
    featured: true,
  },
  {
    id: 3,
    title: "Children",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1706195249/testtest/profile_pictures/avjbogg1obb7yqu0dv3n.jpg",
    featured: true,
  },
  {
    id: 4,
    title: "Pets",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1706193444/testtest/profile_pictures/js3d6kpqqkskdfk6cxxg.jpg",
    featured: true,
  },
  {
    id: 5,
    title: "Auto",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1707057600/assets/mustang-2338377_1280_1_ep9xfk.jpg",
    featured: true,
  },
  {
    id: 6,
    title: "Electronics",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1707662510/mohammadreza-alidoost-0rUp9vgyEYo-unsplash_s0bmqz.jpg",
    featured: true,
    subCategory: "computers",
  },
];

export const homeCategoryData = [
  {
    id: 1,
    title: "Who to schedule",
    subTitle: "See all services",
    images: [
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752372/pexels-photo-1148998_nahdgn.jpg",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1692554164/service/alxyienmqxqtqogvyo79.jpg",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752372/photo-1589985494639-69e60c82cab2_icm6wc.avif",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752372/photo-1502877338535-766e1452684a_zmzpng.avif",
      },
    ],
    url: "services",
    text: ["Babysitter", "Beauty Salon", "Hair Stylist", "Transportation"],
  },
  {
    id: 2,
    title: "What to wear",
    subTitle: "See all products",

    images: [
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752761/women_hfstbz.avif",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752759/men_wrvqil.avif",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752759/baby-girl-1633940_1280_1_huksep.jpg",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752760/pets_qeufnw.avif",
      },
    ],
    url: "products",
    text: ["Women", "Men", "Children", "Pet's"],
  },
  {
    id: 3,
    title: "Where to eat",
    subTitle: "See all eateries",

    images: [
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752966/dine-out_nfr94v.avif",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752967/food-truck_hbkstu.avif",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752967/kitchen_r5gxt4.avif",
      },
      {
        image_Url:
          "https://res.cloudinary.com/dcinefb4x/image/upload/v1708752968/recipes_ytaq4s.avif",
      },
    ],
    url: "eateries",
    text: ["Take out", "Food trucks", "Kitchens", "Home (recipes)"],
  },
  // {
  //   id: 4,
  //   title: "Share...",
  //   subTitle: "Share the experience",

  //   images: [
  //     {
  //       image_Url:
  //         "https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838",
  //     },
  //   ],
  //   url: "profile",
  // },
];

export const trData = [
  {
    id: 1,
    title: "Love",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1705333049/assets/hugs-and-love-basket_f7zip8.png",
    featured: true,
    subCategory: "computers",
  },
  {
    id: 2,
    title: "His/Hers",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1705336110/assets/spa_day_sbk4fo.png",
    featured: true,
  },
  {
    id: 3,
    title: "Baby/Kids",
    subTitle: "",
    image_Url:
      "https://res.cloudinary.com/dcinefb4x/image/upload/v1705336968/assets/babygirl_basket_o8vaji.png",
    featured: true,
  },
  {
    id: 4,
    title: "Because",
    subTitle: "",
    image_Url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBQPQMVNRd6TtDkGs2dCri0Y-rxKkFOiEWw&usqp=CAU",
    featured: true,
  },
  {
    id: 5,
    title: "Corporate",
    subTitle: "",
    image_Url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBQPQMVNRd6TtDkGs2dCri0Y-rxKkFOiEWw&usqp=CAU",
    featured: true,
  },
  {
    id: 6,
    title: "Pets",
    subTitle: "",
    image_Url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBQPQMVNRd6TtDkGs2dCri0Y-rxKkFOiEWw&usqp=CAU",
    featured: true,
  },
];
