import React from "react";
import AmLabsHero from "../../components/amlabs/AmLabsHero";

import "./style.css";

const AmLabsPage = () => {
  return (
    <div className="amlabs_page">
      <AmLabsHero />
    </div>
  );
};

export default AmLabsPage;
