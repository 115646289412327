import "./truly-remembered.css";

const TrHero = () => {
  return (
    <div className="tr_hero">
      <h1 className="tr_hero_title">
        Thinking of <span>Everyone</span>
      </h1>
      <div className="tr_hero_name">Truly Remembered</div>
      <div className="hero_wrap">
        <div className="tr_hero_image_small"></div>
        {/* <img
          src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705297549/assets/marriage-slate-rev-2_lxmdv4.png"
          alt=""
          className="tr_hero_image_small"
        /> */}
        <p>
          Whether it's a celebration of love, a corporate gesture, or a
          thoughtful thank-you, customize your gift to come in a variety of
          themes. From "Timeless Romance" to "Executive Elegance," each theme is
          designed to evoke a specific emotion and memory, making your
          customized gift truly stand out.
        </p>
      </div>
      <img
        src="https://res.cloudinary.com/dcinefb4x/image/upload/v1705151321/assets/Baby_basket_homepage_erb8o5.png"
        alt=""
        className="tr_hero_image"
      />
      {/* <button className="black_button tr_hero_button">Shop now</button> */}
    </div>
  );
};

export default TrHero;
