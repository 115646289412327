import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import DotLoader from "react-spinners/DotLoader";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import RegisterInput from "../inputs/registerInput";
import { RxAvatar } from "react-icons/rx";
import { axiosInstance } from "../../functions/axiosInstance";

export default function ShopRegisterForm({ setVisible }) {
  const { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const shopInfos = {
    name: "",
    address: "",
    phoneNumber: "",
    zipCode: "",
    email: "",
    password: "",
    description: "",
    userId: user.id,
    picture: "",
  };

  const [shop, setShop] = useState(shopInfos);

  const {
    name,
    address,
    phoneNumber,
    description,
    zipCode,
    email,
    password,
    userId,
    // picture,
  } = shop;

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setShop({ ...shop, [name]: value });
  };

  const registerValidation = Yup.object({
    name: Yup.string()
      .required("What's your shops name ?")
      .min(2, "Shop name must be between 2 and 16 characters.")
      .max(16, "Shop name must be between 2 and 16 characters.")
      .matches(
        /^[aA-zZ\s]+$/,
        "Numbers and special characters are not allowed."
      ),
    email: Yup.string().required(
      "You'll need this when you log in and if you ever need to reset your password."
    ),
    address: Yup.string().required(
      "You'll need this to ship and receive products."
    ),
    zipCode: Yup.string().required(
      "You'll need this to ship and receive products."
    ),
    phoneNumber: Yup.number().required("So we can contact you."),
    password: Yup.string()
      .required(
        "Enter a combination of at least six numbers,letters and punctuation marks(such as ! and &)."
      )
      .min(6, "Password must be atleast 6 characters.")
      .max(36, "Password can't be more than 36 characters"),
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const registerSubmit = async () => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/shop/registerShop`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          email,
          password,
          description,
          userId,
        }
      );
      setError("");
      setSuccess(data.message);
      const { message, ...rest } = data;
      setTimeout(() => {
        dispatch({ type: "SELLER_LOGIN", payload: rest });
        Cookies.set("seller", JSON.stringify(rest));
        navigate("/shop-dashboard");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setSuccess("");
      setError(error.response.data.message);
    }
  };

  return (
    <div className="blur">
      <div className="register">
        <div className="register_header">
          <i className="exit_icon" onClick={() => setVisible(false)}></i>
          <span>Create a Shop</span>
          <span>Just takes a second.</span>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            name,
            address,
            phoneNumber,
            zipCode,
            email,
            password,
            description,
          }}
          validationSchema={registerValidation}
          onSubmit={() => {
            registerSubmit();
          }}
        >
          {(formik) => (
            <Form className="register_form">
              <div className="reg_line">
                <RegisterInput
                  type="text"
                  placeholder="Shop name"
                  name="name"
                  onChange={handleRegisterChange}
                />
              </div>
              <div className="reg_line">
                <RegisterInput
                  type="text"
                  placeholder="Shop description"
                  name="description"
                  onChange={handleRegisterChange}
                />
              </div>

              <RegisterInput
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleRegisterChange}
              />
              <RegisterInput
                type="text"
                placeholder="Zip code"
                name="zipCode"
                onChange={handleRegisterChange}
              />

              <RegisterInput
                type="number"
                placeholder="Phone number"
                name="phoneNumber"
                onChange={handleRegisterChange}
              />
              <div className="reg_line">
                <RegisterInput
                  type="text"
                  placeholder="Email address"
                  name="email"
                  onChange={handleRegisterChange}
                />
              </div>

              {/* shop_image */}

              <div className="reg_line">
                <RegisterInput
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleRegisterChange}
                />
              </div>

              <div className="reg_infos">
                By clicking Sign Up, you are agreeing to our{" "}
                <span>Terms & Conditions, Cookie &nbsp;</span>
                and <span>Data Policy.</span>
              </div>
              <div className="reg_btn_wrapper">
                <button className="blue_btn open_signup">Shop Sign Up</button>
              </div>
              <DotLoader color="#5e97b7" loading={loading} size={30} />
              {error && <div className="error_text">{error}</div>}
              {success && <div className="success_text">{success}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
