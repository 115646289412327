export const heroSlide = [
  {
    id: 1,
    url: "https://res.cloudinary.com/dcinefb4x/image/upload/v1717943143/Designer_rev1_cclnha.png",
    name: "designers",
  },
  {
    id: 2,
    url: "https://res.cloudinary.com/dcinefb4x/image/upload/v1717947265/Products_rev3_zzckdt.png",
    name: "products",
  },
  {
    id: 3,
    url: "https://res.cloudinary.com/dcinefb4x/image/upload/v1717257188/Bookings_rev3_pir6jm.png",
    name: "services",
  },
];
