import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";

import { removeFromWishlist } from "../../store/actions/wishlistActions";
import { addToCart } from "../../store/actions/cartActions";

import "./wishlist.css";

const Wishlist = ({ setOpenCart, setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);

  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addToCart(newData));
    setOpenWishlist(false);
  };

  return (
    <>
      <div className="wishlist_container">
        {wishlist && wishlist.length === 0 ? (
          <div className="wishlist_header">
            <div className="wishlist_close_icon">
              <RxCross1
                size={25}
                onClick={() => setOpenWishlist(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <h5>Wishlist Items is empty!</h5>
          </div>
        ) : (
          <>
            <div className="wishlist_header">
              <div className="wishlist_close_icon">
                <RxCross1
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenWishlist(false)}
                />
              </div>
              {/* Item length */}
              <div className="">
                <AiOutlineHeart size={25} />
                <h5 className="">{wishlist && wishlist.length} items</h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="wishlist_single_container">
                {wishlist &&
                  wishlist.map((i, index) => (
                    <WishlistSingle
                      key={index}
                      data={i}
                      addToCartHandler={addToCartHandler}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const WishlistSingle = ({
  data,
  removeFromWishlistHandler,
  addToCartHandler,
}) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  return (
    <div className="wishlist_single">
      <div className="wishlist_single_wrap">
        <div className="wishlist_single_left">
          <RxCross1
            style={{ cursor: "pointer" }}
            onClick={() => removeFromWishlistHandler(data)}
          />
          <img
            src={`${data?.images[0].url}`}
            alt=""
            className="wishlist_image"
          />
        </div>
        <div className="wishlist_single_right">
          <div className="wishlist_text">
            <h3 className="cart_name">
              {" "}
              {data?.name?.length > 40
                ? data?.name.slice(0, 40) + "..."
                : data?.name}
            </h3>

            <h4 className="cart_price">${data.discountPrice}</h4>
            <div>
              <BsCartPlus
                size={20}
                title={"Add to cart"}
                onClick={() => addToCartHandler(data)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
