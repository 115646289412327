import { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { HashLoader } from "react-spinners";

import { profileReducer } from "../../functions/reducers";
import Header from "../../components/header";
import Cover from "./Cover";
import ProfilePictureInfos from "./ProfilePictureInfos";
import ProfileMenu from "./ProfileMenu";
import CreatePost from "../../components/createPost";
import GridPosts from "./GridPosts";
import Post from "../../components/post";
import Photos from "./Photos";
import Intro from "../../components/intro";
import { useMediaQuery } from "react-responsive";
import CreatePostPopup from "../../components/createPostPopup";
import { SetLoader } from "../../store/reducers/loadersSlice";
import { axiosInstance } from "../../functions/axiosInstance";

import "./style.css";

export default function Profile({ getAllPosts, page }) {
  const { user } = useSelector((state) => ({ ...state }));
  const { allProducts } = useSelector((state) => state.products);

  const [userProducts, setUserProducts] = useState([]);

  const [visible, setVisible] = useState(false);
  const [photos, setPhotos] = useState({});
  const [filteredProduct, setFilteredProduct] = useState([]);
  const { username } = useParams();

  var userName = username === undefined ? user.username : username;

  const navigate = useNavigate();

  const color = "#fff";

  const [{ loading, error, profile }, dispatch] = useReducer(profileReducer, {
    loading: false,
    profile: {},
    error: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    console.log(hash);
  }, []);

  useEffect(() => {
    getProfile();
  }, [userName]);

  useEffect(() => {
    setOthername(profile?.details?.otherName);
  }, [profile]);

  var visitor = userName === user.username ? false : true;
  const [othername, setOthername] = useState();
  const path = `${userName}/*`;
  const max = 30;
  const sort = "desc";

  const getProfile = async () => {
    try {
      dispatch({
        type: "PROFILE_REQUEST",
      });
      const { data } = await axiosInstance.get(`/user/getProfile/${userName}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (data.ok === false) {
        navigate("/profile");
      } else {
        try {
          const images = await axiosInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}/upload/listImages`,
            { path, sort, max },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          setPhotos(images.data);
        } catch (error) {
          console.log(error);
        }
        dispatch({
          type: "PROFILE_SUCCESS",
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: "PROFILE_ERROR",
        payload: error?.response?.data.message,
      });
    }
  };

  // Scroll Animation
  const profileTop = useRef(null);
  const leftSide = useRef(null);
  const rightSide = useRef(null);
  const [height, setHeight] = useState();
  const [leftHeight, setLeftHeight] = useState();
  const [rightHeight, setRightHeight] = useState();
  const [scrollHeight, setScrollHeight] = useState();

  useEffect(() => {
    setHeight(profileTop.current.clientHeight + 300);
    // setLeftHeight(leftSide.current.clientHeight);
    // setRightHeight(rightSide.current.clientHeight);
    window.addEventListener("scroll", getScroll, { passive: true });
    return () => {
      window.addEventListener("scroll", getScroll, { passive: true });
    };
  }, [loading, scrollHeight]);

  const check = useMediaQuery({
    query: "(min-width:901px)",
  });
  const getScroll = () => {
    setScrollHeight(window.pageYOffset);
  };

  const filteredProducts = () => {
    const filteredProducts =
      allProducts &&
      allProducts?.filter((product) => product.user?.userId === user.userName);
    setUserProducts(filteredProducts);
  };

  useEffect(() => {
    filteredProducts();
  }, []);

  return (
    <div className="profile">
      {visible && (
        <CreatePostPopup
          user={user}
          setVisible={setVisible}
          posts={profile?.posts}
          dispatch={dispatch}
          profile
        />
      )}
      <Header
        page="profile"
        color={color}
        getAllPosts={getAllPosts}
        className={page === "profile" ? "header_bg" : ""}
      />
      <div>
        {/* Profile Top */}
        <div className="profile_top" ref={profileTop}>
          <div className="profile_container">
            {loading ? (
              <>
                <div className="profile_cover">
                  <Skeleton
                    height="347px"
                    containerClassName="avatar-skeleton"
                    style={{ borderRadius: "8px" }}
                  />
                </div>
                <div
                  className="profile_img_wrap"
                  style={{
                    marginBottom: "-3rem",
                    transform: "translateY(-8px)",
                  }}
                >
                  <div className="profile_w_left">
                    <Skeleton
                      circle
                      height="180px"
                      width="180px"
                      containerClassName="avatar-skeleton"
                      style={{ transform: "translateY(-3.3rem)" }}
                    />
                    <div className="profile_w_col">
                      <div className="profile_name">
                        <Skeleton
                          height="35px"
                          width="200px"
                          containerClassName="avatar-skeleton"
                        />
                        <Skeleton
                          height="30px"
                          width="100px"
                          containerClassName="avatar-skeleton"
                          style={{ transform: "translateY(2.5px)" }}
                        />
                      </div>
                      <div className="profile_friend_count">
                        <Skeleton
                          height="20px"
                          width="90px"
                          containerClassName="avatar-skeleton"
                          style={{ marginTop: "5px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`friendship ${!visitor && "fix"}`}>
                    <Skeleton
                      height="36px"
                      width={120}
                      containerClassName="avatar-skeleton"
                    />
                    <div className="flex">
                      <Skeleton
                        height="36px"
                        width={120}
                        containerClassName="avatar-skeleton"
                      />
                      {visitor && (
                        <Skeleton
                          height="36px"
                          width={120}
                          containerClassName="avatar-skeleton"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Cover
                  cover={profile.cover}
                  visitor={visitor}
                  photos={photos.resources}
                />
                <ProfilePictureInfos
                  profile={profile}
                  visitor={visitor}
                  photos={photos.resources}
                  othername={othername}
                  loading={loading}
                />
                <Intro
                  detailss={profile.details}
                  visitor={visitor}
                  setOthername={setOthername}
                />
              </>
            )}
          </div>
          <ProfileMenu />
        </div>

        {/* PROFILE BOTTOM */}
        <div className="profile_bottom">
          <div className="profile_container">
            <div className="bottom_container">
              {/* PROFILE MIDDLE */}
              <div className="profile_middle">
                {!visitor && (
                  <CreatePost user={user} profile setVisible={setVisible} />
                )}

                {/* ------ Test ------ */}
                {loading ? (
                  <div className="sekelton_loader">
                    <HashLoader color="#5e97b7" />
                  </div>
                ) : (
                  <div className="posts">
                    {profile.posts && profile.posts.length ? (
                      profile.posts.map((post) => (
                        <Post post={post} user={user} key={post._id} profile />
                      ))
                    ) : (
                      <div className="no_posts">No posts available</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
