import { useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { MdOutlineTrackChanges } from "react-icons/md";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { TbAddressBook } from "react-icons/tb";
import { AiOutlineCreditCard, AiOutlineMessage } from "react-icons/ai";
import { IoBagHandleOutline } from "react-icons/io5";

import { ArrowDown1 } from "../../../svg";

import "./style.css";

export default function LeftFeed({ user, active, setActive }) {
  const navigate = useNavigate();
  const { type } = useParams();

  const [visible, setVisible] = useState(false);

  return (
    <div className="left_home scrollbar">
      <Link to="/profile" className="left_link hover2">
        <img src={user?.picture} alt="" />
        <span>
          {user?.first_name} {user.last_name}
        </span>
      </Link>
      <Outlet />
      <div className="feed_left_wrap">
        {/* info */}
        <div
          className={`mmenu_item hover0 ${type === "active_feed"}`}
          onClick={() => setActive(1)}
        >
          <div className="small_circle">
            <IoBagHandleOutline
              size={24}
              color={active === 1 ? "#5e97b7" : ""}
            />
          </div>
          <span className={active === 1 ? "active_feed" : ""}>Feed</span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>

        {/* order */}
        <div
          className={`mmenu_item hover0 ${type === "active_feed"}`}
          onClick={() => setActive(2)}
        >
          <div className="small_circle">
            <IoBagHandleOutline
              size={24}
              color={active === 2 ? "#5e97b7" : ""}
            />
          </div>
          <span className={active === 2 ? "active_feed" : ""}>Orders</span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>

        {/* refund */}
        <div
          className={`mmenu_item hover0 ${type === "sent" && "active_feed"}`}
          onClick={() => setActive(3)}
        >
          <div className="small_circle">
            <HiOutlineReceiptRefund
              size={24}
              color={active === 3 ? "#5e97b7" : ""}
            />
          </div>
          <span className={active === 3 ? "active_feed" : ""}>Returns</span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>

        {/* messages */}
        <div
          className="mmenu_item hover0"
          onClick={() => setActive(4) || navigate("/inbox")}
        >
          <div className="small_circle ">
            <AiOutlineMessage size={24} color={active === 4 ? "#5e97b7" : ""} />
          </div>

          <span className={active === 4 ? "active_feed" : ""}>Inbox</span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>

        {/* tracking */}
        <div className="mmenu_item hover0" onClick={() => setActive(5)}>
          <div className="small_circle">
            <MdOutlineTrackChanges
              size={24}
              color={active === 5 ? "#5e97b7" : ""}
            />
          </div>

          <span className={active === 5 ? "active_feed" : ""}>Tracking</span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>

        {/* payment */}
        <div
          className={`mmenu_item hover0 ${type === "all" && "active_feed"}`}
          onClick={() => setActive(6)}
        >
          <div className="small_circle">
            <AiOutlineCreditCard
              size={20}
              color={active === 6 ? "#5e97b7" : ""}
            />
          </div>
          <span className={active === 6 ? "active_feed" : ""}>
            Payment info
          </span>
          <div className="rArrow">
            <i className="right_icon"></i>
          </div>
        </div>

        {/* address */}
        <div>
          <div className="mmenu_item hover0" onClick={() => setActive(7)}>
            <div className="small_circle">
              <TbAddressBook size={20} color={active === 7 ? "#5e97b7" : ""} />
            </div>
            <span className={active === 7 ? "active_feed" : ""}>
              Address book
            </span>
            <div className="rArrow">
              <i className="right_icon"></i>
            </div>
          </div>
        </div>
      </div>

      {!visible && (
        <div
          className="left_link hover2"
          onClick={() => {
            setVisible(true);
          }}
        >
          <div className="small_circle">
            <ArrowDown1 />
          </div>
          <span>See more</span>
        </div>
      )}
      <div className="splitter"></div>
      <div className="shortcut">
        <div className="heading">Your Shortcuts</div>
        <div className="edit_shortcut">Edit</div>
      </div>
      <div
        className={`nxxtstore_copyright ${
          visible && "relative_nxxtstore_copyright"
        }`}
      >
        <Link to="/">Terms </Link>
        <span>. </span>
        <Link to="/">Advertising </Link>
        <span>. </span>
        <Link to="/"></Link>Cookies <span>. </span>
        <Link to="/">More </Link>
        <span>. </span> <br />
        Nxxtstore © 2024
      </div>
    </div>
  );
}
