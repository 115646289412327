import React from "react";
import DashboardSideBar from "../../components/shop/layout/DashboardSidebar";
import AllOrders from "../../components/shop/AllOrders";
import Header from "../../components/header";

const ShopAllOrders = () => {
  return (
    <>
      <Header />
      <DashboardSideBar active={2} />
      <AllOrders />
    </>
  );
};

export default ShopAllOrders;
