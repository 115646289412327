import React from "react";
import CanvawwwHero from "../../components/canvawww/CanvawwwHero";

const CanvawwwPage = () => {
  return (
    <div>
      <CanvawwwHero />
    </div>
  );
};

export default CanvawwwPage;
