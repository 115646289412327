import { axiosInstance } from "./axiosInstance";

export const searchService = async (searchTerm, token) => {
  try {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKEND_URL}/service/searchService/${searchTerm}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
