import { useEffect } from "react";
import { useSelector } from "react-redux";
import { HashLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Header from "../../components/header";
import Hero from "../../components/hero/Hero";
import FeaturedProduct from "../../components/featured/FeaturedProduct";
import Footer from "../../components/footer/Footer";
import { getAllProducts } from "../../store/actions/productActions";

import PopularPost from "../../components/popularPost/PopularPost";
import Service from "../../components/service/Service";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";

import HomeBanner from "../../components/banner/HomeBanner";
import HomeCategories from "../../components/categories/HomeCategories";
import HomePageProductBanner from "../../components/banner/HomePageProductBanner";
import Spinner from "../../components/shared/spinner/Spinner";
import ServicePageBanner from "../../components/banner/ServicePageBanner";

import { setLocation } from "../../store/actions/locationActions";

import "./style.css";

export default function Home({ loading, posts }) {
  const { user } = useSelector((state) => ({ ...state }));

  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "home" });
  }, []);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    const locationData = async (position) => {
      const userLocation = Cookies.get("userLocation");

      if (userLocation) {
        const { latitude, longitude } = JSON.parse(userLocation);

        const lat = latitude;
        const lng = longitude;
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    locationData();
  }, []);

  // useEffect(() => {
  //   if (navigator?.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       if (position) {
  //         const lat = position.coords?.latitude;
  //         const lng = position.coords?.longitude;
  //         dispatch(setLocation(lat, lng));
  //       }
  //     });
  //   }
  // }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Header />
          <Hero loading={loading} />
          <HomeCategories />
          <FeaturedProduct />
          <HomeBanner />
          <Service />
          <ServicePageBanner page="home" />
          <FeaturedProduct />
          <HomePageProductBanner />
          <PopularPost user={user} posts={posts} loading={loading} />
          <Footer />
        </>
      )}
    </>
  );
}
