import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { serviceData } from "../../data/staticData";
import ServiceCard from "./serviceCard/ServiceCard";
import { getServicesOfService } from "../../store/actions/serviceActions";

import "./service.css";

const ServiceProfileData = ({ isOwner, data, page }) => {
  const { allServices } = useSelector((state) => state.services);

  const dispatch = useDispatch();
  const { id } = useParams();

  const trulyremembered = data?._id === "65b2b61406fb1c1c4f7d21e2";

  useEffect(() => {
    dispatch(getServicesOfService(id));
  }, [dispatch]);

  const [active, setActive] = useState(1);

  return (
    <>
      {trulyremembered ? (
        <div className="tr_product_card_container">
          {allServices &&
            allServices.map((i, index) => (
              <ServiceCard
                data={i}
                key={index}
                isShop={true}
                trulyremembered={trulyremembered}
                page={page}
              />
            ))}
        </div>
      ) : (
        <div className="service_profile_data">
          <div
            className={
              page === "profile" ? "spd_container_profile" : "spd_container"
            }
          >
            {/* <div className="spd_top"> */}
            <div
              className={
                page === "profile" ? "shop_profile_profile" : "spd_top"
              }
            >
              {/* <div className="spd_tab" onClick={() => setActive(1)}> */}
              <div
                className={page === "profile" ? "spd_tab_profile" : "spd_tab"}
                onClick={() => setActive(1)}
              >
                <h5
                  className={`spd_text ${
                    active === 1 ? "shop_profile_data_active" : ""
                  }`}
                >
                  Services
                </h5>
              </div>
              <div
                className={page === "profile" ? "spd_tab_profile" : "spd_tab"}
                onClick={() => setActive(2)}
              >
                <h5
                  className={`spd_text ${
                    active === 2 ? "shop_profile_data_active" : ""
                  }`}
                >
                  Specials
                </h5>
              </div>

              <div
                className={page === "profile" ? "spd_tab_profile" : "spd_tab"}
                onClick={() => setActive(3)}
              >
                <h5
                  className={`spd_text ${
                    active === 3 ? "shop_profile_data_active" : ""
                  }`}
                >
                  Reviews
                </h5>
              </div>
            </div>

            <div>
              {isOwner && (
                <div>
                  <Link to="/dashboard">
                    <div className="black_button">
                      <span>Dashboard</span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <br />
          <div className="spd_bottom_container">
            {allServices &&
              allServices.map((i, index) => (
                <ServiceCard
                  data={i}
                  key={index}
                  isShop={true}
                  trulyremembered={trulyremembered}
                />
              ))}
          </div>
          {serviceData && serviceData.length === 0 && (
            <h5 className="w-full text-center py-5 text-[18px]">
              No Products for this shop!
            </h5>
          )}
        </div>
      )}
    </>
  );
};

export default ServiceProfileData;
