import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ProductCard from "../product/productCard/ProductCard";

import "./featured.css";

const FeaturedProduct = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.filter((a) => a.featured === true);
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allProducts]);

  return (
    <section>
      <div className="featured_product">
        <h1 className="section_header">Featured Products</h1>
        <div className="fp_container">
          {data?.map((i, index) => (
            <ProductCard data={i} key={index} isShop={true} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProduct;
