import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const notificationReducer = createReducer(initialState, {
  notificationCreateRequest: (state) => {
    state.isLoading = true;
  },
  notificationCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.notification = action.payload;
    state.success = true;
  },
  notificationCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Shop all products
  // getAllProductsShopRequest: (state) => {
  //   state.isLoading = true;
  // },
  // getAllProductsShopSuccess: (state, action) => {
  //   state.isLoading = false;
  //   state.products = action.payload;
  // },
  // getAllProductsShopFailed: (state, action) => {
  //   state.isLoading = false;
  //   state.error = action.payload;
  //   state.products = false;
  // },

  // User all notificationss
  getAllNotificationsUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllNotificationsUserSuccess: (state, action) => {
    state.isLoading = false;
    state.notification = action.payload;
  },
  getAllNotificationsUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.products = false;
  },

  // delete product of a shop
  deleteNotificationRequest: (state) => {
    state.isLoading = true;
  },
  deleteNotificationSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteNotificationFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Get all products
  getAllNotificationsRequest: (state) => {
    state.isLoading = true;
  },
  getAllNotificationsSuccess: (state, action) => {
    state.isLoading = false;
    state.allNotifications = action.payload;
  },
  getAllNotificationsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    // state.products = false;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
