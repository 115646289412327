import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProductInput from "../inputs/productInputs/ProductInput";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";

import { createService } from "../../store/actions/serviceActions";
import { categoriesData, serviceData } from "../../data/staticData";

import "react-clock/dist/Clock.css";
// type ValuePiece = Date | string | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

const CreateService = ({ servicer }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const { success, error } = useSelector((state) => state.services);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  // const [value, onChange] = useState<Value>(['10:00', '11:00']);
  // const [value, onChange] = useState(["07:00", "07:00"]);
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [experience, setExperience] = useState("");
  const [feePerHour, setFeePerHour] = useState(0);
  const [feePerService, setFeePerService] = useState(0);
  const [timings, setTimings] = useState(["07:00", "07:00"]);
  const [occupants, setOccupants] = useState(1);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Service created successfully!");
      navigate("/service-dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("specialization", specialization);
    newForm.append("experience", experience);
    newForm.append("feePerHour", feePerHour);
    newForm.append("feePerService", feePerService);
    newForm.append("userId", String(user.id));
    newForm.append("servicerId", String(servicer.id));
    newForm.append("servicer", servicer);

    newForm.append("timings", timings);
    newForm.append("occupants", occupants);
    dispatch(
      createService(
        {
          name,
          description,
          category,
          tags,
          specialization,
          experience,
          feePerHour,
          feePerService,
          userId: user.id,
          servicerId: servicer.id,
          images,
          timings,
          occupants,
        },
        {
          headers: {
            Authorization: `Bearer ${servicer.token}`,
          },
        }
      )
    );
  };

  return (
    <div className="create_product">
      <div className="createProduct">
        <div className="createProduct_body">
          <div className="createProduct_header">
            Create a service, {servicer?.name}
            <div className="create_splitter"></div>
          </div>

          {/* create product form */}
          <form onSubmit={handleSubmit}>
            <br />
            <div className="create_product_input_wrap">
              <label>
                Name{" "}
                <span style={{ color: "red" }} className="text-red-500">
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                className="create_product_input"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your product name..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Description <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                value={description}
                style={{ height: "100px" }}
                className="create_product_input"
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter your product description..."
              ></textarea>
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Speciality</label>
              <input
                type="text"
                name="specialization"
                value={specialization}
                className="create_product_input"
                onChange={(e) => setSpecialization(e.target.value)}
                placeholder="Enter your specialization..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Experience</label>
              <input
                type="text"
                name="experience"
                value={experience}
                className="create_product_input"
                onChange={(e) => setExperience(e.target.value)}
                placeholder="Enter your experience..."
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Fee per hour</label>
              <input
                type="number"
                name="feePerHour"
                value={feePerHour}
                className="create_product_input"
                onChange={(e) => setFeePerHour(e.target.value)}
                placeholder="Set hourly rate"
              />
            </div>
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Fee per service </label>
              <input
                type="number"
                name="feePerService"
                value={feePerService}
                className="create_product_input"
                onChange={(e) => setFeePerService(e.target.value)}
                placeholder="Set service rate"
              />
            </div>
            <br />
            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Occupants allowed </label>
              <input
                type="number"
                name="occupants"
                value={occupants}
                className="create_product_input"
                onChange={(e) => setOccupants(e.target.value)}
                placeholder="Set number of occupants allowed"
              />
            </div>
            <br />
            <div
              className="create_product_input_wrap"
              style={{ marginBottom: "16px" }}
            >
              <label style={{ paddingBottom: "8px" }}>
                Hours of operation{" "}
              </label>

              <TimeRangePicker
                name="timings"
                onChange={(e) => setTimings(e.target.value)}
                value={timings}
                className="timings"
              />
            </div>

            <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>
                Category <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="create_product_input"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Choose a category">Choose a category</option>
                {serviceData &&
                  serviceData.map((i) => (
                    <option value={i.title} key={i.title}>
                      {i.title}
                    </option>
                  ))}
              </select>
            </div>
            <br />
            {/* <div className="create_product_input_wrap">
              <label style={{ paddingBottom: "8px" }}>Original Price</label>
              <input
                type="number"
                name="price"
                value={originalPrice}
                className="create_product_input"
                onChange={(e) => setOriginalPrice(e.target.value)}
                placeholder="Enter your product price..."
              />
            </div> */}
            <br />
            <div>
              <label style={{ paddingBottom: "8px" }}>
                Upload Images (50kb max) <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name=""
                id="upload"
                className="hidden"
                multiple
                onChange={handleImageChange}
              />
              <div className="create_product_add_image">
                <label htmlFor="upload">
                  <AiOutlinePlusCircle
                    size={30}
                    style={{ marginTop: "10px" }}
                    color="#5e97b7"
                  />
                </label>
                {images &&
                  images.map((i) => (
                    <img
                      src={i}
                      key={i}
                      alt=""
                      className="create_product_image"
                      style={{ width: "120px", height: "120px" }}
                    />
                  ))}
              </div>
              <br />{" "}
              <div>
                <input type="submit" value="Create" className="blue_btn" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateService;
