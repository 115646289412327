import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

import Header from "../../components/header";
import Spinner from "../../components/shared/spinner/Spinner";
import TrulyRememberedPage from "../trulyremembered/TrulyRememberedPage";
import ServicerCover from "./ServicerCover";
import ServicerProfilePictureInfos from "./ServicerProfilePictureInfos";
import ServiceProfileData from "../../components/service/ServiceProfileData";

const ServicerProfilePage = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { servicer } = useSelector((state) => ({ ...state }));

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getServicerInfo/${id}`)
      .then((res) => {
        setData(res.data?.servicer);
        isLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const profileTop = useRef(null);

  var visitor = data?._id === servicer?.id ? false : true;

  const canvawww = data?._id === "65c7a9b11e2c19050991310f";

  return (
    <>
      <Header />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <>
            <div className="shop_profile">
              <div className="shop_profile_top" ref={profileTop}>
                <ServicerCover
                  data={data}
                  cover={data?.coverPicture}
                  visitor={visitor}
                />
                <ServicerProfilePictureInfos
                  profile={data}
                  visitor={visitor}
                  photos={photos.resources}
                  imageUrl={imageUrl}
                  loading={isLoading}
                  data={data}
                />
              </div>
              <div className="shop_profile_container">
                <ServiceProfileData isOwner={false} data={data} />
              </div>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default ServicerProfilePage;
