import React from "react";
import Header from "../../components/header";
import CheckoutSteps from "../../components/checkout/CheckoutSteps";
import Checkout from "../../components/checkout/Checkout";
import Footer from "../../components/footer/Footer";

const CheckoutPage = () => {
  return (
    <>
      <Header />
      <div style={{ marginTop: "110px" }}>
        <CheckoutSteps active={1} />
        <Checkout />
        <Footer />
      </div>
    </>
  );
};

export default CheckoutPage;
