import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ProductDetailsCard from "./ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../store/actions/wishlistActions";
import { addToCart } from "../../../store/actions/cartActions";
import Ratings from "../Ratings";

import "./product-card.css";

const ProductCard = ({ data, page, trulyremembered, shops }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);

  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart");
    } else {
      if (data.stock < 1) {
        toast.error(`Sorry limited amount in stock`);
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addToCart(cartData));
        toast.success("Item added to cart successfully");
      }
    }
  };

  const trProduct = data.shop._id === "65b2b61406fb1c1c4f7d21e2";

  return (
    <>
      {page === "trulyremembered" && trProduct ? (
        <div className="tr_product_card">
          <Link to={`/product/${data._id}`}>
            <img
              src={data?.images[0]?.url}
              alt=""
              className="tr_product_card_image"
            />
          </Link>

          <div className="tr_product_info_wrap">
            <Link to={`/product/${data._id}`}>
              <h3 className="tr_product_name_slice">
                {" "}
                {data?.name?.length > 40
                  ? data?.name.slice(0, 40) + "..."
                  : data?.name}
              </h3>

              {/* <p className="product_description">{data.description}</p> */}

              <div className="tr_product_info">
                <div style={{ display: "flex" }}>
                  <h5 className="">
                    {data?.price === 0 ? data?.price : data?.discount_price}$
                    {data?.originalPrice === 0
                      ? data?.originalPrice
                      : data?.discountPrice}
                  </h5>
                  <h4 className="product_price">
                    {/* {data?.discountPrice ? data?.originalPrice + " $" : null} */}
                    {data?.discountPrice ? data?.originalPrice + " $" : null}
                  </h4>
                </div>
              </div>
            </Link>
          </div>

          {/* side options */}
          <div>
            {click ? (
              <AiFillHeart
                size={22}
                className="product_heart_icon"
                onClick={() => removeFromWishlistHandler(data)}
                color={click ? "red" : "#333"}
                title="Remove from wishlist"
              />
            ) : (
              <AiOutlineHeart
                size={22}
                className="product_heart_icon"
                onClick={() => addToWishlistHandler(data)}
                color={click ? "red" : "#333"}
                title="Add to wishlist"
              />
            )}
            <AiOutlineEye
              size={22}
              className="product_eye_icon"
              onClick={() => setOpen(!open)}
              color="#333"
              title="Quick view"
            />
            <AiOutlineShoppingCart
              size={22}
              className="product_cart_icon"
              onClick={() => addToCartHandler(data._id)}
              color="#444"
              title="Add to cart"
            />
            {/* open */}
            {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
          </div>
        </div>
      ) : (
        <div className="product_card">
          {/* {page === "trulyremembered"} */}
          <div className="product_name"></div>
          <Link to={`/product/${data._id}`}>
            <img src={data?.images[0]?.url} alt="" className="product_image" />
          </Link>

          <div style={{ padding: "3px" }}>
            <Link to={`/shop/preview/${data?.shop._id}`}>
              <h5 className="product_shop_name">{data.shop?.name}</h5>
            </Link>

            <Link to={`/product/${data._id}`}>
              <h3 className="product_name_slice">
                {" "}
                {data?.name?.length > 40
                  ? data?.name.slice(0, 40) + "..."
                  : data?.name}
              </h3>
              <div className="product_info_wrap">
                <div className="product_rating">
                  <Ratings rating={data?.ratings} />
                  <span className="product_sold">{data?.sold_out} sold</span>
                </div>

                <div className="product_info">
                  <div style={{ display: "flex" }}>
                    <h4 className="">
                      $
                      {data.originalPrice === 0
                        ? data.originalPrice
                        : data.discountPrice}
                    </h4>
                    <h5 className="product_price">
                      {data.originalPrice ? " $" + data.originalPrice : null}{" "}
                    </h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* side options */}
          <div>
            {click ? (
              <AiFillHeart
                size={22}
                className="product_heart_icon"
                onClick={() => removeFromWishlistHandler(data)}
                color={click ? "red" : "#333"}
                title="Remove from wishlist"
              />
            ) : (
              <AiOutlineHeart
                size={22}
                className="product_heart_icon"
                onClick={() => addToWishlistHandler(data)}
                color={click ? "red" : "#333"}
                title="Add to wishlist"
              />
            )}
            <AiOutlineEye
              size={22}
              className="product_eye_icon"
              onClick={() => setOpen(!open)}
              color="#333"
              title="Quick view"
            />
            <AiOutlineShoppingCart
              size={22}
              className="product_cart_icon"
              onClick={() => addToCartHandler(data._id)}
              color="#444"
              title="Add to cart"
            />
            {/* open */}
            {open ? (
              <ProductDetailsCard setOpen={setOpen} data={data} shops={shops} />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;
