import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import Header from "../../components/header";
import ProductCard from "../../components/product/productCard/ProductCard";
import Categories from "../../components/categories/Categories";
import ProductHero from "../../components/product/ProductHero";
import FeaturedProduct from "../../components/featured/FeaturedProduct";
import { getAllProducts } from "../../store/actions/productActions";
import BestDeals from "../../components/bestDeals/BestDeals";
import ProductPageBanner from "../../components/banner/ProductPageBanner";
import { shopsDistances, shopsWithin } from "../../functions/shop";
import Spinner from "../../components/shared/spinner/Spinner";
import HomeBanner from "../../components/banner/HomeBanner";

import Cookies from "js-cookie";

import "./style.css";

const ProductsPage = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({ status: "approved" });

  // const coords = useSelector((state) => state.root.userData);

  // console.log(coords);

  const [shops, setShops] = useState(null);
  const [shopsDistance, setShopsDistance] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);

  useEffect(
    (i) => {
      if (categoryData === null) {
        const d = allProducts;
        setData(d);
      } else {
        const d =
          allProducts && allProducts.filter((i) => i.category === categoryData);
        setData(d);
      }
      // window.scrollTo(0, 0);
    },
    [allProducts]
  );

  const isLoadingHandler = () => {
    setIsLoading(false);
  };

  useEffect((position) => {
    const shopData = async (position) => {
      const userLocation = Cookies.get("userLocation");

      if (userLocation) {
        const { latitude, longitude } = JSON.parse(userLocation);
        const lat = latitude;
        const lng = longitude;

        const result = await shopsWithin(lat, lng);
        const shopsObject = result?.shops;
        setShops(shopsObject);
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    shopData();
  }, []);

  // useEffect(() => {
  //   const orderData = JSON.parse(localStorage.getItem("latestOrder"));
  //   setOrderData(orderData);
  // }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Header />
          <ProductHero shops={shops} data={data} />
          <div className="product_page">
            <div style={{ marginTop: "100px" }}>
              <Categories />
            </div>
            <div className="product_page_all_products">
              <section>
                <h1 className="section_header">All Products</h1>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {data &&
                    data.map((i, index) => (
                      <ProductCard
                        data={i}
                        key={index}
                        isLoading={isLoading}
                        shops={shops}
                      />
                    ))}
                  {data && data.length === 0 ? (
                    <h1 className="">No products Found!</h1>
                  ) : null}
                </div>
              </section>
            </div>
            <HomeBanner />

            <FeaturedProduct />
            {/* <ProductPageBanner /> */}
            <div className="product_page_best_deals">
              <BestDeals />
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
