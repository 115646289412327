import { axiosInstance } from "./axiosInstance";

// /shopsWithin/:distance/center/:latlng/unit/:unit
export const shopsWithin = async (lat, lng) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/shop/shopsWithin/5/center/${lat},${lng}/unit/mi`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

// /getShopDistances/32.8165166,-117.179884/unit/mi
export const shopsDistances = async (lat, lng) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/shop/getShopDistances/${lat},${lng}/unit/mi`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateShopProfilePicture = async (url, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/shop/updateShopProfilePicture`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateShopCover = async (url, token) => {
  try {
    const { data } = await axiosInstance.put(
      `${process.env.REACT_APP_BACKEND_URL}/shop/updateShopCover`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};
