import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

import Navbar from "../categoryMenu/Navbar";
import ProductHeroItem from "./ProductHeroItem";
import FeaturedProduct from "../featured/FeaturedProduct";
import ProductFeaturedItem from "./ProductFeaturedItem";

import "./product.css";

const ProductHero = ({ shops, key, data }) => {
  return (
    <div className="product_hero">
      <div className="phc_container">
        <div className="phc_card">
          <span className="phc_title">Local vendors</span>
          <div className="phc_images">
            {shops && (
              <>
                {shops.map((shop, i) => (
                  <div className="phc_wrap_0" key={i}>
                    <ProductHeroItem shop={shop} />
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="hero_used_title">
            <Link to="/products">
              <span className="phc_subTitle">
                Shop all local <IoIosArrowForward className="ml-1" />
              </span>
            </Link>
          </div>
        </div>
        <div className="phc_card">
          <span className="phc_title">Featured products</span>
          <div className="phc_images">
            <ProductFeaturedItem data={data} />
          </div>

          <div className="hero_used_title">
            <Link to="/products">
              <span className="phc_subTitle">
                Shop all nearest <IoIosArrowForward className="ml-1" />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <img
        src="https://res.cloudinary.com/dcinefb4x/image/upload/v1708750391/home-1680800_1280_ehuymh.jpg"
        alt=""
        className="product_hero_image"
      />
      <Navbar />
    </div>
  );
};

export default ProductHero;
