import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const appointmentReducer = createReducer(initialState, {
  appointmentCreateRequest: (state) => {
    state.isLoading = true;
  },
  appointmentCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.appointment = action.payload;
    state.success = true;
  },
  appointmentCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Service all appointments
  getAllAppointmentServiceRequest: (state) => {
    state.isLoading = true;
  },
  getAllAppointmentServiceSuccess: (state, action) => {
    state.isLoading = false;
    state.appointments = action.payload;
  },
  getAllAppointmentServiceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.products = false;
  },

  // User all products
  getAllProductsUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllProductsUserSuccess: (state, action) => {
    state.isLoading = false;
    state.products = action.payload;
  },
  getAllProductsUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.products = false;
  },

  // delete product of a shop
  deleteProductRequest: (state) => {
    state.isLoading = true;
  },
  deleteProductSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteProductFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Get all products
  getAllServicesRequest: (state) => {
    state.isLoading = true;
  },
  getAllServicesSuccess: (state, action) => {
    state.isLoading = false;
    state.allServices = action.payload;
  },
  getAllServicesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    // state.products = false;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
