import { axiosInstance } from "../../functions/axiosInstance";

//create product
//newForm is component createProduct
export const createProduct =
  (
    name,
    description,
    category,
    tags,
    originalPrice,
    discountPrice,
    stock,
    shopId,
    userId,
    images,
    seller,
    user,
    clothingSizes,
    deliveryOptions
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "productCreateRequest",
      });

      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/product/createProduct`,
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId,
        userId,
        images,
        seller,
        user,
        clothingSizes,
        deliveryOptions
      );
      dispatch({
        type: "productCreateSuccess",
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: "productCreateFail",
        payload: error.response?.data.message,
      });
    }
  };
// get all products from shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/product/getShopProducts/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response?.data.message,
    });
  }
};

// get all products from shop
export const getAllUserProducts = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsUserRequest",
    });

    const { data } = await axiosInstance.get(
      // `${process.env.REACT_APP_BACKEND_URL}/getUserProducts/${id}`
      `${process.env.REACT_APP_BACKEND_URL}/product/getUserProducts/${userId}`
    );
    dispatch({
      type: "getAllProductsUserSuccess",
      payload: data.products,
      // payload: data.userProducts,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsUserFailed",
      payload: error.response?.data.message,
    });
  }
};

// Get all products
export const getAllProducts = (id) => async (dispatch) => {
  try {
    dispatch({
      // type: "getAllProducts",
      type: "getAllProductsRequest",
    });

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/product/getAllProducts`
    );
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response?.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch, token) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axiosInstance.delete(
      `${process.env.REACT_APP_BACKEND_URL}/product/deleteShopProduct/${id}`
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};
