export const logout = () => {
  localStorage.clear("user");
  window.location.pathname = "/login";
};

export const shopLogout = () => {
  localStorage.clear("seller");
  window.location.pathname = "/shop-create";
};

export const servicerLogout = () => {
  localStorage.clear("servicer");
  window.location.pathname = "/service-create";
};
